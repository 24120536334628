import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

export default function RadarChart({showLegend, datasets, labels}) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      // Aggiorna i dati del grafico
      chartInstance.current.data.labels = labels;
      chartInstance.current.data.datasets = datasets;
  
      // Aggiorna visivamente il grafico
      chartInstance.current.update();
    } else {
      const myChartRef = chartRef.current.getContext("2d");
  
      // Crea un nuovo grafico
      chartInstance.current = new Chart(myChartRef, {
        type: "radar",
        data: {
          labels: labels || [
            "Market Readiness",
            "Innovation Impact",
            "Operational Excellence",
            "Growth Potential",
            "Cost Efficiency",
            "Risk Mitigation",
          ],
          datasets: datasets || [],
        },
        options: {
          color: "white",
          scale: {
            min: 0,
            max: 10,
          },
          scales: {
            r: {
              angleLines: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              grid: {
                color: "rgba(255, 255, 255, 0.2)",
              },
              pointLabels: {
                color: "white",
                font: {
                  weight: "bold",
                },
              },
              ticks: {
                color: "transparent",
                backdropColor: "transparent",
              },
            },
          },
          plugins: {
            legend: {
              display: showLegend || false,
              labels: {
                color: "white",
              },
            },
          },
        },
      });
    }
  }, [datasets, labels]);

  return (
    <div className='w-full h-[500px] flex items-center justify-center'>
      <canvas ref={chartRef} />
    </div>
  )
}