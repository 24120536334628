import React, { useEffect, useState, useRef } from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import Badge from "@mui/material/Badge";
import { socket } from "../index";
import { useParams, useNavigate } from "react-router-dom";

export default function Chats() {
  const navigate = useNavigate();
  const { id: paramId, name: paramName } = useParams(); // Parametri della rotta
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedChatMessages, setSelectedMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);
  const chatContainerRef = useRef(null);

  const selectedChatIdRef = useRef(null);

  useEffect(() => {
    selectedChatIdRef.current = selectedChatId;
  }, [selectedChatId]);

  useEffect(() => {
    if (!socket) {
      console.error("Socket is not initialized.");
      return;
    }

    // Listener per nuovi messaggi
    const handleNewMessage = (payload) => {
      const currentChatId = selectedChatIdRef.current;
    
      // Recuperiamo il valore di localStorage per determinare se l'utente è una startup
      const storedInnovation = localStorage.getItem("roleName");
      const isStartup = storedInnovation === "user_innovation";
    
      // Log per debug (da rimuovere in produzione)
      //console.log("📢 Messaggio ricevuto:", payload);
      //console.log("🏢 L'utente è una startup?", isStartup);
      //console.log("🔄 Controllo ID con:", isStartup ? "user_id" : "innovation_id");
    
      // Se l'utente è una startup, usiamo `user_id` per confrontare l'ID della chat
      const correctChatId = isStartup ? payload.user_id : payload.innovation_id;
    
      // Verifica se il messaggio è per la chat attualmente aperta
      const shouldDisplayMessage = isStartup
        ? payload.sent_from_user === true // Se l'utente è una startup, mostra `sent_from_user: true`
        : payload.sent_from_user === false; // Se è user, mostra `sent_from_user: false`
    
      if (correctChatId === currentChatId && shouldDisplayMessage) {
        setSelectedMessages((prev) => [...prev, payload]);
      }
    };
    
    

    // Listener per cronologia messaggi
    const handleChatHistory = (payload) => {
      if (payload.messages) {
        setSelectedMessages((prev) => [
          ...payload.messages.reverse(),
          ...prev,
        ]);
      }
    };

    // Listener per lista chat
    const handleChatList = (payload) => {
      if (payload.chats) {
        const storedInnovation = localStorage.getItem("innovation");
    
        //console.log("📢 Valore di storedInnovation:", storedInnovation);
    
        // ✅ Logica inversa per garantire che un utente chatti con una startup e viceversa
        const newChats = payload.chats.map((chat) => {
          const useInnovationId = storedInnovation === null || storedInnovation === "null" || storedInnovation === "undefined";
          const chatId = useInnovationId ? String(chat.innovation_id) : String(chat.user_id);
    
          //console.log(`🛠 Chat processata -> id: ${chatId}, name: ${chat.name}, innovation_id: ${chat.innovation_id}, user_id: ${chat.user_id}`);
    
          return {
            id: chatId,
            name: chat.name,
            date: chat.date,
          };
        });
    
        //console.log("✅ Chats mappate:", newChats);
    
        setChats((prevChats) => {
          const existingChatIds = new Set(prevChats.map((chat) => chat.id));
    
          // Mantiene solo le nuove chat con ID unici
          const filteredChats = newChats.filter((chat) => !existingChatIds.has(chat.id));
    
          // Unione delle chat vecchie + nuove
          const mergedChats = [...prevChats, ...filteredChats];
    
          // 🔹 Aggiungiamo la chat dai parametri URL se non è già presente
          if (
            paramId &&
            !mergedChats.some((chat) => chat.id === String(paramId))
          ) {
            const idToUse = storedInnovation === null || storedInnovation === "null" || storedInnovation === "undefined"
              ? String(paramId)  // Se innovation è nullo, usa innovation_id
              : String(paramId); // Altrimenti usa user_id
    
            //console.log(`🔗 Aggiunta chat dall'URL -> id: ${idToUse}, name: ${paramName}`);
    
            mergedChats.push({
              id: idToUse,
              name: paramName || "Unknown",
            });

            console.log("paramName", paramName)
          }
    
          //console.log("🔥 Chats finali dopo il filtro duplicati e inclusione URL:", mergedChats);
    
          return mergedChats;
        });
      }
    };
    
    

    // Assegna i listener
    socket.on("innovations chat list", handleChatList);
    socket.on("innovations chat message", handleNewMessage);
    socket.on("innovations chat history", handleChatHistory);

    // Richiede la lista delle chat
    socket.emit("innovations chat list");

    return () => {
      // Rimuovi i listener
      socket.off("innovations chat message", handleNewMessage);
      socket.off("innovations chat history", handleChatHistory);
      socket.off("innovations chat list", handleChatList);
    };
  }, [paramId, paramName]); // Aggiungi paramId e paramName come dipendenze

  // Gestione dei parametri della rotta
  useEffect(() => {
    //console.log('paramId:', paramId, 'paramName:', paramName);
    if (paramId && paramName) {
      setSelectedChatId(paramId);
      socket.emit("innovations chat history", {
        messageId: null,
        toId: paramId,
      });
      // Non navigare, l'URL è già corretto
    } else if (!paramId && !paramName && chats.length > 0 && !selectedChatId) {
      // Se non ci sono parametri nell'URL e nessuna chat è selezionata
      const firstChat = chats[0];
      setSelectedChatId(firstChat.id);
      socket.emit("innovations chat history", {
        messageId: null,
        toId: firstChat.id,
      });
      navigate(`/chats/${firstChat.id}/${firstChat.name}`);
    }
  }, [paramId, paramName, chats, navigate, selectedChatId]);

  const handleChatSelection = (chatId, chatName) => {
    setSelectedChatId(chatId);
    setSelectedMessages([]);
    socket.emit("innovations chat history", { messageId: null, toId: chatId });
    // Aggiorna l'URL con i parametri id e name
    navigate(`/chats/${chatId}`);
  };

  const handleAddMessage = () => {
    if (newMessage.trim() && selectedChatId !== null) {
      const messagePayload = {
        toId: selectedChatId,
        content: newMessage.trim(),
      };
      socket.emit("innovations chat message", messagePayload);
      setNewMessage("");
      const storedInnovation = localStorage.getItem("innovation");
      const useInnovationId = storedInnovation === null || storedInnovation === "null" || storedInnovation === "undefined";
      const chatId = useInnovationId ? false : true;
      setSelectedMessages((prev) => [
        ...prev,
        { ...messagePayload, sent_from_user: useInnovationId },
      ]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleAddMessage();
    }
  };

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };


  const formatDate = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date(); // Usa `dateString` se definito, altrimenti ora corrente
    return isNaN(date) ? new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) 
                       : date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  
  const formatDay = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date(); // Usa `dateString` se definito, altrimenti ora corrente
    return isNaN(date)
      ? new Date().toLocaleDateString("it-IT", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : date.toLocaleDateString("it-IT", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
  };
  
  const loadPreviousMessages = () => {
    if (selectedChatMessages.length > 0) {
      setIsLoadingPrevious(true); // Inizia il caricamento
  
      // Salva la posizione attuale dello scroll
      const currentScrollHeight = chatContainerRef.current.scrollHeight;
      const currentScrollTop = chatContainerRef.current.scrollTop;
  
      const firstMessageId = selectedChatMessages[0]?.id || null;
  
      socket.emit("innovations chat history", { messageId: firstMessageId, toId: selectedChatId });
  
      socket.once("innovations chat history", (payload) => {
        if (payload.messages) {
          setSelectedMessages((prev) => [
            ...payload.messages.reverse(), // Aggiungi i nuovi messaggi sopra
            ...prev, // Mantieni i messaggi esistenti
          ]);
  
          // Ripristina la posizione dello scroll
          setTimeout(() => {
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight - currentScrollHeight + currentScrollTop;
          }, 0);
        }
        setIsLoadingPrevious(false); // Fine caricamento
      });
    }
  };  

  // Scroll automatico quando arrivano nuovi messaggi
  useEffect(() => {
    if (!isLoadingPrevious) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedChatMessages, isLoadingPrevious]);
  

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[calc(100vh-104px)] flex flex-col">
        <div className="flex flex-row h-full">
          {/* Colonna Sinistra */}
          <div className="flex flex-col gap-2 w-60 min-w-60 h-full bg-[rgb(255,255,255,.2)] p-2 pt-6">
            <TextField
              variant="outlined"
              placeholder="Search startups"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "#818181" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                display: "none",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#818181",
                    fontFamily: "Urbanist, sans-serif",
                  },
                },
              }}
            />

            {chats.map((chat) => (
              <Badge badgeContent={0} color="primary" key={chat.id}>
                <Button
                  sx={{
                    display: "flex",
                    gap: "8px",
                    width: "100%",
                    alignItems: "center",
                    position: "relative",
                    backgroundColor:
                      chat.id === selectedChatId
                        ? "rgba(197, 255, 85, 0.8)"
                        : "transparent",
                  }}
                  onClick={() => handleChatSelection(chat.id, chat.name)}
                >
                  <div
                    className="flex flex-col gap-0 w-full text-left text-white normal-case font"
                    style={{
                      color: chat.id === selectedChatId ? "#000" : "white",
                      fontWeight: chat.id === selectedChatId ? "700" : "400",
                    }}
                  >
                    <span>{chat.name}</span>
                    <span className="text-[10px]">ID: {chat.id}</span>
                  </div>
                </Button>
              </Badge>
            ))}
          </div>

          {/* Right Column */}
          <div className="flex flex-col flex-grow h-full bg-black">
            <div 
              ref={chatContainerRef}
              className="flex flex-col flex-grow p-5 gap-5 h-0 overflow-y-auto">
              
              {/* Pulsante per caricare i messaggi precedenti */}
                <div className="text-center p-2">
                  {selectedChatMessages.length > 0 && (
                    <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "#fff",
                      fontSize: "12px",
                      fontFamily: "Urbanist"
                    }}
                    onClick={loadPreviousMessages}
                    disabled={isLoadingPrevious} // Disabilita il pulsante durante il caricamento
                  >
                    {isLoadingPrevious ? "Loading..." : "Load Previous Messages"}
                  </Button>
                  )}
                </div>
              {selectedChatMessages.length > 0 ? (                
                selectedChatMessages.map((m, index) => (
                  <div
                    key={index}
                    msgid={m.id}
                    className={`flex flex-col gap-1 ${
                      m.sent_from_user ? "items-end" : "items-start"
                    }`}
                  >
                    <span className="font-semibold text-sm	 text-white" >
                      {m.sent_from_user ? "You" : "Startup"}
                    </span>     
                                   
                    <div className={`p-2 bg-gray-800 text-white w-fit rounded-2xl min-w-xs max-w-[80%] flex flex-col gap-1 
                      border-radius: 50px;
                      padding-left: 15px;
                      padding-right: 15px;
                    ${
                      m.sent_from_user ? "items-end" : "items-start"
                    }
                    `}>
                      {m.content}
                      
                    </div>                      
                    <span style={{fontSize: "10px", color:"#ddd"}}>{formatDate(m.sent_at)} &bull; {formatDay(m.sent_at)}</span>
                  </div>
                ))
              ) : (
                <div className="text-white text-center">Open a chat on the left with a startup</div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Message Input Field */}
            <div className="p-5 bg-black flex gap-5">
              <TextField
                variant="outlined"
                fullWidth
                multiline
                value={newMessage}
                placeholder="Write a message..."
                sx={{                  
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Urbanist",
                    "& fieldset": { borderColor: "#818181", fontFamily: "Urbanist, sans-serif" },
                  },
                }}
                onChange={handleNewMessageChange}
                onKeyDown={handleKeyDown}
                disabled={selectedChatId === null}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "rgb(var(--global-color-primary))",
                  color: "black",
                }}
                onClick={handleAddMessage}
                disabled={selectedChatId === null}
              >
                <SendIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
