import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import StandardButton from "../../standardButton";

export default function DeleteStartupDialog({
  open,
  handleClose,
  id, // Passato come prop (startup ID)
  name, // Nome della startup per il messaggio
  handleConfirm,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-startup-dialog-title"
      aria-describedby="delete-startup-dialog-description"
    >
      <DialogTitle
        id="delete-startup-dialog-title"
        sx={{
          backgroundColor: "#191919",
          fontFamily: "Urbanist, sans-serif",
          color: "white",
        }}
      >
        Delete Startup
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: "#191919",
          fontFamily: "Urbanist, sans-serif",
        }}
      >
        <DialogContentText
          id="delete-startup-dialog-description"
          sx={{
            color: "white",
            fontFamily: "Urbanist, sans-serif",
          }}
        >
          Are you sure you want to delete the startup <strong>{name}</strong>?
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          backgroundColor: "#191919",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <StandardButton
          onClick={handleClose}
          type="default"
          variant="outlined"
          text="Cancel"
        />
        <StandardButton
          onClick={() => handleConfirm(id, name)}
          text="Confirm"
          type="error"
          variant="error"
        />
      </DialogActions>
    </Dialog>
  );
}
