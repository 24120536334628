import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedTable from '../components/paginatedTable'

import SectorFilterDialog from "../components/sectorFilterDialog";
import { Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Pagination, Select, TextField, Switch } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import axios from "axios";
import NewStartupDialog from "../components/newStartupDialog";
import { useLocation } from "react-router-dom";
import ConfirmDialog from "../components/confirmDialog";
import { useDebounce } from 'use-debounce';
import StandardButton from "../components/standardButton";

// function LinkColumn(params) {
//   return <a href={params.value} className="text-[rgb(var(--global-color-primary))]" target="_blank" rel="noreferrer">{params.value}</a>
// }
function StatusColumn(params) {
  return <span>{params.value ? 'Blocked' : 'Active'}</span>
}
function LogoColumn(params) {
  if(!!params.value)
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <img src={params.value} className="w-8 h-8 object-cover rounded-full" alt="Logo" />
      </div>
    )
  else
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <img src="" className="w-8 h-8 object-cover rounded-full" alt="Logo"/>
      </div>
    )
}  

export default function StartupList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startups, setStartups] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedStartupId, setSelectedStartupId] = useState(0);
  const [selectedStartup, setSelectedStartup] = useState({});
  const [perPage] = useState(15);
  const [page, setPage] = useState(1); // Pagina attuale
  const [totalPages, setTotalPages] = useState(1); // Totale pagine
  const [totalResults, setTotalResults] = useState(0);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const navigate = useNavigate();
  const header = [
    { field: 'id', headerName: 'ID', width: 70 },
    { 
      field: 'logo_url', 
      headerName: 'Logo', 
      width: 50,
      type: 'custom',
      renderCell: LogoColumn
    },
    { field: 'name', headerName: 'Brand', width: 200 },
    { field: 'account_type_name', headerName: 'Startup type', width: 130 },
    { field: 'founder', headerName: 'Founder', width: 130 },
    { field: 'account_email', headerName: 'Account email', width: 130 },
    { field: 'is_verified', headerName: 'Email verified', type: 'boolean', width: 120 },
    { field: 'is_approved_by_admin', headerName: 'Approved', type: 'boolean', width: 120 },
    { 
      field: 'is_blocked', 
      headerName: 'Status', 
      type: 'custom', 
      width: 120, 
      renderCell: StatusColumn 
    },
    { field: 'company_type_name', headerName: 'Company type', width: 200 },
    { field: 'business_model_name', headerName: 'Business model', width: 200 },
    { field: 'company_status', headerName: 'Company status', width: 200 },
    // { 
    //   field: 'linkedin_url', 
    //   headerName: 'Linkedin', 
    //   width: 200,
    //   type: 'custom',
    //   renderCell: LinkColumn
    // },
    // { 
    //   field: 'website', 
    //   headerName: 'Website', 
    //   width: 200,
    //   type: 'custom',
    //   renderCell: LinkColumn
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <Switch
            checked={!params.row.is_blocked} // Se è bloccato, lo switch è disattivato
            onChange={() => handleApprovalChange(params.id, params.row.is_blocked)}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "rgb(var(--global-color-primary))",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "rgb(var(--global-color-primary))",
              },
            }}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />
        ];
      }
    }
  ];

  const handleApprovalChange = async (id, isBlocked) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token non trovato. Effettua il login.");
      }
  
      const action = isBlocked ? "unblock" : "block"; // Determina l'azione
      const url = `${process.env.REACT_APP_API_URL}/innovations/${id}/${action}`;
  
      await axios.patch(url, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      // Aggiorna lo stato della lista senza ricaricare tutto
      setStartups((prevStartups) =>
        prevStartups.map((startup) =>
          startup.id === id ? { ...startup, is_blocked: !isBlocked } : startup
        )
      );
  
    } catch (err) {
      console.error("Errore nel cambio di stato della startup:", err);
    }
  };  

  const [filters, setFilters] = useState({}); // Dati per i filtri dall'API
  const [selectedFilters, setSelectedFilters] = useState({
    chat: "",
    business_models: [],
    stages: [], // renamed to "status"
    company_types: [],
    target_markets: [],
    tags: [],
    account_type: 0,
    order_by: "",
    status: "",
    order_dir: "",
    countries: [],
    year_min: "",
    year_max: "",
    categories: [],
    approved: ""
  });
  const [orderDir, setOrderDir] = useState("desc"); // Aggiunto stato per `order_dir`
  const [orderBy] = useState("added");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);  // Manage dialog open state
  const [startupIdToDelete, setStartupIdToDelete] = useState(null);  // Store ID of startup to delete
  const location = useLocation();

  // Funzione per convertire i parametri della query string in un oggetto
  const parseQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const parsedParams = {};
    for (const [key, value] of params.entries()) {
      parsedParams[key] = value;
    }
    return parsedParams;
  };

  // Funzione per ottenere i dati dei filtri
  const fetchFilterData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/values/innovation-filters`, {
        params: {
          admin: true
        }
      });
      setFilters({
        ...response.data.data,
        countries: response.data.data.countries?.sort((a, b) => a.name.localeCompare(b.name)),
        approved: response.data.data.approved || []
      });
    } catch (err) {
      console.error("Errore nel caricamento dei filtri", err);
    }
  };

  const handleCountriesList = (data) => {
    if(data && data.data) {
      setCountries(data.data.countries)
    }
  }

  const handleCountriesListError = (err) => {
    setError(err)
    console.log(err)
  }
  
  const handleStartupAdded = async () => {
    await fetchData(page);
  };

  // Funzione per ottenere i dati delle startup con i filtri applicati
  const fetchData = async (page) => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trovato. Effettua il login.');
      }

      const queryParams = new URLSearchParams();

      queryParams.append('page', page);
      queryParams.append('per_page', perPage);
      queryParams.append('order_dir', orderDir); // Usa `orderDir` dallo stato
      queryParams.append('order_by', orderBy);

      // Aggiungi i filtri ai parametri della query string

      if (selectedFilters.chat !== "") {
        queryParams.append('chat', selectedFilters.chat);
      }

      if (selectedFilters.countries.length > 0) {
        selectedFilters.countries.forEach(countryId => {
          queryParams.append('country', countryId);
        });
      }

      if (selectedFilters.year_min) {
        queryParams.append('year_min', selectedFilters.year_min);
      }

      if (selectedFilters.year_max) {
        queryParams.append('year_max', selectedFilters.year_max);
      }
      
      if (selectedFilters.account_type) {
        queryParams.append('account_type', selectedFilters.account_type);
      }
      
      if (selectedFilters.status) {
        queryParams.append('status', selectedFilters.status);
      }

      if (selectedFilters.business_models.length > 0) {
        queryParams.append('business_model', selectedFilters.business_models[0]);
      }

      if (selectedFilters.stages.length > 0) {
        queryParams.append('stages', selectedFilters.stages[0]);
      }

      if (selectedFilters.company_types.length > 0) {
        selectedFilters.company_types.forEach(id => {
          queryParams.append('type', id);
        });
      }

      if (selectedFilters.target_markets.length > 0) {
        selectedFilters.target_markets.forEach(id => {
          queryParams.append('target_markets[]', id);
        });
      }

      if (selectedFilters.tags.length > 0) {
        selectedFilters.tags.forEach(id => {
          queryParams.append('tags[]', id);
        });
      }

      // Aggiungi le categorie selezionate
      if (selectedFilters.categories.length > 0) {
        selectedFilters.categories.forEach(id => {
          queryParams.append('categories[]', id);
        });
      }

      if (selectedFilters.approved !== "") {
        queryParams.append('approved', selectedFilters.approved);
      }

      // Aggiungi i parametri della query string dall'URL
      const queryParamsFromURL = parseQueryParams(location.search);
      Object.entries(queryParamsFromURL).forEach(([key, value]) => {
        if (!queryParams.has(key)) {
          queryParams.append(key, value);
        }
      });

      if (searchTerm.trim() !== "") {
        queryParams.append('name', searchTerm.trim());
      }

      const url = `${process.env.REACT_APP_API_URL}/innovations?${queryParams.toString()}`;
      const response = await axios.get(url, {
        params: {
          admin: true,
          per_page: perPage
        },
        headers: { Authorization: `Bearer ${token}` }
      });

      const data = response.data;
      handleStartupsList(data)
      setTotalResults(data.total);
      setTotalPages(parseInt(data.last_page));
    } catch (err) {
      setError(err.message || "Errore nel caricamento dei dati");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.trim() !== "") {
      fetchData(1);
    } else {
      fetchData(page);
    }
  }, [debouncedSearchTerm, page]); 
  

  const handleStartupsList = (data) => {
    setLoading(true)
    if(data && data.data) {
      let filteredStartups = []
      for (let i = 0; i < data.data.length; i++) {
        if(!filteredStartups.find(s => s.id === data.data[i].id)) {
          filteredStartups.push({
            ...data.data[i], 
            founder: data.data[i].user ? data.data[i].user.first_name + " " + data.data[i].user.last_name : "",
            account_email: data.data[i].user ? data.data[i].user.email : "",
            account_type_name: data.data[i].account_type.name || "",
            company_type_name: data.data[i].company_type.name || "",
            business_model_name: data.data[i].business_model.name || "",
            company_status: data.data[i].company_stage.name || "",
          })
        }
      }
      setStartups(filteredStartups)
      // console.log(data.data)
    }
    setLoading(false)
  }

  const handleEditClick = (id) => () => {
    navigate(`/startupList/${id}`); // Naviga al path /startupList/{id}
  };


  const handleOpenConfirmDialog = (id) => () => {
    setStartupIdToDelete(id);
    setOpenConfirmDialog(true);  // Open the dialog
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);  // Close the dialog without deleting
    setStartupIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (startupIdToDelete !== null) {
      await handleDeleteStartup(startupIdToDelete);
      setStartups(startups.filter(c => c.id !== startupIdToDelete));  // Remove user from the list
      setOpenConfirmDialog(false);  // Close the dialog after deletion
      setStartupIdToDelete(null);
    }
  };

  const handleDeleteStartup = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found. Please login.');
      }
      const url = `${process.env.REACT_APP_API_URL}/innovations/${id}`;

      const response = await axios.delete(url, {
        data: { 
          name: startups.find(c => c.id === id).name
        },
        headers: { Authorization: `Bearer ${token}` }
      });

    } catch(err) {
      setError(err)
    }
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Funzione per resettare i filtri
  const resetFilters = () => {
    setSelectedFilters({
      chat: "",
      business_models: [],
      stages: [], // renamed to "status"
      company_types: [],
      target_markets: [],
      tags: [],
      order_by: "",
      order_dir: "",
      countries: [],
      year_min: "",
      year_max: "",
      status: "",
      account_type: 0,
      categories: [] // Resetta le categorie
    });
    setOrderDir("desc"); // Resetta anche `orderDir`
    setPage(1);
  };

  const handleFilterChange = (filterName, selectedValues) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterName]: selectedValues
    }));
  };

  const handleOrderChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "reset") {
      resetFilters(); // Resetta tutti i filtri
    } else {
      setOrderDir(selectedValue); // Aggiorna solo `orderDir`
    }
  };

  // Funzione per gestire la selezione della categoria
  const handleCategorySelect = (categoryId) => {
    setSelectedFilters(prev => ({
      ...prev,
      categories: [categoryId] // Puoi modificare questo per supportare selezioni multiple
    }));
  };

  const filteredStartups = startups.filter((row) =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.account_email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchData(page);
  }, [page, selectedFilters, orderDir, location.search]); // Aggiungi `orderDir` come dipendenza

  useEffect(() => {
    fetchFilterData();
  }, []);

  const navigateStartup = () => {
    navigate("/add-startup");
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Startup List</h1>
            <StandardButton 
              text={"Add new startup"} 
              onClick={navigateStartup} 
              width={"300px"} 
            />
          </div>
          <div className="flex flex-wrap gap-4">
            <SectorFilterDialog onCategorySelect={handleCategorySelect} />                    

            <FilterSelect
              label="Location"
              options={[{ id: "", name: "Reset" }, ...(filters.countries || [])]}
              selected={selectedFilters.countries}
              onChange={(selected) => handleFilterChange('countries', selected)}
            />

            <FilterSelect
              label="Type"
              options={[{ id: "", name: "Reset" }, ...(filters.company_types || [])]}
              selected={selectedFilters.company_types}
              onChange={(selected) => handleFilterChange('company_types', selected)}
            />

            <FilterSelect
              label="Target Markets"
              options={[{ id: "", name: "Reset" }, ...(filters.target_markets || [])]}
              selected={selectedFilters.target_markets}
              onChange={(selected) => handleFilterChange('target_markets', selected)}
              width={"170px"}
            />

            <FilterSelect
              label="Business Models"
              options={[{ id: "", name: "Reset" }, ...(filters.business_models || [])]}
              selected={selectedFilters.business_models}
              onChange={(selected) => handleFilterChange('business_models', selected)}
              width={"170px"}
            />

            <FilterSelect
              label="Stage"
              options={[{ id: "", name: "Reset" }, ...(filters.stages || [])]}
              selected={selectedFilters.stages}
              onChange={(selected) => handleFilterChange('stages', selected)}
            />

            <FilterSelect
              label="Tags"
              options={[{ id: "", name: "Reset" }, ...(filters.tags || [])]}
              selected={selectedFilters.tags}
              onChange={(selected) => handleFilterChange('tags', selected)}
            />

            <FilterSelect
              label="Startup Type"
              options={[{ id: "", name: "Reset" }, ...(filters.innovation_account_types || [])]}
              selected={selectedFilters.account_type}
              multiple={false}
              onChange={(selected) => handleFilterChange('account_type', selected)}
              width={"150px"}
            />

            <FilterSelect
              label="Status"
              options={[{ id: "", name: "Reset" }, ...(filters.status?.map(s => {return {id: s, name: s}}) || [])]}
              selected={selectedFilters.status}
              multiple={false}
              onChange={(selected) => handleFilterChange('status', selected)}
              width={"120px"}
            />

            <FilterSelect
              label="Approved"
              options={[
                { id: "", name: "Reset" },
                ...(Array.isArray(filters.approved) ? filters.approved.map(value => ({
                  id: value.toString(),
                  name: value ? "Approved" : "Not Approved"
                })) : [])
              ]}
              selected={selectedFilters.approved}
              multiple={false}
              onChange={(selected) => handleFilterChange('approved', selected)}
              width={"150px"} 
            />

            <FormControl sx={{ minWidth: 120 }}>
              <Select value={orderDir} onChange={handleOrderChange}>
                <MenuItem value="asc">Older</MenuItem>
                <MenuItem value="desc">Recent</MenuItem>
                <MenuItem value="reset">Reset</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Search"
              variant="outlined"
              sx={{
                input: { color: "white" },
                "& label": { color: "rgb(255, 255, 255, .7)" },
                "& label.Mui-focused": { color: "rgb(255, 255, 255)" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgb(255, 255, 255, .3)" },
                  "&:hover fieldset": { borderColor: "rgb(255, 255, 255, .7)" },
                  "&.Mui-focused fieldset": { borderColor: "rgb(255, 255, 255)" },
                },
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Button variant="outlined" onClick={resetFilters} sx={{ ...buttonStyles }}>
              Reset All Filters
            </Button>
          </div>
          <PaginatedTable 
            header={header} 
            items={filteredStartups} 
            hideFooterPagination={true}
          />
          <div className="flex justify-center mt-6">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              className="pagination"
            />
          </div>
        </div>
      </div>
      <FlexibleDataFetcher
        endpoint={"values/innovation-filters"}
        params={{
          admin: true
        }}
        onSuccess={handleCountriesList}
        onError={handleCountriesListError}
      />
      {
        openConfirmDialog &&
        <ConfirmDialog
          open={openConfirmDialog}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleConfirmDelete}
          title="Confirm Delete"
          message="Are you sure you want to delete this startup?"
        />
      }
    </div>
  )
}

const FilterSelect = ({ label, options, selected, onChange, multiple = true, width }) => {
  return (
    <FormControl sx={{ minWidth: 120, width: !!width ? width : 'auto' }}>
      <InputLabel sx={{ fontFamily: 'Urbanist, sans-serif' }}>{label}</InputLabel>
      <Select
        sx={{
          border: (Array.isArray(selected) && selected.length > 0) || (typeof selected === 'string' && selected !== '') || (typeof selected === 'number' &&  selected !== 0) ? '1px solid rgb(var(--global-color-primary))' : '1px solid white',
          '.MuiOutlinedInput-notchedOutline' : {
            border: '0'
          },
          '.MuiFormLabel-root .MuiInputLabel-root' : {
            backgroundColor: '#191919'
          },
          '.Mui-focused' : {
            border: '0 !important'
          },
        }}
        multiple={multiple}
        value={selected || (multiple ? [] : "")}
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => {
          if (multiple) {
            return selected
              .map((sel) => options.find((option) => option.id === sel)?.name || "")
              .join(', ');
          } else {
            return options.find((option) => option.id === selected)?.name || "";
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {multiple && <Checkbox checked={selected.indexOf(option.id) > -1} />}
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const buttonStyles = {
  fontFamily: "Urbanist, sans-serif",
  border: "1px solid rgb(255, 255, 255, .3)",
  color: "rgb(255, 255, 255, .3)"
};