import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Chip, Fade, Slider, Button, Link, Tooltip } from "@mui/material";
import AccountInformation from "../components/adminStartupDetails/accountInformation";
import AdminVideo from "../components/adminStartupDetails/loadAdminVideo";
import ArticlesAdmin from "../components/adminStartupDetails/articlesAdmin";
import EditIcon from '@mui/icons-material/Edit'; 
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthIcon from '@mui/icons-material/South';
import CompanyDialog from '../components/adminStartupDetails/dialogs/companyInformation';
import DescriptionDialog from '../components/adminStartupDetails/dialogs/descriptionCompany';
import DialogTags1 from '../components/adminStartupDetails/dialogs/dialogTags1';
import DialogTags2 from '../components/adminStartupDetails/dialogs/dialogTags2';
import UploadLogoDialog from "../components/adminStartupDetails/dialogs/uploadLogoDialog";


const StartupEdit = () => {
  const { id } = useParams();
  const [startupData, setStartupData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [openLogoDialog, setOpenLogoDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token"); // Prendiamo il token di autenticazione
      if (!token) {
        setError("Token mancante, esegui il login.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/innovations/${id}?admin=true`, {
          headers: {
            Authorization: `Bearer ${token}`, // Includiamo il token negli header
          },
        });

        setStartupData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "Errore nella chiamata API.");
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, refreshTrigger]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [integrationTiming, setIntegrationTiming] = useState(3);
  const [integrationTimingLabel, setIntegrationTimingLabel] = useState("N/A");

  useEffect(() => {
      if (startupData?.integration_timing) {
        const timingString = startupData.integration_timing;
  
        // Estrai tutti i numeri dalla stringa
        const numbers = timingString.match(/\d+/g)?.map(Number) || [];
  
        console.log("numbers", numbers )
  
        if (numbers.length > 0) {
          const average = numbers.reduce((a, b) => a + b, 0) / numbers.length;
  
          // Converti la media in mesi per il valore dello slider
          let sliderValue = Math.round(average);
          console.log("media", average )
  
          // Aggiorna lo stato dello slider e la label
          setIntegrationTiming(sliderValue);
          setIntegrationTimingLabel(timingString);
        }
      }
    }, [startupData]);

  const [readDescription, setReadMoreDescription] = useState(false);
  const [readServices, setServicesDescription] = useState(false);
  
  const handleReadMoreDescription = () => {
    setReadMoreDescription(true);
  };

  const handleServicesDescription = () => {
    setServicesDescription(true);
  };
  
  const [openDialog, setOpenDialog] = useState(null);

  
  const handleOpenDialog = (dialogName) => setOpenDialog(dialogName);
  const handleCloseDialog = () => {
    setOpenDialog(null);
    setRefreshTrigger((prev) => prev + 1);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  const refreshStartupData = () => setRefreshTrigger((prev) => prev + 1);

  return (
    <div className="p-5 bg-black min-h-[100vh]">
      <div className="flex flex-row mb-3">
                <div className="w-2/4 flex flex-col">
                    <h1 className="text-5xl font-semibold">{startupData.name || "No Name"}</h1>
                    <div className="relative">
                      <img
                        src={startupData.logo_url || startupData.fixed_logo_url || `${process.env.REACT_APP_BASE_URL}/default-logo.png`}
                        alt={startupData.name || "No Logo"}
                        className="w-72 rounded-2xl"
                        style={{ marginBottom: "5px", width: "50%", height: "200px", objectFit: "cover" }}
                      />
                      <div className="absolute" style={{ cursor: "pointer", position: "absolute", top: 0, left: "50%" }}>
                        <EditIcon
                          sx={{ cursor: "pointer", position: "absolute", top: 10, right: 10, color: "rgb(var(--global-color-primary))", backgroundColor: "#191919", padding: "5px", borderRadius: "50%", width: "30px", height: "30px" }}
                          onClick={() => setOpenLogoDialog(true)}
                        />
                      </div>
                    </div>
                </div>
                <div className="w-2/4 flex flex-col gap-2 items-end	justify-center">

                    <div className="flex gap-2">
                      <span className="font-semibold">Active since:</span>
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {startupData.created_at 
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(new Date(startupData.created_at))
                            : "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Last Seen:</span>
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {startupData.updated_at 
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(new Date(startupData.updated_at))
                            : "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Account Type:</span>
                      <span className="text-[rgb(var(--global-color-primary))] uppercase">
                        {startupData.account_type?.name || "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Status:</span>
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {startupData.is_blocked ? 'Blocked' : 'Active'}
                      </span>
                    </div>

                </div>
            </div>

    
            <div className="flex flex-col gap-10 pt-3">      

{/* Dati principali */}
<div className="grid grid-cols-2 gap-8">

  <div className="flex flex-col gap-10">
  
      {/* Company */}

      <div className="flex flex-col gap-4 no-p-margin">

          <div className="flex flex-row items-center gap-2 ">
              <h2 className="text-4xl font-semibold m-0">Company Information</h2>
              <EditIcon
                  sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                  onClick={() => handleOpenDialog("dialog1")}
              />
          </div>
          
          <p><strong>Founder:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.founders ? startupData.founders.replaceAll(';', '') : "N/A"} </span></p>
          <p><strong>Founding Year:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.founding_year || "N/A"} </span></p>
          <p><strong>Company Type:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.company_type.name || "N/A"} </span></p>
          <p><strong>Business Model:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.business_model?.name || "N/A"} </span></p>
          <p><strong>Company Status:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.company_stage?.name || "N/A"} </span></p>
          <p>
            <strong>Target Markets:</strong>{" "}
            <span className="text-[rgb(var(--global-color-primary))] capitalize">
              {startupData.target_markets?.length > 0
                ? startupData.target_markets.map((market) => market.name).join(", ")
                : "N/A"}
            </span>
          </p>
          <p><strong>LinkedIn:</strong> <a className="text-[rgb(var(--global-color-primary))]" href={startupData.linkedin_url || "N/A"} target="_blank"> {startupData.linkedin_url || "N/A"} </a></p>
          <p><strong>City:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.city ? startupData.city.replaceAll(';', '') : "N/A"}</span></p>
          <p><strong>Address:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.address ? startupData.address.replaceAll(';', '') : "N/A"}</span></p>
          <p><strong>Website:</strong> <a className="text-[rgb(var(--global-color-primary))]" href={startupData.website || "N/A"} target="_blank"> {startupData.website || "N/A"} </a></p>
          <p><strong>Phone:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.phone ? startupData.phone.replaceAll(';', '') : "N/A"}</span></p>
      </div>

      {/* Traction */}

      <div className="flex flex-col gap-4 no-p-margin">
          <div className="flex flex-row items-center gap-2 ">
              <h2 className="text-4xl font-semibold m-0">Traction</h2>
          </div>
          <p><strong>Founder:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.founders ? startupData.founders.replaceAll(';', ' ') : "N/A"} </span></p>
          <p><strong>Clients:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.clients ? startupData.clients.replaceAll(';', ' ') : "N/A"} </span></p>
          <p><strong>Presence in other counties:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.company_type.name || "N/A"} </span></p>
          <p><strong>Platform Partners:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize"> {startupData.platforms ? startupData.platforms.replaceAll(';', ' ') : "N/A"} </span></p>                    
      </div>
      
      {/* Total Funding */}

      <div className="flex flex-row gap-10 no-p-margin">

          <div className="flex gap-2">
              <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-14 w-14">
                <NorthEastIcon sx={{ color: "black" }} />
              </div>
              <div className="flex flex-col gap-2">
                <span className="font-semibold opacity-50 text-sm">Total funding:</span>
                <div className="font-bold text-2xl">
                  $ {startupData.total_funding ? Number(startupData.total_funding).toLocaleString() : "N/A"}
                </div>
              </div>
          </div>

          <div className="flex flex-col items-center gap-4">
            <h2 className="text-white text-2xl font-semibold">Integration Timing</h2>
            <div className="flex items-center gap-4 w-full max-w-md">
              {/* Min Label */}
              <span className="text-gray-400 text-sm">Min</span>

              {/* Slider with Value */}
              <div className="relative flex flex-col items-center w-full">
                <span
                  className="absolute -top-6 text-[rgb(var(--global-color-primary))] text-sm font-bold"
                >
                  {integrationTimingLabel}
                </span>
                <Slider
                  value={integrationTiming}
                  defaultValue={3}
                  min={1}
                  max={12}
                  disabled
                  sx={{
                    color: "rgb(var(--global-color-primary))",
                    "& .MuiSlider-thumb": {
                      height: 16,
                      width: 16,
                      backgroundColor: "rgb(var(--global-color-primary))",
                      border: "2px solid white",
                    },
                    "& .MuiSlider-track": {
                      backgroundColor: "rgb(var(--global-color-primary))",
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "gray",
                    },
                  }}
                />
              </div>
              
              {/* Max Label */}
              <span className="text-gray-400 text-sm">Max</span>
            </div>
          </div>
      
      </div>

  </div>

  

  <div className="flex flex-col gap-10">
      
      {/* Description */}

      <div className="flex flex-col gap-4 no-p-margin list-p">
          <div className="flex flex-row items-center gap-2 ">
              <h2 className="text-4xl font-semibold m-0">Description</h2>
              <EditIcon
                  sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                  onClick={() => handleOpenDialog("dialog2")}
              />
          </div>
          <p 
            className={readDescription ? "h-full overflow-auto" : " max-h-40 overflow-hidden"}
            dangerouslySetInnerHTML={{ __html: startupData.description || "No description available" }}
          />
          {
            !readDescription &&
            <div className="flex gap-2 items-center hover:no-underline cursor-pointer text-[rgb(var(--global-color-primary))]" onClick={handleReadMoreDescription}>
              <span>Read more</span>
              <SouthIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
            </div>
          }
      </div>

      {/* Services */}

      <div className="flex flex-col gap-4 no-p-margin list-p">
          <div className="flex flex-row items-center gap-2 ">
              <h2 className="text-4xl font-semibold m-0">Solutions, products, and services</h2>
          </div>
          <p 
            className={readDescription ? "h-full overflow-auto" : " max-h-40 overflow-hidden"}
            dangerouslySetInnerHTML={{ __html: startupData.solutions || "No description available" }}
          />
          {
            !readServices &&
            <div className="flex gap-2 items-center hover:no-underline cursor-pointer text-[rgb(var(--global-color-primary))]" onClick={handleServicesDescription}>
              <span>Read more</span>
              <SouthIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
            </div>
          }
      </div>

      {/* Sectors */}

      <div className="flex flex-col gap-4 no-p-margin">
          <div className="flex flex-row items-center gap-2 ">
              <h2 className="text-4xl font-semibold m-0">Sectors of Competence</h2>
              <EditIcon
                  sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                  onClick={() => handleOpenDialog("dialog3")}
              />
          </div>
          <div className="flex flex-row items-center gap-2 ">
                {startupData.taxonomies?.categories?.length > 0 ? (
                startupData.taxonomies.categories.map((category) => (
                  <div key={category.id} className="flex gap-2 justify-start items-center cursor-pointer">
                    <Tooltip
                      title={
                        category.children?.length > 0
                          ? (
                            <div>
                              {category.children.map((child) => (
                                <div key={child.id}>
                                  {child.name}
                                  {child.children?.length > 0 && (
                                    <div style={{ marginLeft: '15px' }}>
                                      {child.children.map((grandChild) => (
                                        <div key={grandChild.id}>
                                          {grandChild.name}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )
                          : "No children available"
                      }
                      arrow
                      placement="right"
                    >
                      <img src={category.logo || "N/A"} style={{width:"50px"}}></img>
                    </Tooltip>
                  </div>
                ))
              ) : (
                <span>No sectors available</span>
              )}
        </div>
      </div>

      {/* Tags */}

      <div className="flex flex-col gap-4 no-p-margin">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-4xl font-semibold m-0">Tags</h2>
          <EditIcon
            sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
            onClick={() => handleOpenDialog("dialog4")}
          />
        </div>
              
        {/* Chip per i tag selezionati */}
        <div className="flex flex-wrap gap-2">
        {startupData.taxonomies?.tags?.length > 0 ? (
          startupData.taxonomies.tags
            .filter((tag) => tag !== null && tag !== undefined) // Filtro i tag nulli
            .map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                sx={{
                  backgroundColor: "rgb(var(--global-color-primary))",
                  color: "#000",
                  fontWeight: "bold",
                }}
              />
            ))
        ) : (
          <span>No tags available</span>
        )}
        </div>
      </div>


      {/* Fine */}
      

      </div>
</div>

{/* Company Dialog */}
<CompanyDialog 
  open={openDialog === "dialog1"}
  handleClose={handleCloseDialog}
  innovationId={startupData.id}      
  startupData={startupData}   
/>

{/* Description Dialog */}
<DescriptionDialog 
  open={openDialog === "dialog2"}
  handleClose={handleCloseDialog}
  innovationId={startupData.id}    
  startupData={startupData}     
/>

{/* Tags Dialog */}
<DialogTags1 
  open={openDialog === "dialog3"}
  handleClose={handleCloseDialog}     
  startupData={startupData}
  innovationId={startupData.id} 
/>

<DialogTags2
  open={openDialog === "dialog4"}
  handleClose={handleCloseDialog}     
  startupData={startupData}
  innovationId={startupData.id} 
/>

<UploadLogoDialog 
  open={openLogoDialog} 
  handleClose={() => setOpenLogoDialog(false)} 
  innovationId={startupData.id} 
  onLogoUpdated={refreshStartupData} 
/>


</div>

      {/* Dialogs */}
      <CompanyDialog open={false} handleClose={() => {}} innovationId={startupData.id} />
      <DescriptionDialog open={false} handleClose={() => {}} innovationId={startupData.id} />
      <DialogTags1 open={false} handleClose={() => {}} startupData={startupData} innovationId={startupData.id} />
      <DialogTags2 open={false} handleClose={() => {}} startupData={startupData} innovationId={startupData.id} />
    </div>
  );
};

export default StartupEdit;
