import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import axios from "axios";

const ChangePasswordDialog = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!formData.oldPassword || !formData.newPassword || !formData.confirmPassword) {
      setError("All fields are required.");
      return;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setError("New passwords do not match.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      await axios.post(
        `${process.env.REACT_APP_API_URL}/change-password`,
        {
          password_previous: formData.oldPassword,
          password: formData.newPassword,
          password_confirmation: formData.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      alert("Password changed successfully!");
      handleClose();
    } catch (error) {
      setError("Failed to change password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ backgroundColor: "#191919", color: "white", textAlign: "center", fontFamily: "Urbanist" }}>
        Change Password
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#191919", color: "white", padding: "24px", fontFamily: "Urbanist" }}>
        {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}
        <TextField
          fullWidth
          margin="normal"
          label="Old Password"
          name="oldPassword"
          type="password"
          value={formData.oldPassword}
          onChange={handleChange}
          InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
          InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Urbanist" } }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="New Password"
          name="newPassword"
          type="password"
          value={formData.newPassword}
          onChange={handleChange}
          InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
          InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Urbanist" } }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          InputProps={{ style: { color: "white", fontFamily: "Urbanist" } }}
          InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Urbanist" } }}
        />
      </DialogContent>

      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px", justifyContent: "center" }}>
        <Button
          onClick={handleSubmit}
          disabled={loading}
          sx={{
            backgroundColor: "#A3E635",
            color: "#000",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(163, 230, 53, 0.8)" },
            padding: "8px 32px",
            fontFamily: "Urbanist"
          }}
        >
          {loading ? "Changing..." : "Change Password"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
