import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

const AddUserDialog = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    user_type_id: "",
    company_id: "",
    innovation_id: "",
    plan_type: "",
    start_date:"",
    end_date: "",
    is_trial: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const [userTypeRes, companyRes, planRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/values/users-filters`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${process.env.REACT_APP_API_URL}/companies`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${process.env.REACT_APP_API_URL}/plans`, { headers: { Authorization: `Bearer ${token}` } }),
        ]);

        if (userTypeRes.data?.data?.user_type) setUserTypes(userTypeRes.data.data.user_type);
        if (companyRes.data?.data) setCompanies(companyRes.data.data);
        if (planRes.data?.data) setPlan(planRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //console.log(`Campo aggiornato: ${name}, Valore: ${value}`);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
  
      // 1️⃣ Creazione della company
      const companyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/companies`,
        { name: formData.company_id },
        { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }
      );
  
      const companyId = companyResponse.data.id; // Otteniamo l'ID della company
  
      // 2️⃣ Creazione dell'utente con l'ID della company
      const userPayload = {
        email: formData.email,
        password: formData.password,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number: formData.phone_number,
        company_id: companyId,
      };
  
      const userResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        userPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const userId = userResponse.data.id; // Otteniamo l'ID dell'utente appena creato
  
      // 3️⃣ Creazione della subscription
      const subscriptionPayload = {
        plan_id: formData.plan_type,  // L'ID del piano selezionato
        user_id: userId, 
        company_id: companyId, 
        starts_at: formData.start_date, 
        ends_at: formData.end_date, 
        is_trial: formData.is_trial ? true : false,
      };
  
      await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions`,
        subscriptionPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      alert("User and subscription added successfully!");
      handleClose();
  
    } catch (error) {
      console.error("Error:", error.response?.data || error);
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "#191919", color: "white", textAlign: "center", fontFamily: "Urbanist" }}>Add New User</DialogTitle>

      <DialogContent sx={{ backgroundColor: "#191919", color: "white", padding: "24px", fontFamily: "Urbanist" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}><TextField name="first_name" label="First Name" fullWidth variant="outlined" value={formData.first_name} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/></Grid>
          <Grid item xs={12} sm={6}><TextField name="last_name" label="Last Name" fullWidth variant="outlined" value={formData.last_name} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/></Grid>
          {/*<Grid item xs={12}><FormControl fullWidth><InputLabel sx={{ color: "rgba(255, 255, 255, 0.7)" }}>Linked Company</InputLabel><Select name="company_id" value={formData.company_id} onChange={handleInputChange} sx={{ color: "white" }}>{companies.map((company) => (<MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>))}</Select></FormControl></Grid> */}
          <Grid item xs={12}><TextField name="company_id" label="Company Name" fullWidth variant="outlined" value={formData.company_id} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/></Grid>
          <Grid item xs={6}><FormControl fullWidth><InputLabel sx={{ color: "rgba(255, 255, 255, 0.7)" }}>User Type</InputLabel><Select name="user_type_id" value={formData.user_type_id} onChange={handleInputChange} sx={{ color: "white" }}>{userTypes.map((type) => (<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>))}</Select></FormControl></Grid>
          <Grid item xs={6}><FormControl fullWidth><InputLabel sx={{ color: "rgba(255, 255, 255, 0.7)" }}>Plan Type</InputLabel><Select name="plan_type" value={formData.plan_type} onChange={handleInputChange} sx={{ color: "white" }}>{plan.map((plans) => (<MenuItem key={plans.id} value={plans.id}>{plans.name}</MenuItem>))}</Select></FormControl></Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.is_trial}
                  onChange={(e) => setFormData((prev) => ({ ...prev, is_trial: e.target.checked }))}
                  sx={{
                    color: "#A3E635", 
                    "&.Mui-checked": {
                      color: "#A3E635", 
                    },
                  }}
                />
              }
              label="Trial Period"
              sx={{ color: "#fff" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="start_date"
              label="Start Date"
              type="date"
              fullWidth
              variant="outlined"
              value={formData.start_date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{ style: { color: "white" }, inputProps: { min: new Date().toISOString().split("T")[0] } }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="end_date"
              label="End Date"
              type="date"
              fullWidth
              variant="outlined"
              value={formData.end_date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{ style: { color: "white" } }}
            />
          </Grid>
          <Grid item xs={12}><TextField name="email" label="Email" fullWidth variant="outlined" value={formData.email} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/></Grid>
          <Grid item xs={6}><TextField name="password" label="Password" type={showPassword ? "text" : "password"} fullWidth variant="outlined" value={formData.password} onChange={handleInputChange} InputProps={{ style: { color: "white" }, endAdornment: <InputAdornment position="end"><IconButton onClick={() => setShowPassword(!showPassword)} sx={{ color: "white" }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton></InputAdornment> }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/></Grid>
          <Grid item xs={6}><TextField name="phone_number" label="Phone Number" fullWidth variant="outlined" value={formData.phone_number} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/></Grid>          
        </Grid>
      </DialogContent>

      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px", justifyContent: "center" }}>
        <Button onClick={handleClose} disabled={loading} sx={{ backgroundColor: "#191919", color: "#fff", fontWeight: "bold", border: "1px solid #A3E635" }}>Close</Button>
        <Button onClick={handleAddUser} disabled={loading} sx={{ backgroundColor: "#A3E635", color: "#000", fontWeight: "bold", border: "1px solid #A3E635" }}>{loading ? "Adding..." : "Add"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
