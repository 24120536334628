import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Radio } from '@mui/material';
import StandardButton from './standardButton';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

export default function QuestionsDialog({ open, onClose, intervieweeSurveyId, onCompleted }) {
  const [questions, setQuestions] = useState([]); // State to store questions
  const [innovations, setInnovations] = useState([]); // State to store startups
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0); // Current question index
  const [responses, setResponses] = useState({}); // State to store user responses
  const [isSummaryVisible, setIsSummaryVisible] = useState(false); // State to show the summary screen
  const [validationError, setValidationError] = useState(''); // State for validation error message

  // Stati per la chat
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);

  const selectedQuestion = questions[selectedQuestionIndex];

  // Function to fetch questions and startups from the API
  const fetchQuestionsAndInnovations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/advisory/questions?token=${intervieweeSurveyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data.questions); // Populate questions
      setInnovations(response.data.innovations); // Populate startups
      console.log("chiamata 1")
    } catch (error) {
      console.error('Error fetching questions and startups:', error);
    }
  };

  // Effect to load questions when the dialog opens
  useEffect(() => {
    if (open && intervieweeSurveyId) {
      fetchQuestionsAndInnovations();
    }
  }, [open, intervieweeSurveyId]);

  // Handle response change
  const handleResponseChange = (questionId, innovationId, score) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: {
        ...prevResponses[questionId],
        [innovationId]: score,
      },
    }));
    // Clear validation error when a response is changed
    setValidationError('');
  };

  // Function to proceed to the next question or show the summary
  const handleNext = () => {
    // Get the current question ID
    const currentQuestionId = selectedQuestion.question_id;
    // Get responses for the current question
    const currentQuestionResponses = responses[currentQuestionId] || {};
    // Check if responses exist for all innovations
    const allInnovationsResponded = innovations.every(
      (innovation) => currentQuestionResponses[innovation.id] !== undefined
    );

    if (!allInnovationsResponded) {
      // Set validation error
      setValidationError('Please respond for all startups before proceeding.');
    } else {
      // Clear validation error
      setValidationError('');
      if (selectedQuestionIndex < questions.length - 1) {
        setSelectedQuestionIndex(selectedQuestionIndex + 1);
      } else {
        setIsSummaryVisible(true); // Show the summary screen
      }
    }
  };

  // Function to return to a question from the summary screen
  const handleSelectQuestionFromSummary = (index) => {
    setIsSummaryVisible(false);
    setSelectedQuestionIndex(index);
  };

  // Function to build the payload for submission
  const buildPayload = () => {
    const answers = [];

    // Iterate over questions in responses
    Object.keys(responses).forEach((questionId) => {
      // Iterate over startups for each question
      Object.keys(responses[questionId]).forEach((innovationId) => {
        // Add each response to the answers array
        answers.push({
          innovation_id: parseInt(innovationId),
          question_id: parseInt(questionId),
          score_id: responses[questionId][innovationId],
        });
      });
    });

    // Return the payload object
    return { answers };
  };

  // Function to submit responses
  const handleSubmit = async () => {
    const payload = buildPayload();
  
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/advisory/submit?token=${intervieweeSurveyId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log("Submit successful");
  
      // Chiudi la dialog dopo il submit
      if (onCompleted) {
        onCompleted(); // Questo chiama il callback, che può includere ulteriore logica
      }
  
      if (onClose) {
        onClose(); // Chiude la dialog
      }
  
    } catch (error) {
      console.error('Error submitting responses:', error);
    }
  };
  

  // Custom onClose handler to prevent closing the dialog
  const handleDialogClose = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      // Do nothing, prevent closing
    } else {
      if (onClose) {
        onClose();
      }
    }
  };

  // Funzione per inviare messaggi nella chat
  const sendMessage = async () => {
    if (!messageInput.trim()) return;

    // Aggiungi il messaggio dell'utente alla chat
    setChatMessages(prev => [...prev, { sender: 'You', text: messageInput }]);
    const currentMessage = messageInput;
    setMessageInput('');
    setLoadingChat(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/advisory/interviewee-message?type=survey&token=${intervieweeSurveyId}`,
        { message: currentMessage },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      //console.log('Response from server:', response.data);

      //console.log("chiamata 3")

      // Usa 'answer' o la proprietà corretta in base alla risposta del server
      const serverMessage = response.data.answer;

      if (serverMessage) {
        setChatMessages(prev => [...prev, { sender: 'Server', text: serverMessage }]);
      } else {
        console.error('Messaggio del server non disponibile:', response.data);
        // Aggiungi un messaggio di errore alla chat o gestisci l'errore
      }
    } catch (err) {
      console.error('Errore durante l\'invio del messaggio', err);
      // Gestisci l'errore se necessario
    } finally {
      setLoadingChat(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px !important',
          overflow: 'hidden',
          backgroundColor: '#000',
          height: '50vh',
          border: '1px solid #C5FF55',
        },
      }}
    >
      <DialogContent sx={{ fontFamily: 'Urbanist, sans-serif', backgroundColor: '#000', padding:'0'}}>
        <div className="flex w-full h-full">
          {/* Sezione principale (2/3) */}
          <div className={`flex flex-col justify-center ${window.innerWidth < 768 ? 'w-full' : 'w-2/3'} px-4`} style={{borderRight: window.innerWidth < 768 ? 'none' : "1px solid #C5FF55", borderBottom:"1px solid #C5FF55", padding: "20px"}}>
            {isSummaryVisible ? (
              // Summary screen
              <div className="flex flex-col w-full h-full text-white overflow-auto">
                <ul className="list-disc-q pl-5">
                  {questions.map((question, index) => (
                    <li key={question.question_id}>
                      <button
                        className="text-left"
                        onClick={() => handleSelectQuestionFromSummary(index)}
                      >
                        {index + 1}. {question.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : selectedQuestion ? (
              // Display the current question
              <div className="flex flex-col w-full rounded-2xl border border-[rgb(var(--global-color-primary))] overflow-auto">
                {/* Question Number and Title */}
                <div className="bg-[rgb(var(--global-color-primary))] text-black font-bold w-full p-5">
                  <span className="text-xl">{`${selectedQuestionIndex + 1}. ${selectedQuestion.title}`}</span>
                </div>

                <div className="flex flex-col gap-4 w-full p-5 text-white bg-black-l">
                  <span className="w-full">
                    {selectedQuestion.description || 'Descrizione non disponibile'}
                  </span>

                  {/* Responses Table */}
                  <div className="w-full overflow-auto">
                    <table className="table-auto w-full text-center text-white border-separate border-spacing-2">
                      {/* Column Headers with Scores */}
                      <thead>
                        <tr>
                          <th className="text-left">Startup</th>
                          {selectedQuestion.answers.map((answer) => (
                            <th key={answer.score_id} className="px-4 py-2">
                              {answer.description}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      {/* Table Body with Startups and Radio Buttons */}
                      <tbody>
                        {innovations.map((innovation) => (
                          <tr key={innovation.id}>
                            {/* Startup Name */}
                            <td className="text-left font-bold">{innovation.name}</td>

                            {/* Radio Buttons for Each Startup */}
                            {selectedQuestion.answers.map((answer) => (
                              <td key={answer.score_id}>
                                <Radio
                                  checked={
                                    responses[selectedQuestion.question_id]?.[innovation.id] === answer.score_id
                                  }
                                  onChange={() =>
                                    handleResponseChange(selectedQuestion.question_id, innovation.id, answer.score_id)
                                  }
                                  value={answer.score_id}
                                  name={`radio-${selectedQuestion.question_id}-${innovation.id}`}
                                  sx={{
                                    color: "#c5ff55",
                                    "&.Mui-checked": {
                                      color: "#c5ff55",
                                    },
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Validation Error Message */}
                  {validationError && (
                    <div className="text-red-500 mt-2">
                      {validationError}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>Caricamento...</div>
            )}
          </div>

          {/* Sezione chat (1/3) */}
          <div className={`flex flex-col ${window.innerWidth < 768 ? 'w-full' : 'w-1/3'}`} style={{borderBottom: '1px solid #C5FF55'}}>
            {/* Chat Messages Area */}
            <div className="flex flex-col flex-grow bg-black rounded-2xl text-white overflow-y-auto">
              <div className="p-2" style={{borderBottom: '1px solid #C5FF55'}}>
                <h2 className="text-xl font-bold mb-0">AI</h2>
              </div>
              <div className="flex flex-col flex-grow overflow-y-auto p-2 ">
                {chatMessages.map((m, index) => (
                  <div
                    key={`${m.sender}-${index}`}
                    className={`flex flex-col gap-1 ${
                      m.sender === "You" ? "items-end" : "items-start"
                    }`}
                  >
                    <span className="font-semibold">{m.sender}</span>
                    <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap msgClass">
                      {m.sender === "Server" ? (
                        <ReactMarkdown>{m.text}</ReactMarkdown>
                      ) : (
                        <span>{m.text}</span>
                      )}
                    </div>
                  </div>
                ))}
                {loadingChat && (
                  <div className="flex items-center">
                    <span>Sto pensando...</span>
                  </div>
                )}
              </div>
            </div>
            {/* Input per i messaggi */}
            <div className="flex items-center mt-2 p-2">
              <input
                type="text"
                className="flex-grow p-2 bg-gray-700 rounded-l-2xl focus:outline-none text-white"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                placeholder=""
              />
              <button
                className="p-2 rounded-r-2xl"
                onClick={sendMessage}
                disabled={loadingChat}
                style={{ backgroundColor: '#C5FF55', color: '#000' }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions
        sx={{
          fontFamily: 'Urbanist, sans-serif',
          backgroundColor: '#000',
          paddingTop: '40px',
          paddingBottom: '40px',
          justifyContent: 'center',
          gap: '50px',
        }}
      >
        {/* Button to Proceed to Next Question or Submit */}
        {isSummaryVisible ? (
          <StandardButton
            onClick={handleSubmit}
            autoFocus={true}
            text="Send"
            width="300px"
          />
        ) : (
          <StandardButton
            onClick={handleNext}
            autoFocus={true}
            text={selectedQuestionIndex === questions.length - 1 ? 'Termina' : 'Next Question'}
            width="300px"
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
