import React, { useState, useEffect, useContext } from "react";
import StandardButton from "../standardButton";
import StandardTextfield from "../standardTextfield";
import StandardSelect from "../standardSelect";
import FlexibleDataFetcher from "../flexibileDataFetcher";
import axios from "axios";
import DialogTags1 from "../adminStartupDetails/dialogs/dialogTags1Front";
import DialogTags2 from "../adminStartupDetails/dialogs/dialogTags1Front";
import { Button, Chip } from "@mui/material";

export default function CompanyDetails({companyId}) {
  const [company, setCompany] = useState({});
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [country, setCountry] = useState(0);
  const [city, setCity] = useState("");
  const [legalName, setLegalName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");

  const [dialogTagsOpen, setDialogTagsOpen] = useState(false);
  const [dialogSectorsOpen, setDialogSectorsOpen] = useState(false);

  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  const [logoFile, setLogoFile] = useState(null);

  //const companyId = user.user?.companies?.[0]?.id;

  //console.log("companyId",companyId)

  // Funzione per caricare i dati dall'API
  useEffect(() => {
    
    if (companyId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/companies/${companyId}?admin=true`)
        .then((response) => {
          const companyData = response.data;
          setCompany(companyData);
          setCountry(companyData.country_id || 0);
          setCity(companyData.city || "");
          setLegalName(companyData.legal_name || "");
          setBrandName(companyData.brand_name || "");
          setLinkedin(companyData.linkedin_url || "");
          setPhone(companyData.phone_number || "");
          setWebsite(companyData.website || "");
          setDescription(companyData.description || "");
          setSelectedSectors(companyData.categories || []);        
        })
        
        .catch((err) => {
          setError(err.message || "Failed to fetch company data");
        })
        .finally(() => {
          setLoading(false);
        });
        
    }
  }, []);

  // Gestione del salvataggio dei tag
  const handleSaveTags = (tags) => {
    setSelectedTags(tags); // Aggiorna lo stato con i nuovi tag selezionati
    console.log("Tags saved:", tags); // IDs e nomi
  };

  // Gestione del salvataggio dei settori
  const handleSaveSectors = (sectors) => {
    setSelectedSectors(sectors); // Aggiorna lo stato con i nuovi settori selezionati
    console.log("Sectors saved:", sectors); // IDs e nomi
  };

  const handleEditUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
  
      // Aggiorniamo i dati della company
      const payload = {
        name: company.name,
        city: city,
        country_id: parseInt(country),
        linkedin_url: linkedin,
        website: website,
        phone_number: phone,
        description: description,
        categories: selectedSectors.map((sector) => sector.id),
      };
  
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/companies/${company.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Company updated successfully:", response.data);
  
      // Se c'è un file da caricare, effettuiamo l'upload
      if (logoFile) {
        await handleUploadLogo(company.id, logoFile, token);
      }
  
    } catch (err) {
      console.error("Failed to update company:", err.response?.data || err.message);
      setError(err.message || "Update failed");
    } finally {
      alert("Company Updated");
      setLoading(false);
    }
  };
  
  // Funzione per caricare il logo
  const handleUploadLogo = async (companyId, file, token) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
  
      await axios.post(
        `${process.env.REACT_APP_API_URL}/companies/${companyId}/upload-logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Logo uploaded successfully");
    } catch (err) {
      console.error("Failed to upload logo:", err.response?.data || err.message);
      setError("Error uploading logo");
    }
  };
  

  return (
    <React.Fragment>
      <div className="flex flex-col gap-4 h-full p-5 w-full rounded-2xl">
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between mb-5">
            <h1 className="text-4xl font-medium mb-0">Company Details</h1>
            {company.logo_url && (
              <img
                src={`https://dev.retailhub.ai/assets/${company.logo_url}`}
                alt="Company logo"
                className="w-20 h-20 object-cover rounded-2xl"
              />
            )}
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div className="flex gap-4">
                <StandardSelect
                  label="Country"
                  value={country}
                  items={countries}
                  onChange={(e) => setCountry(e.target.value)}
                />
                <StandardTextfield
                  className="w-full"
                  label="City"
                  value={city || ""}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="flex gap-4">
                <StandardTextfield
                  className="basis-1/2"
                  label="Legal name"
                  value={legalName || ""}
                  onChange={(e) => setLegalName(e.target.value)}
                />
                <StandardTextfield
                  className="basis-1/2"
                  label="Brand name"
                  value={brandName || ""}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </div>
              <div className="flex gap-4">
                <StandardTextfield
                  className="basis-1/3"
                  label="Linkedin company page"
                  value={linkedin || ""}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
                <StandardTextfield
                  className="basis-1/3"
                  label="Phone"
                  value={phone || ""}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <StandardTextfield
                  className="basis-1/3"
                  label="Website url"
                  value={website || ""}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
              <StandardTextfield
                label="Company description"
                value={description || ""}
                multiline
                minRows={4}
                onChange={(e) => setDescription(e.target.value)}
              />



              <div className="flex flex-col gap-4 mt-4">
                {/* Chip per i settori selezionati */}
                <div className="flex flex-wrap gap-2">
                  {selectedSectors.map((sector) => (
                    <Chip
                      key={sector.id}
                      label={sector.name}
                      sx={{
                        backgroundColor: "rgb(var(--global-color-primary))",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
                <StandardButton
                  text="Edit Sectors"
                  onClick={() => setDialogSectorsOpen(true)}
                  width="150px"
                />
              </div>

              <div className="flex flex-col gap-4 mt-4">
                <label className="text-lg font-medium">Upload Company Logo</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      setLogoFile(e.target.files[0]);
                    }
                  }}
                />
                {logoFile && (
                  <img
                    src={URL.createObjectURL(logoFile)}
                    alt="Preview"
                    className="w-20 h-20 object-cover rounded-2xl mt-2"
                  />
                )}
              </div>

            </>
          )}
          <div className="flex justify-center items-center flex-wrap gap-5 mt-6">
            <StandardButton
              variant="outlined"
              text="Reset"
              width="150px"
              onClick={() => {
                setCountry(company.country_id || 0);
                setCity(company.city || "");
                setLegalName(company.legal_name || "");
                setBrandName(company.brand_name || "");
                setLinkedin(company.linkedin_url || "");
                setPhone(company.phone_number || "");
                setWebsite(company.website || "");
                setDescription(company.description || "");
                setSelectedTags([]);
                setSelectedSectors([]);
              }}
            />
            <StandardButton
              variant="filled"
              text="Save"
              width="150px"
              onClick={handleEditUser}
            />
          </div>
        </div>
      </div>

      {/* Dialog per i settori */}
      <DialogTags2
        open={dialogSectorsOpen}
        handleClose={() => setDialogSectorsOpen(false)}
        onSave={handleSaveSectors}
        companyId={companyId}
      />

      <FlexibleDataFetcher
        endpoint={"values/countries"}
        onSuccess={(data) => setCountries(data.data || [])}
        onError={(err) => setError(err.message)}
      />
      <FlexibleDataFetcher
        endpoint={"values/innovation-filters"}
        onSuccess={(data) => setTags(data.data.tags || [])}
        onError={(err) => setError(err.message)}
      />
    </React.Fragment>
  );
}
