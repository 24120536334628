import React, { useState, useEffect, useRef } from 'react';
import QuestionsDialog from '../questionsDialog';
import InviteCollaboratorsDialog2 from '../inviteCollaboratorsDialog2';
import axios from 'axios';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StandardTextfield from "../standardTextfield";
import ReactMarkdown from 'react-markdown';

export default function Step6({ onNext, chatMessages, setChatMessages, intervieweeSurveyId, SurveyId }) {
  const [dialogOpen, setDialogOpen] = useState(false); // Controls QuestionsDialog
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false); // Controls InviteCollaboratorsDialog
  const [users, setUsers] = useState([]); // Store users data for InviteCollaboratorsDialog
  const [newMessage, setNewMessage] = useState(""); // State for new message
  const [isTyping, setIsTyping] = useState(false); // State to track typing indicator
  const [serverReplied, setServerReplied] = useState(false); // State to show "Go to next step" button
  const [error, setError] = useState(null);
  const [startups, setStartups] = useState([]);

  const token = localStorage.getItem('token');
  const messagesEndRef = useRef(null);

  useEffect(() => {
   
    if (intervieweeSurveyId) {
      setInviteDialogOpen(true);
    }
  }, [intervieweeSurveyId]);

  const handleInviteCompleted = () => {
    setInviteDialogOpen(false); // Chiude InviteCollaboratorsDialog
    setDialogOpen(true); // Ora apre QuestionsDialog
  };

  // Scroll to bottom when chatMessages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  // Callback when QuestionsDialog is closed and submission is complete
  // Updated code in Step6
  const handleQuestionsCompleted = async (innovations) => {
    setDialogOpen(false); // Close QuestionsDialog
  
    // Fetch users data for InviteCollaboratorsDialog
    try {
      const token = localStorage.getItem('token');
      const companyId = localStorage.getItem('companyId');
  
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/advisory/interviewees?company_id=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data.data);
      setStartups(innovations); // Set the startups received
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users');
    }
  
    setInviteDialogOpen(true); // Open InviteCollaboratorsDialog
  };


  // Function to close InviteCollaboratorsDialog
  const handleCloseInviteDialog = () => {
    setInviteDialogOpen(false);
  };

  // Function to handle new message input
  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  // Function to handle sending a new message
  const handleAddMessage = async () => {
    if (newMessage.trim() !== "") {
      setChatMessages(prevMessages => [
        ...prevMessages,
        { id: prevMessages.length, text: newMessage, sender: "You" }
      ]);

      setIsTyping(true);

      try {
        const payload = { message: newMessage };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/advisory/message`,
          payload,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        setTimeout(() => {
          if (response.data && response.data.answer) {
            setChatMessages(prevMessages => [
              ...prevMessages,
              { id: prevMessages.length, text: response.data.answer, sender: "Server" }
            ]);
            setServerReplied(true);
          }
          setIsTyping(false);
        }, 1000);

      } catch (error) {
        console.error('Error sending message:', error);
        setError('Error sending message');
        setIsTyping(false);
      }

      setNewMessage("");
    }
  };

   // Function to handle back button click
   const handleBackToStep1 = async () => {
    try {
      setIsTyping(true); // Disable input while processing      
      onNext(1);
    } catch (error) {
      window.location.reload();
      console.error('Error changing to step 1:', error);
      //setError('Error changing step.');
      window.location.reload();
    } finally {
      setIsTyping(false); // Re-enable input
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a new line
      handleAddMessage();     // Calls your message sending function
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* Chat Container */}
      <div className="flex flex-col flex-grow h-0">
        {/* Chat Messages Area */}
        <div className="flex flex-col flex-grow overflow-y-auto px-5">
          {chatMessages.map((m, index) => (
            <div
              key={`${m.sender}-${index}`}
              className={`flex flex-col gap-1 ${
                m.sender === "You" ? "items-end" : "items-start"
              }`}
            >
              <span className="font-semibold">{m.sender}</span>
              <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap">
                {m.sender === "Server" ? (
                  <ReactMarkdown>{m.text}</ReactMarkdown>
                ) : (
                  <span>{m.text}</span>
                )}
              </div>
            </div>
          ))}

          {/* Typing Indicator */}
          {isTyping && (
            <div className="flex flex-col gap-1 items-start mt-4">
              <span className="font-semibold">AI Navigator</span>
              <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col">
                <span>Typing...</span>
              </div>
            </div>
          )}

          {/* Scroll Anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-5 w-full bg-black flex items-center gap-2">
          {/* Back to Step 1 Button */}
          <Button
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "#000 !important",
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleBackToStep1}
            disabled={isTyping}
          >
            Restart
          </Button>

          <StandardTextfield
            variant="outlined"
            multiline={true}
            value={newMessage}
            placeholder="Write here..."
            onChange={handleNewMessage}
            onKeyDown={handleKeyDown} 
            disabled={isTyping}
            style={{ flexGrow: 1 }}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black",
              minWidth: '48px',
              minHeight: '48px',
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleAddMessage}
            disabled={isTyping}
          >
            <SendIcon />
          </Button>
        </div>
      </div>

      {/* InviteCollaboratorsDialog */}
      <InviteCollaboratorsDialog2
        open={inviteDialogOpen}
        onClose={handleInviteCompleted}
        users={users}
        SurveyId={SurveyId}
        startups={startups} // Pass the startups here
      />

      {/* QuestionsDialog */}
      <QuestionsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCompleted={handleQuestionsCompleted} 
        intervieweeSurveyId={intervieweeSurveyId}
      />

      


      {/* Display error */}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
}
