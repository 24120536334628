import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip } from "@mui/material";
import axios from "axios";

const DialogTags1 = ({ open, handleClose, startupData, innovationId, companyId }) => {
  const [categories, setCategories] = useState([]); // Contiene solo i figli di secondo livello (senza duplicati)
  const [selectedTags, setSelectedTags] = useState([]); // Tag selezionati (prefiltrati per startup)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        if (!apiUrl) {
          console.error("API URL is not defined.");
          return;
        }

        // Chiamate API per tassonomie e dati specifici (innovazione o azienda)
        const [taxonomyResponse, entityResponse] = await Promise.all([
          axios.get(`${apiUrl}/taxonomies?type=category`),
          companyId
            ? axios.get(`${apiUrl}/companies/${companyId}`)
            : axios.get(`${apiUrl}/innovations/${innovationId}`)
        ]);

        const taxonomyData = taxonomyResponse.data.data;
        const entityData = entityResponse.data.data;   
        
        //console.log("entityData",entityData)

        // Filtra i figli di secondo livello dai dati della tassonomia
        const secondLevelCategories = getFirstLevelCategories(taxonomyData);
        setCategories(secondLevelCategories);

        // Filtra i tag già presenti nell'entità corrispondente (azienda o innovazione)
        if (companyId && entityData?.categories) {
          setSelectedTags(entityData.categories);
        } else if (innovationId && entityData?.taxonomies?.categories) {
          setSelectedTags(entityData.taxonomies.categories);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategories();
  }, [startupData, companyId, innovationId]);


  const getFirstLevelCategories = (nodes) => {
    return nodes.filter((node) => !node.parent_id); // Filtra solo gli elementi senza parent_id
  };

  // Gestione selezione tag
  const handleTagSelect = (tag) => {
    if (!selectedTags.some((t) => t.id === tag.id)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Gestione rimozione tag
  const handleTagRemove = (tagId) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== tagId));
  };

  // Salvataggio tag selezionati
  const handleSave = async () => {
    try {
      const updatedTags = selectedTags.map((tag) => tag.id);
      const payload = { categories: updatedTags };

      let apiUrl = "";
      if (companyId) {
        apiUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}`;
      } else if (innovationId) {
        apiUrl = `${process.env.REACT_APP_API_URL}/innovations/${innovationId}`;
      } else {
        console.error("Neither companyId nor innovationId is provided.");
        return;
      }

      await axios.patch(apiUrl, payload);

      //console.log("Tags updated successfully:", updatedTags);
      handleClose(); // Chiudi il dialog dopo il salvataggio
    } catch (error) {
      console.error("Error saving tags:", error);
    }
  };


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          fontWeight: "bold",
          backgroundColor: "#191919",
          color: "white",
          fontFamily: "Urbanist",
        }}
      >
        Sectors of Competence
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white" }}>
        {/* Tag selezionati */}
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedTags.map((tag) => (
            <Chip
              key={tag.id}
              label={tag.name}
              onDelete={() => handleTagRemove(tag.id)}
              deleteIcon={
                <span
                  style={{
                    color: "rgb(var(--global-color-primary))",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </span>
              }
              sx={{
                backgroundColor: "transparent",
                border: "1.5px solid rgb(var(--global-color-primary))",
                borderRadius: "5px",
                fontFamily: "Urbanist",
                color: "rgb(var(--global-color-primary))",
                fontWeight: "bold",
              }}
            />
          ))}
        </div>

        {/* Figli di secondo livello (unici) */}
        <div className="flex flex-wrap gap-2">
          {categories.map((category) => (
            <Chip
              key={category.id}
              label={category.name}
              onClick={() => handleTagSelect({ id: category.id, name: category.name })}
              sx={{
                backgroundColor: "white",
                fontFamily: "Urbanist",
                color: "black",
                fontWeight: "bold",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgb(var(--global-color-primary))",
                  color: "black",
                },
              }}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919" }}>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "gray",
            color: "white",
            "&:hover": { backgroundColor: "rgba(128, 128, 128, 0.8)" },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTags1;
