import React, { useEffect, useRef, useContext } from "react";
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StandardTextfield from "../standardTextfield";
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import showdown from 'showdown';
import { UserContext } from '../../components/context/UserContext';

// Import necessary Material-UI components for the modal
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function Step1({ chatMessages, setChatMessages, onNext, isTypingAncestor }) {
  const [newMessage, setNewMessage] = React.useState("");
  const [error, setError] = React.useState(null);
  const [isTyping, setIsTyping] = React.useState(false);
  const [typingMessage, setTypingMessage] = React.useState("");
  const [showEvaluationButton, setShowEvaluationButton] = React.useState(false);
  const messagesEndRef = useRef(null);

  const { user } = useContext(UserContext);
  const [userPermissions, setUserPermissions] = React.useState(null);

  const token = localStorage.getItem('token');
  const fullMessage = useRef("");

  const converter = new showdown.Converter();

  // State variables for modal and startups selection
  const [showModal, setShowModal] = React.useState(false);
  const [innovationsData, setInnovationsData] = React.useState(null);
  const [selectedStartupIds, setSelectedStartupIds] = React.useState([]);

  useEffect(() => {
    if (user?.permissions) {
        const permissions = user.permissions.map(p => p.name);
        setUserPermissions(permissions);
        //console.log("User permissions updated:", permissions);
    }
  }, [user]);

  useEffect(() => {
    if (userPermissions !== null) {
        fetchChatHistory();
    }
  }, [userPermissions]);

  const fetchChatHistory = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/steps/current`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const stepData = response.data;

        if (stepData.chat && stepData.chat.length > 0) {
            const chatHistory = stepData.chat.flatMap((chatItem, index) => ([            
                { id: index * 2 + 1, text: chatItem.answer, sender: "Server" },
                { id: index * 2, text: chatItem.question, sender: "You" }
            ])).reverse();

            setChatMessages(chatHistory);
        } else {
            setChatMessages([{
                id: 0,
                text: "Type something to start chat",
                sender: "Server"
            }]);
        }

        if (stepData.innovations && stepData.innovations.length > 0) {
            setInnovationsData(stepData.innovations);
            const hasAINavigatorAccess = userPermissions?.includes("access.advisory_program");

            if (hasAINavigatorAccess) {
                setShowEvaluationButton(true);
            } else {
                setShowEvaluationButton(false);
            }
        }

    } catch (error) {
        console.error('Error fetching chat history:', error);
        setError('Unable to load chat history.');
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages, typingMessage, isTypingAncestor]);

  // Function to replace bold names with links
  function replace_bold_names_with_links(response) {
    const pattern = /\*\*(.*?)\*\* \(ID: (.*?)\)/g;

    // Replace each found bold text with a Markdown link
    return response.replace(pattern, (match, name, sid) => {
      if (name && sid) {
        const url = `${process.env.REACT_APP_BASE_URL}/startups/startup/${sid}`;
        return `[${name}](${url})`;
      }
      return match;
    });
  }

  const handleAddMessage = async () => {
    if (newMessage.trim() !== "") {
      setChatMessages(prevMessages => [
        ...prevMessages,
        { id: prevMessages.length, text: newMessage, sender: "You" }
      ]);

      setIsTyping(true);
      setTypingMessage("Typing...");

      try {
        const payload = {
          message: newMessage,
          stream: true
        };

        fullMessage.current = "";

        const response = await fetch(`${process.env.REACT_APP_API_URL}/advisory/message?stream=true`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (!response.body) {
          throw new Error('ReadableStream not supported or response body is empty.');
        }

        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
        let accumulatedText = "";

        while (true) {
          const { value, done } = await reader.read();

          if (done || accumulatedText.includes("[DONE]")) {
            break;
          }

          value.replaceAll("\n\n\n\n", '__DD__\n\n').split("\n\n").forEach((element) => {
            if (element !== "") {
              const processedElement = element.replaceAll("__DD__", '\n\n');
              if (processedElement.startsWith("data: ")) {
                accumulatedText += processedElement.substring(6);
              } else {
                accumulatedText += processedElement;
              }
            }
          });

          // Remove the conversion to HTML
          setTypingMessage(accumulatedText.replaceAll("\n   -", "\n    -"));
        }

        // Clean the final text and update `fullMessage.current`
        fullMessage.current = accumulatedText
          .replace(/data:  -/g, '')    
          .replace(/data:    -/g, '')
          .replace(/data: -/g, '')
          .replace(/\[DONE\]/g, '')
          //.replace(/\[\s*website\s*\]\(\s*N\s*\/\s*A\s*\)/g, '')
          .trim();

        // Apply replace_bold_names_with_links to fullMessage.current
        fullMessage.current = replace_bold_names_with_links(fullMessage.current);

        if (fullMessage.current) {  // Check that it's not empty
          setChatMessages(prevMessages => [
            ...prevMessages,
            { id: prevMessages.length, text: fullMessage.current, sender: "Server" }
          ]);
        }

        setTypingMessage("");
        setIsTyping(false);

        // Call /chat-current-selection to check if the evaluation button should be shown
        try {
          const currentSelectionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/chat-current-selection`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
        
          const data = currentSelectionResponse.data;
          //console.log('Response from /chat-current-selection:', data);
        
          // Verifica e mappa i dati delle innovazioni
          if (data?.innovations && Array.isArray(data.innovations)) {
            const innovations = data.innovations.map(innovation => ({
              id: innovation.id,
              name: innovation.name,
              logo_url: innovation.logo_url, // Puoi includerlo se serve per il rendering
            }));
            setInnovationsData(innovations);
            
            // Controlla se l'utente ha accesso a "access.ai_navigator"
            const hasAINavigatorAccess = userPermissions?.includes("access.advisory_program");

            //console.log("hasAINavigatorAccess",hasAINavigatorAccess)
            //console.log("innovations",innovations)

            // Mostra il pulsante solo se l'utente NON ha accesso a AI Navigator
            if (hasAINavigatorAccess && innovations.length > 0) {
                setShowEvaluationButton(true);
            } else {
                setShowEvaluationButton(false);
            }

          } else {
            setInnovationsData([]); // Svuota i dati se non ci sono innovazioni
            setShowEvaluationButton(false);
          }
        } catch (error) {
          console.error('Error fetching chat current selection:', error);
          setInnovationsData([]); // Gestione fallback
          setShowEvaluationButton(false);
        }

      } catch (error) {
        console.error('Error during fetch:', error);
        setError('Error sending message');
        setIsTyping(false);
      }

      setNewMessage("");
    }
  };

  // Function to open the modal instead of going to step 2 directly
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to close the modal and reset selections
  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
    setSelectedStartupIds([]);
  };

  // Handle checkbox change in the modal
  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedStartupIds(prev => [...prev, id]);
    } else {
      setSelectedStartupIds(prev => prev.filter(sid => sid !== id));
    }
  };

  // Function to confirm selection and proceed to next step
  const handleConfirmSelection = () => {
    if (selectedStartupIds.length === 0) {
      // Show error message
      setError('Please select at least one startup.');
      return;
    }

    // Close modal and call onNext with selected startup IDs
    setShowModal(false);
    onNext(2, selectedStartupIds);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); 
      handleAddMessage();    
    }
  };

  const handleReset = async () => {
   // Effettua la POST per aggiornare lo step
   const payload = { new_step: 1 }

   const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/advisory/steps`,
    payload,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    );

    window.location.reload();
  };

  return (
    <div className="flex flex-col h-full w-full">
      
      {/* Header */}
      <div className="flex p-5 pb-5 border-b border-white mb-5 justify-between items-center">
        <div className="flex flex-row">
          <FaceRetouchingNaturalIcon />
          <span>AI</span>
        </div>
        <div className="flex flex-row">
          <Button
              sx={{
                backgroundColor: "rgb(var(--global-color-primary))",
                color: "#000 !important",
                '&:hover': {
                  backgroundColor: "rgb(var(--global-color-primary))",
                }
              }}
              onClick={handleReset}
              disabled={isTyping}
            >
              Start New Chat
          </Button>
        </div>
      </div>

      <div className="flex flex-col flex-grow h-0 overflow-y-auto">
        <div className="flex flex-col flex-grow overflow-y-auto px-5">
        
          {chatMessages.map((m, index) => (
            <div
              key={`${m.sender}-${index}`}
              className={`flex flex-col gap-1 ${m.sender === "You" ? "items-end" : "items-start"}`}
            >
              <span className="font-semibold">{m.sender}</span>
              <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap msgClass">
                {m.text && (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {replace_bold_names_with_links(m.text)}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          ))}

          {isTyping && typingMessage && (
            <div className="flex flex-col gap-1 items-start">
              <span className="font-semibold">AI Navigator</span>
              <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col msgClass">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {replace_bold_names_with_links(typingMessage)}
                </ReactMarkdown>
              </div>
            </div>
          )}

          {isTypingAncestor && (
            <div className="flex flex-col gap-1 items-start">
              <span className="font-semibold">AI Navigator</span>
              <div className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col msgClass">
                <span>Typing...</span>
              </div>
            </div>
          )}

          <div ref={messagesEndRef} />
        </div>

        <div className="p-5 w-full bg-black flex gap-5">
          {showEvaluationButton && (
            <Button
              sx={{
                backgroundColor: "rgb(var(--global-color-primary))",
                color: "#000 !important",
                '&:hover': {
                  backgroundColor: "rgb(var(--global-color-primary))",
                }
              }}
              onClick={handleOpenModal} 
              disabled={isTyping}
            >
              Start Evaluation
            </Button>
          )}

          <StandardTextfield
            variant="outlined"
            multiline={true}
            value={newMessage}
            placeholder="Input here"
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={isTyping}
            style={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black"
            }}
            onClick={handleAddMessage}
            disabled={isTyping}
          >
            <SendIcon />
          </Button>
        </div>
      </div>

      {error && !showModal && <div style={{ color: 'red' }}>{error}</div>}

      {/* Modal Dialog */}
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            backgroundColor: 'black',
            color: 'white',
            width: '400px',
            fontFamily: 'Urbanist, sans-serif',
            borderRadius: '10px',
          },
        }}
      >
        <DialogTitle style={{ color: 'white' }}>Select Startups</DialogTitle>
        <DialogContent>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <FormGroup>
          {Array.isArray(innovationsData) && innovationsData.length > 0 && innovationsData.map((startup) => (
            <FormControlLabel
              key={startup.id}
              control={
                <Checkbox
                  checked={selectedStartupIds.includes(parseInt(startup.id, 10))}
                  onChange={(e) => handleCheckboxChange(e, parseInt(startup.id, 10))}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'rgb(var(--global-color-primary))',
                    },
                  }}
                />
              }
              label={startup.name}
              sx={{ color: 'white' }}
            />
          ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} sx={{ color: 'rgb(var(--global-color-primary))' }}>Cancel</Button>
          <Button onClick={handleConfirmSelection} sx={{ color: 'rgb(var(--global-color-primary))' }}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
