import React, { useState, useEffect } from "react";
import { Checkbox, Chip } from "@mui/material";
import StandardButton from "../standardButton";
import StandardTextfield from "../standardTextfield";
import StandardSelect from "../standardSelect";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; 
import DialogTags1 from "./dialogs/dialogTags2Front";
import DeleteAccountUserDialog from "./dialogs/deleteAccountUser";

export default function AccountInformation({ startupData, userId, companyId }) {

  const [user, setUser] = useState({
    id: null,
    firstname: "",
    lastname: "",
    role: "",
    roleOptions: [],
    department: "",
    departmentOptions: [],
    position: "",
    image: "",
    phone: "",
    email: "",
    faEnabled: false,
  });

  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [selectedPositionId, setSelectedPositionId] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [dialogTagsOpen, setDialogTagsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const [hasUsers, setHasUsers] = useState(false);

  const [dialogDeleteUserOpen, setDialogDeleteUserOpen] = useState(false);

  const isUserAdmin = localStorage.getItem("roleName") === "admin";

  //console.log("startupData", startupData)

  const handleChange = (event, field) => {
    switch (field) {
      case "firstname":
        setFirstname(event.target.value);
        break;
      case "lastname":
        setLastname(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "department":
        setSelectedDepartmentId(event.target.value);
        break;
      case "position":
        setSelectedPositionId(event.target.value);
        break;
      case "role":
        setSelectedRoleId(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;        
      default:
        break;
    }
  };

  const handleResetUser = () => {
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setPhone(user.phone);
    setSelectedDepartmentId(user.department);
    setSelectedPositionId(user.position);
    setSelectedRoleId(user.role);
    setPassword("");
  };

  const handleEditUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const url = hasUsers
        ? `${process.env.REACT_APP_API_URL}/user`
        : `${process.env.REACT_APP_API_URL}/users`;
      const method = hasUsers ? axios.patch : axios.post;

      const payload = {
        first_name: firstname,
        last_name: lastname,
        phone_number: phone,
        //email: email,
        department_id: selectedDepartmentId,
        position_id: selectedPositionId,
        tags: selectedTags.map((tag) => tag.id),
      };

      const innovation_id = startupData.id;
      
      if (!hasUsers) {
        //console.log("hasUsers",hasUsers);
        payload.password = password;
        payload.innovation_id = innovation_id;
        payload.email = email;
        //payload.confirm_password = password;
      }

      alert("User updated");
  
      await method(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      alert("User cannot be updated");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  
  // Variabile per il pulsante nel JSX
  const buttonLabel = hasUsers ? "Save" : "Create";

  const fetchDatas = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
  
      const [
        userResponse,
        departmentResponse,
        positionsResponse,
        rolesResponse
      ] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/innovations/${startupData.id}?admin=true`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get(`${process.env.REACT_APP_API_URL}/values/departments`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get(`${process.env.REACT_APP_API_URL}/values/positions`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get(`${process.env.REACT_APP_API_URL}/values/roles`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      setDepartments(departmentResponse.data.data);
      setPositions(positionsResponse.data.data);
      setRoles(rolesResponse.data.data);
  
      const userData = userResponse.data.data.user;
      const hasUsers = userResponse.data.data.user !== null && typeof userResponse.data.data.user === "object";
      setHasUsers(hasUsers);
  
      //console.log("userData", userResponse.data.data.user);
      //console.log("hasUsers", hasUsers);
  
      if (userData) {
        setUser((prevUser) => ({
          ...prevUser,
          id: userData.id,
          firstname: userData.first_name,
          lastname: userData.last_name,
          phone: userData.phone_number,
          email: userData.email,
          faEnabled: userData.is_enabled_2fa,
          department: userData.department_id || "",
          position: userData.position_id || "",
        }));
  
        setFirstname(userData.first_name);
        setLastname(userData.last_name);
        setPhone(userData.phone_number);
        setEmail(userData.email);
        setSelectedDepartmentId(userData.department_id || "");
        setSelectedPositionId(userData.position_id || "");
        setSelectedRoleId(userData.role_id || "");
      }
  
      // Salvo lo stato di hasUsers per usarlo nel componente
      setHasUsers(hasUsers);
      
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchDatas();
  }, []);

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  // Gestione del salvataggio dei tag
  const handleSaveTags = (tags) => {
    setSelectedTags(tags);
    //console.log("Tags saved:", tags);
  };

  const handleDeleteUser = async (userId) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDialogDeleteUserOpen(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className="flex flex-col gap-8 w-full">
            <div className="flex flex-row items-center justify-between mb-8">
              <h1 className="text-4xl font-medium mb-0">Account Information</h1>
              {hasUsers &&
                <StandardButton
                  text="Delete Account"
                  type="error"
                  variant="error"              
                  width="200px"
                  onClick={() => setDialogDeleteUserOpen(true)}
                />
              }
            </div>
            <div className="grid grid-cols-2 gap-x-12 gap-y-6">
              <StandardTextfield
                label="First Name"
                value={firstname}
                onChange={(e) => {
                  handleChange(e, "firstname");
                }}
              />
              <StandardTextfield
                label="Last Name"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />

              <StandardSelect
                label="Position"
                value={selectedPositionId}
                items={positions}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "position");
                }}
              />
              <StandardSelect
                label="Department"
                value={selectedDepartmentId}
                items={departments}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "department");
                }}
              />

              {/* Telefono */}
              <div className="relative w-full">
                <PhoneInput
                  country={"us"} // o "it", se vuoi cambiare default
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Phone"
                  inputStyle={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    borderRadius: "5px",
                    color: "white",
                    width: "100%",
                    fontFamily: "Urbanist, sans-serif",
                    fontWeight: "500",
                    fontSize: "16px",
                    paddingLeft: "50px", // Per lasciare spazio alla bandierina
                    height: "56px",
                  }}
                  buttonStyle={{
                    backgroundColor: "transparent",
                    border: "none",
                    width: "50px", // Uniforme con il padding del testo
                    borderRadius: "5px 0 0 5px",
                  }}
                  dropdownStyle={{
                    backgroundColor: "#333",
                    color: "white",
                  }}
                />
              </div>

              <StandardTextfield 
                label="Email" 
                value={email} 
                onChange={(e) => handleChange(e, "email")} 
                
              />

              {!hasUsers && (
                <StandardTextfield 
                  label="Password" 
                  type="password" 
                  value={password} 
                  onChange={(e) => handleChange(e, "password")}                
                />
              )}

            </div>

            <div className="flex flex-col gap-4">
                {/* Chip per i tag selezionati */}
                <div className="flex flex-wrap gap-2">
                  {selectedTags.map((tag) => (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      sx={{
                        backgroundColor: "rgb(var(--global-color-primary))",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
                <StandardButton
                  text="Edit Tags"
                  onClick={() => setDialogTagsOpen(true)}
                  width="150px"
                />
            </div>

            <div className="flex justify-end mt-8 gap-4">
              <StandardButton
                variant="outlined"
                text="Reset"
                width="200px"
                onClick={handleResetUser}
              />
              <StandardButton
                variant="filled"
                text={buttonLabel}
                width="200px"
                onClick={handleEditUser}
              />
            </div>
          </div>
          
        </div>
      )}

      {/* Dialog per i tag */}
      <DialogTags1
        open={dialogTagsOpen}
        handleClose={() => setDialogTagsOpen(false)}
        onSave={handleSaveTags}
        selectedTags={selectedTags}
      />
      <DeleteAccountUserDialog
        open={dialogDeleteUserOpen}
        handleClose={() => setDialogDeleteUserOpen(false)}
        handleConfirm={handleDeleteUser}
        id={user.id}
        email={user.email}
      />
    </React.Fragment>
  );
}
