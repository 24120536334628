import React, { useState, useContext, useEffect } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import LogoRH from '../assets/images/LogoRH.png';
import bookmarks from '../assets/images/bookmarks.png';
import email from '../assets/images/email.png';
import bell from '../assets/images/bell.png';
//import user_fake from '../assets/images/avatar.png';
import { NotificationContext } from "../index";
import AccountMenu from './accountMenu';
import { UserContext } from './context/UserContext';


function Header({logo}) {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Gestione Notifiche Chat
  const { notifications, setNotifications, badgeVisible, setBadgeVisible } = useContext(NotificationContext);
  const unreadCount = notifications.filter((n) => !n.isRead).length;

  // Aggiorna la visibilità del badge quando cambiano le notifiche
  useEffect(() => {
    setBadgeVisible(unreadCount > 0);
  }, [unreadCount, setBadgeVisible]);

  // Gestione del clic sulla campanella
  const handleBellClick = () => {
    setBadgeVisible(false); // Nascondi il badge
    // Marca tutte le notifiche come lette
    const updatedNotifications = notifications.map((n) => ({ ...n, isRead: true }));
    setNotifications(updatedNotifications);
  };

  // Handle input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle search submission
  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      if (searchQuery.trim()) {
        navigate(`/startups?name=${encodeURIComponent(searchQuery)}`);
      }
    }
  };

  const user = useContext(UserContext);

  const userPermissions = user?.user?.permissions?.map(p => p.name) || [];

  const isRestrictedUser = userPermissions.length !== 0;

  const firstName = user?.user?.first_name || "";
  const lastName = user?.user?.last_name || "";
  
  // Funzione per ottenere le iniziali (es. "Federico Curtoni" → "FC")
  const getInitials = (first, last) => {
    return `${first?.charAt(0) || ""}${last?.charAt(0) || ""}`.toUpperCase();
  };

const initials = getInitials(firstName, lastName);

// Controllo se c'è un'immagine del profilo
const accountImage = logo?.includes("/") ? `https://dev.retailhub.ai/assets/${logo}` : null;


  const hasPermission = (requiredPermission) => userPermissions.includes(requiredPermission);

  // Gestione Startup
  const isStartup = user.user?.roles?.some((role) => role.name === "user_innovation") || false;

  if (location.pathname === '/lander-survey' || location.pathname === '/register') {
    return null;
  }

  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        backgroundColor: '#000',
        padding: 2,
        height:'8vh',
      }}
    >
      <Box sx={{ flexGrow: 1, padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {!isStartup &&(
        <a href="/">
          <img src={LogoRH} alt="logo rh" style={{ width: '50px' }} />
        </a>
        )}
        {isStartup && (
          <img src={LogoRH} alt="logo rh" style={{ width: '50px' }} />
        )}        
      </Box>

      {isStartup && (
        <Box sx={{ flexGrow: 1, padding: 1, alignItems: 'center', justifyContent: 'flex-end', display: { xs: 'none', sm: 'flex' } }}>
          <ul className="nav-links">
            <div style={{ position: 'relative', cursor: 'pointer' }} onClick={handleBellClick}>
              <Link to={localStorage.getItem('innovation') === 'true' ? '/chatsAdmin' : '/chats'}>
                <img src={bell} alt="bell" />
                {badgeVisible && unreadCount > 0 && (
                  <span className="notification-badge">{unreadCount}</span>
                )}
              </Link>
            </div>
          </ul>
        </Box>
      )}

      {!isStartup && isRestrictedUser && (
        <Box sx={{ flexGrow: 2, padding: 1, alignItems: 'center', justifyContent: 'center', display: { xs: 'none', sm: 'flex' }, }}>
          <ul className="nav-links">
            {hasPermission('access.innovations') && (
              <li><a href="/startups">Scout startups</a></li>
            )}
            {/* Mostra il link solo se l'utente ha il permesso */}
            {hasPermission('access.ai_navigator') && (
              <li><a className="btn-link" href="/survey/chat">AI Navigator &#8599;</a></li>
            )}
            {hasPermission('access.advisory_program') && (
              <li><a className="btn-link" href="/advisory">Advisory Program &#8599;</a></li>
            )}
          </ul>
        </Box>
      )}

      {!isStartup && isRestrictedUser && (
      <Box sx={{ flexGrow: 4, padding: 1, alignItems: 'center', display: { xs: 'none', sm: 'flex' }, }}>
        {hasPermission('access.innovations') && (
          <TextField
            variant="outlined"
            placeholder="Search for startups"
            fullWidth
            value={searchQuery}
            onChange={handleInputChange}
            onKeyDown={handleSearchSubmit}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    sx={{ color: '#818181', cursor: 'pointer' }}
                    onClick={handleSearchSubmit}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#818181',
                  fontFamily: 'Urbanist, sans-serif',
                },
                '&:hover fieldset': {
                  borderColor: '#818181',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#818181',
                },
              },
              '& .MuiOutlinedInput-input': {
                color: '#818181',
                fontFamily: 'Urbanist, sans-serif',
              },
              '& .MuiInputLabel-root': {
                color: '#818181',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#818181',
              },
            }}
          />
        )}
      </Box>
      )}

      {!isStartup && isRestrictedUser && (
        <Box sx={{ flexGrow: 1, padding: 1, alignItems: 'center', justifyContent: 'flex-end', display: { xs: 'none', sm: 'flex' } }}>
          <ul className="nav-links">
          {hasPermission('access.innovations') && (
            <a href="/bookmarks-startups">
              <img src={bookmarks} alt="bookmarks" />
            </a>
          )}
          {hasPermission('access.chat') && (
            <>
            <a href="/chats">
              <img src={email} alt="email" />
            </a>          
            <div style={{ position: 'relative', cursor: 'pointer' }} onClick={handleBellClick}>
              <Link to={localStorage.getItem('innovation') === 'true' ? '/chatsAdmin' : '/chats'}>
                <img src={bell} alt="bell" />
                {badgeVisible && unreadCount > 0 && (
                  <span className="notification-badge">{unreadCount}</span>
                )}
              </Link>
            </div>
            </>
          )}
            {/* Inserisci il menu dell'account */}
            <AccountMenu 
              accountImage={accountImage}
              initials={initials} 
              canAccessCompanyMembers={hasPermission('access.company_member')}
            />

          </ul>
        </Box>
      )}

    </Box>
  );
}

export default Header;
