import React from "react";

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SearchIcon from "@mui/icons-material/Search"
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import { useState, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import PromptDialog from "../components/promptDialog";
import DeleteSurveyAnswersDialog from "../components/deleteSurveyAnswersDialog";
import SurveyStartupRankingWidget from "../components/widgets/surveyStartupRanking";
import SurveyQuestionsChartWidget from "../components/widgets/surveyQuestionsChart";
import SurveyPointChartRankingWidget from "../components/widgets/surveyPointChartRanking";
import InviteCollaboratorsDialog from "../components/inviteCollaboratorsDialog";
import { Button } from "@mui/material";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import axios from "axios";
import showdown from 'showdown';
import ReactMarkdown from 'react-markdown';

function formatDateTime(isoString) {
  if(!!isoString) {
    const date = new Date(isoString);
  
    // Format the date part (dd/mm/yyyy)
    const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' gives dd/mm/yyyy format
  
    // Format the time part (HH:mm)
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // 24-hour format
    });
  
    return `${formattedDate} ${formattedTime}`;
  } else {
    return ''
  }
}

export default function Survey() {
  const [surveys, setSurveysList] = useState([]);
  const [selectedSurveyCompletion, setSurveyCompletion] = useState({})
  const [startups, setStartupList] = useState([]);
  const [firstWidget, setFirstWidget] = useState({})
  const [secondWidget, setSecondWidget] = useState({})
  const [thirdWidget, setThirdWidget] = useState({})
  const [secondWidgetInterviewee, setSecondWidgetInterviewee] = useState({})
  const [thirdWidgetInterviewee, setThirdWidgetInterviewee] = useState({})
  const [secondWidgetSurvey, setSecondWidgetSurvey] = useState({})
  const [thirdWidgetSurvey, setThirdWidgetSurvey] = useState({})
  const [fourthWidget, setFourthWidget] = useState([])
  const [selectedStartupCompletion, setSelectedStartupCompletion] = useState({})
  const [loading, setLoading] = useState(true);
  const [completionLoading, setCompletionLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Sent')
  const [searchOpen, setSearchOpen] = useState(false)
  const [aiOpen, setAiOpen] = useState(false)
  const companyId = localStorage.getItem('companyId');
  const [companyMembers, setCompanyMembers] = useState([])
  const [selectedStartup, setSelectedStartup] = useState({})
  const [selectedStartupId, setSelectedStartupId] = useState(0)
  const [selectedSurvey, setSelectedSurvey] = useState({})
  const [selectedSurveyId, setSelectedSurveyId] = useState(0)
  const [responseInterviewees, setResponseInterviewees] = useState([])
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);
  const [widgetsError, setWidgetsError] = useState(null);
  const [firstWidgetLoading, setFirstWidgetLoading] = useState(false);
  const [secondWidgetLoading, setSecondWidgetLoading] = useState(false);
  const [thirdWidgetLoading, setThirdWidgetLoading] = useState(false);
  const [fourthWidgetLoading, setFourthWidgetLoading] = useState(false);
  let surveyId = 0
  let startupId = 0

  const converter = new showdown.Converter();
  const [typingHTML, setTypingHTML] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  // Handle data fetch success for startups
  const handleSurveyIntervieweesSuccess = (data) => {
    if (data && data.data) {
      setCompanyMembers(data.data)
      // console.log(data.data)
    }
    setLoading(false);
  };

  // Start Chat
  const startChat = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/advisory/chat-statistics-start`,
        { innovation_id: innovationId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setChatMessages([]);
    } catch (err) {
      console.error('Errore durante l\'avvio della chat', err);
      setError('Errore durante l\'avvio della chat');
    }
  };

  // Chat Messages

  const sendMessage = async () => {
    if (!messageInput.trim()) return;

    // Aggiungi il messaggio dell'utente alla chat
    setChatMessages(prev => [...prev, { sender: 'You', text: messageInput }]);
    const currentMessage = messageInput;
    setMessageInput('');
    setIsTyping(true);
    setTypingHTML("Typing...");
    setLoadingChat(true);

    try {
        const token = localStorage.getItem('token');
        const payload = { innovation_id: selectedStartup.innovation_id, message: currentMessage };

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/advisory/message?type=statistics&stream=true`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
        let accumulatedText = "";

        while (true) {
            const { value, done } = await reader.read();

            if (done) break;
            if (accumulatedText.includes("[DONE]")) break;

            value.replaceAll("\n\n\n\n", '__DD__\n\n').split("\n\n").forEach((element) => {
                if (element !== "") {
                    const processedElement = element.replaceAll("__DD__", '\n\n');
                    if (processedElement.startsWith("data: ")) {
                        accumulatedText += processedElement.substring(6);
                    } else {
                        accumulatedText += processedElement;
                    }
                }
            });

            // Aggiorna la chat con i chunk ricevuti
            const formattedText = converter.makeHtml(accumulatedText.replaceAll("\n   -", "\n    -"));
            setTypingHTML(formattedText);  // Aggiorna il testo parziale man mano che arriva

            // Scorri la finestra della chat verso il basso ogni volta che arriva un nuovo chunk
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }

        // Elimina il segnaposto [DONE] e aggiorna il messaggio completo
        const finalMessage = accumulatedText.replace(/\[DONE\]/g, '').trim();

        setChatMessages(prev => [
            ...prev,
            { sender: "Server", text: finalMessage }
        ]);

        setTypingHTML(""); 
        setIsTyping(false);

    } catch (error) {
        console.error('Errore durante l\'invio del messaggio', error);
        setError('Errore durante l\'invio del messaggio');
        setIsTyping(false);
    } finally {
        setLoadingChat(false);
    }
  };

  const handleAiOpen = () => {
    setAiOpen(!aiOpen);
    setSearchOpen(false);
    setChatOpen(true);
    startChat(selectedStartup.innovation_id);
  };

  // Handle data fetch error
  const handleSurveyIntervieweesError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };

  // Handle data fetch success for startups
  const handleFirstWidgetFetchSuccess = (data) => {
    if (data) {
      setFirstWidget(data)
    }
    setLoading(false);
  };

  // Handle data fetch error
  const handleFirstWidgetDataFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };
  
  // Handle data fetch success for startups
  const handleStartupListSuccess = (data) => {
    if (data) {
      if(data.length > 0) {
        let filteredStartups = []
        for (let i = 0; i < data.length; i++) {
          if(!filteredStartups.find(fs => fs.innovation_id === data[i].innovation_id)) {
            filteredStartups.push({...data[i], selected: filteredStartups.filter(fs => fs.selected).length === 0 ? true : false})
          }
          if(i === 0) {
            setSelectedStartup(data[0])
            setSelectedStartupId(data[0].innovation_id)
            startupId = data[0].innovation_id
          }
        }
        setStartupList(filteredStartups)
      }
    }
    setLoading(false);
  };

  // Handle data fetch error
  const handleStartupListError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };
  
  // Handle data fetch success for startups
  const handleSurveysListSuccess = (data) => {
    if (data) {
      if(data.length > 0) {
        let filteredSurveys = []
        for (let i = 0; i < data.length; i++) {
          filteredSurveys.push({
            ...data[i], 
            formattedDate: formatDateTime(data[i].created_timestamp), 
            selected: filteredSurveys.filter(fs => fs.selected).length === 0 ? true : false
          })          
          if(i === 0) {
            setSelectedSurvey(data[0])
            setSelectedSurveyId(data[0].id)
            surveyId = data[0].id
          }
        }
        setSurveysList(filteredSurveys)
      }
    }
    setLoading(false);
    fetchData()
  };

  // Handle data fetch error
  const handleDataFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };

  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen)
    setAiOpen(false)
  }
  
  const handleTabSelection = (tab) => {
    setSelectedTab(tab)
    if(tab === "Startup") {
      fetchStartupData()
    } else {
      fetchData()
    }
  }

  const handleSelectionSurvey = async (event, id) => {
    setSelectedSurvey(surveys.find(s => s.id === id))
    setSelectedSurveyId(id)
    surveyId = id
    let newSurveys = []
    for (let i = 0; i < surveys.length; i++) {
      if(surveys[i].selected && surveys[i].id !== id){
        newSurveys.push({...surveys[i], selected: false})
      } else if (surveys[i].id === id ) {
        newSurveys.push({...surveys[i], selected: true})
      } else {
        newSurveys.push(surveys[i])
      }
    }
    setSurveysList(newSurveys)
    await fetchData()
  }
  
  const handleSelectionStartup = async (event, id) => {
    setSelectedStartup(startups.find(s => s.innovation_id === id))
    setSelectedStartupId(id)
    startupId = id
    await startChat(id);
    let newStartups = []
    for (let i = 0; i < startups.length; i++) {
      if(startups[i].selected && startups[i].innovation_id !== id){
        newStartups.push({...startups[i], selected: false})
      } else if (startups[i].innovation_id === id ) {
        newStartups.push({...startups[i], selected: true})
      } else {
        newStartups.push(startups[i])
      }
    }
    setStartupList(newStartups)
    await fetchStartupData()
  }

  const fetchData = async () => {
    setCompletionLoading(true);
    try {
      const token = localStorage.getItem('token');
      const companyId = localStorage.getItem('companyId');

      if (!token) {
        throw new Error('Token not found. Please login.');
      }
  
      const url = `${process.env.REACT_APP_API_URL}/advisory/results/completion`;
      if (surveyId !== 0 || selectedSurveyId !== 0) {
        const response = await axios.get(url, {
          params: { survey_id: surveyId !== 0 ? surveyId : selectedSurveyId },
          headers: { Authorization: `Bearer ${token}` }
        });
  
        // Aggiorna la lista degli intervistati e la survey selezionata
        setResponseInterviewees(response.data.interviewees);
        console.log("Dati aggiornati per selectedStartupCompletion:", response.data);
        setSurveyCompletion(response.data);
      }
  
      // Esegui anche un aggiornamento della lista generale dei survey
      const surveyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/surveys?company_id=${companyId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSurveysList(surveyResponse.data); // Aggiorna la lista delle survey
  
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setCompletionLoading(false);
    }
  };
  
  const fetchStartupData = async () => {
    setFirstWidgetLoading(true);
    setSecondWidgetLoading(true);
    setThirdWidgetLoading(true);
    setFourthWidgetLoading(true);  
    setWidgetsError(null);
  
    try {
      const token = localStorage.getItem('token');
      const companyId = localStorage.getItem('companyId');
      if (!token) {
        throw new Error('Token non trovato. Effettua il login.');
      }
  
      let selectedStartupIds = startupId !== 0 ? [startupId] : [selectedStartupId];

      const url = `${process.env.REACT_APP_API_URL}/advisory/results/by-innovation`;

      const response = await axios.get(url, {
        params: { 
          company_id: companyId,
          innovation_id: startupId !== 0 ? startupId : selectedStartupId
        },
        headers: { Authorization: `Bearer ${token}` }
      });
      setSelectedStartupCompletion(response.data.data)
  
      const firstWidgetData = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
        params: { company_id: companyId, innovations_ids: selectedStartupIds, widget_id: 1 },
        headers: { Authorization: `Bearer ${token}` }
      });
      
  
      const secondWidgetData = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
        params: { company_id: companyId, innovations_ids: selectedStartupIds, widget_id: 2 },
        headers: { Authorization: `Bearer ${token}` }
      });
  
      const thirdWidgetData = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`, {
        params: { company_id: companyId, innovations_ids: selectedStartupIds, widget_id: 3 },
        headers: { Authorization: `Bearer ${token}` }
      });
  
      const fourthWidgetData = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/histograms`, {
        params: { company_id: companyId, innovation_id: startupId !== 0 ? startupId : selectedStartupId },
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setFirstWidget(firstWidgetData.data);
      setSecondWidget(secondWidgetData.data);
      setThirdWidget(thirdWidgetData.data);
      setFourthWidget(fourthWidgetData.data.data);
  
      // Controlla se tutti i widget sono vuoti
      if (
        (!firstWidgetData.data || Object.keys(firstWidgetData.data).length === 0) &&
        (!secondWidgetData.data || Object.keys(secondWidgetData.data).length === 0) &&
        (!thirdWidgetData.data || Object.keys(thirdWidgetData.data).length === 0) &&
        (!fourthWidgetData.data.data || fourthWidgetData.data.data.length === 0)
      ) {
        setWidgetsError("No Startup Available");
      }
      
  
    } catch (err) {
      setWidgetsError("No Startup Available");
    } finally {
      setFirstWidgetLoading(false);
      setSecondWidgetLoading(false);
      setThirdWidgetLoading(false);
      setFourthWidgetLoading(false);
    }
  };
  

  const handleAiClose = () => {
    setAiOpen(false);
    setChatOpen(false);
    setChatMessages([]); // Resetta i messaggi della chat
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-full">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full">
          
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Survey</h1>
            <Button 
              href="/survey/chat" 
              sx={{
                fontFamily: 'Urbanist, sans-serif',
                width: "300px", 
                backgroundColor: 'rgb(var(--global-color-primary))',
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'none'
              }} 
            >Start new survey</Button>
          </div>
          <div className="flex gap-4 items-center">
            <NotificationsActiveIcon sx={{color: "rgb(var(--global-color-primary))"}}/>
            <span>Advisory Program allows to compare a series of startups that match your search in order to carry out a complete evaluation and generate  reports</span>
          </div>
          <div className="flex gap-2">
            <div 
              className={`flex cursor-pointer ${selectedTab === 'Sent' ? 'text-[rgb(var(--global-color-primary))] border-b border-solid border-[rgb(var(--global-color-primary))]' : ""}`}
              onClick={() => handleTabSelection('Sent')}
            >
              Sent
            </div>
            <div 
              className={`flex cursor-pointer ${selectedTab === 'Startup' ? 'text-[rgb(var(--global-color-primary))] border-b border-solid border-[rgb(var(--global-color-primary))]' : ""}`}
              onClick={() => handleTabSelection('Startup')}
            >
              Startup
            </div>
          </div>
          <div className="flex gap-5 w-full max-w-full h-full max-h-[700px]">
            <div className="flex gap-5 w-full max-w-full h-full max-h-full">
              <div className={`h-full flex flex-col items-center bg-black p-5 rounded-2xl ${searchOpen ? 'w-[400px] justify-start' : 'w-fit justify-center'}`}>
                {
                  !searchOpen &&
                  <SearchIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}}/>
                }
                {
                  searchOpen &&
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex justify-end w-full">
                      <CloseIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}} />
                    </div>
                    <div className="flex flex-col gap-2 w-full h-full overflow-y-auto max-h-[620px]">
                      {
                        loading &&
                        <span className="text-sm">Loading Surveys...</span>
                      }
                      {
                        !loading &&
                        selectedTab === 'Startup' &&
                        startups.map(s =>
                          <div 
                            key={s.id} 
                            onClick={(e) => {handleSelectionStartup(e, s.innovation_id)}}
                            className={`p-4 flex flex-col gap-1 w-full rounded-2xl font-semibold cursor-pointer ${ s.selected ? "bg-[rgb(var(--global-color-primary))] text-black" : "bg-black hover:bg-[rgb(255,255,255,.1)]" }`}
                          >
                            <span className="text-sm">{s.name}</span>
                          </div>
                        )
                      }
                      {
                        !loading &&
                        selectedTab === 'Sent' &&
                        surveys.length > 0 ?
                        surveys.map(s =>
                          <div 
                            key={s.id} 
                            onClick={(e) => {handleSelectionSurvey(e, s.id)}}
                            className={`p-4 flex flex-col gap-1 w-full rounded-2xl font-semibold cursor-pointer ${ s.selected ? "bg-[rgb(var(--global-color-primary))] text-black" : "bg-black hover:bg-[rgb(255,255,255,.1)]" }`}
                          >
                            <span className="text-sm">Survey {s.formattedDate}</span>
                            <div className="flex gap-1">
                              {
                                s.innovations.map(i =>
                                  <span className="text-xs" key={i.id}>{i.name}, </span>
                                )
                              }
                            </div>
                          </div>
                        )
                        : <span></span>
                      }
                    </div>
                  </div>
                }
              </div>
              <div 
                className={`flex flex-col gap-5 w-full 
                  ${
                    selectedTab === "Startup" && (aiOpen || searchOpen) ? 
                    "max-w-[calc(100%-504px)]" : 
                    selectedTab === 'Sent' && searchOpen ?
                    "max-w-[calc(100%-440px)]" :
                    selectedTab === "Startup" && (!aiOpen || !searchOpen) ? 
                    "max-w-[calc(100%-168px)]" : 
                    "max-w-[calc(100%-104px)]"
                  } 
                  h-full max-h-full`
                }
              >
                {selectedTab === "Startup" && !widgetsError && (                  
                    <div className="flex flex-wrap justify-between bg-black p-5 rounded-2xl w-full max-w-full">
                      <div className="flex gap-5 items-center">
                        <img 
                          src={`${process.env.REACT_APP_BASE_URL}/assets/${selectedStartup.logo_url || ""}`} 
                          alt="logo" 
                          className="w-20 h-20 object-cover rounded-2xl" 
                        />
                        <div className="flex flex-col gap-1">
                          <h2 className="text-3xl">{selectedStartup.name}</h2>
                          <span>Direct payments, pay after delivery options and installment plans provider.</span>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="flex flex-col justify-center items-center gap-1">
                          <span className="text-sm">Nr. Campaigns</span>
                          <span className="text-sm">{selectedStartupCompletion.total_campaigns}</span>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-1">
                          <span className="text-sm">Surveys Completed</span>
                          <span className="text-sm">
                            {selectedStartupCompletion.total_completed_surveys} / {selectedStartupCompletion.total_sent_surveys}
                          </span>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-1">
                          <span className="text-sm">Status</span>
                          <span className="text-sm">{selectedStartupCompletion.status}</span>
                        </div>
                      </div>
                    </div>
                  )
                }

                {selectedTab === "Startup" && (
                  <div className="flex flex-col gap-5 items-center h-full max-h-full max-w-full overflow-hidden overflow-y-auto  bg-black p-5 rounded-2xl">
                    {widgetsError ? (
                      <span>{widgetsError}</span>
                    ) : (
                      <>
                        {firstWidgetLoading ? (
                          <span>Loading widget data...</span>
                        ) : (
                          <SurveyStartupRankingWidget 
                            sliderValues={firstWidget.data} 
                            labels={firstWidget.projections?.sort((a, b) => a.projection_order - b.projection_order)
                              .map(p => p.projection_name) || []} 
                          />
                        )}

                        {secondWidgetLoading || thirdWidgetLoading ? (
                          <span>Loading widget data...</span>
                        ) : (
                          <SurveyPointChartRankingWidget 
                            labels={[
                              ...(secondWidget.projections || []).map(sw => sw.projection_name),
                              ...(thirdWidget.projections || []).map(tw => tw.projection_name)
                            ]}
                            data={[...(secondWidget.data || []), ...(thirdWidget.data || [])]}
                            surveyData={[...(secondWidgetSurvey.data || []), ...(thirdWidgetSurvey.data || [])]}
                            intervieweesData={[...(secondWidgetInterviewee.data || []), ...(thirdWidgetInterviewee.data || [])]}
                            startupName={selectedStartup.name || ""}
                          />
                        )}

                        {fourthWidgetLoading ? (
                          <span>Loading widget data...</span>
                        ) : (
                          <SurveyQuestionsChartWidget data={fourthWidget} />
                        )}
                      </>
                    )}
                  </div>
                )}
                {
                  !completionLoading &&
                  selectedTab === 'Sent' &&
                  <div className="flex flex-col gap-10 justify-between w-full max-w-full h-full max-h-full overflow-hidden overflow-y-auto bg-black p-5 rounded-2xl">
                    {
                      surveys.length > 0 ? (
                        <>
                          <div className="flex flex-col items-center gap-10 w-full">
                            <div className="flex flex-col items-center gap-2 w-full">
                              <h2 className="text-3xl">Completion rate</h2>
                              <span className="text-7xl font-bold">{selectedSurveyCompletion.completed_surveys} / {selectedSurveyCompletion.total_surveys}</span>
                            </div>
                            {
                              !!selectedSurveyCompletion.interviewees &&
                              selectedSurveyCompletion.interviewees.filter(i => !i.completed_survey).length > 0 &&
                              <div className="flex flex-col gap-2 w-full">
                                <span className="font-semibold text-lg">Wainting answers from:</span>
                                <div className="flex flex-col gap-1 w-full max-h-[250px] overflow-hidden overflow-y-auto">
                                  {
                                    selectedSurveyCompletion.interviewees.filter(i => !i.completed_survey).map(na =>
                                      <div key={na.interviewee_id} className="flex items-center justify-between w-full py-1 border-b border-[rgb(255,255,255,.3)]">
                                        <span>{na.first_name} {na.last_name}</span>
                                        <PromptDialog 
                                          name={`${na.first_name} ${na.last_name}`} 
                                          interviewee_survey_id={na.interviewee_survey_id} 
                                          startups={surveys.find(s => Number(s.id) === Number(selectedSurveyId))?.innovations}
                                          timestamp={formatDateTime(surveys.find(s => Number(s.id) === Number(selectedSurveyId))?.created_timestamp)}
                                        />
                                      </div>
                                    )
                                  }                     
                                </div>
                              </div>
                            }
                          </div>
                          <div className="flex items-center justify-center gap-5 w-full">
                            <DeleteSurveyAnswersDialog interviewees={selectedSurveyCompletion.interviewees.filter(i => i.completed_survey)} surveyId={selectedSurvey.id} onSurveyUpdate={fetchData}/>
                            <InviteCollaboratorsDialog 
                              users={responseInterviewees.length === 0 
                                ? companyMembers 
                                : companyMembers.filter(i => !responseInterviewees.find(ri => ri.interviewee_id === i.id))
                              }
                              survey_id={selectedSurvey.id} 
                              startups={surveys.find(s => Number(s.id) === Number(selectedSurveyId))?.innovations}
                            />
                          </div>
                          {
                            !!selectedSurveyCompletion.interviewees &&
                            selectedSurveyCompletion.interviewees.filter(i => i.completed_survey).length > 0 &&
                            <div className="flex flex-col gap-2 w-full">
                              <span className="font-semibold text-lg">Collected answers from:</span>
                              <div className="flex flex-col gap-1 w-full max-h-[250px] overflow-hidden overflow-y-auto">
                                {
                                  selectedSurveyCompletion.interviewees.filter(i => i.completed_survey).map(na =>
                                    <div key={na.interviewee_id} className="flex items-center justify-between w-full py-1 border-b border-[rgb(255,255,255,.3)]">
                                      <span>{na.first_name} {na.last_name}</span>
                                    </div>
                                  )
                                }                     
                              </div>
                            </div>
                          }
                        </>
                      ) : (<span>No surveys founded</span>)
                    }
                  </div>
                }
                {
                  completionLoading &&
                  selectedTab === "Sent" &&
                  <div className="flex justify-center items-center w-full max-w-full h-full max-h-full bg-black p-5 rounded-2xl">
                    <span className="text-sm">Loading survey...</span>
                  </div>
                }
              </div>
              {
                selectedTab === 'Startup' &&
                <div className={`h-full flex items-center bg-black p-5 rounded-2xl ${aiOpen ? 'w-[400px]' : 'w-fit'}`}>
                  {
                    !aiOpen &&
                    <FaceRetouchingNaturalIcon onClick={handleAiOpen} sx={{cursor: 'pointer'}}/>
                  }
                  {
                    aiOpen &&
                    <div className="flex flex-col gap-2 w-full h-full">
                      <div className="flex justify-end w-full">
                        <CloseIcon onClick={handleAiClose} sx={{cursor: 'pointer'}} />
                      </div>
                      
                      {/* Chat Messages Area */}

                        <div className="flex flex-col flex-grow overflow-y-auto px-5">
                            {chatMessages.map((m, index) => (
                                <div
                                    key={`${m.sender}-${index}`}
                                    className={`flex flex-col gap-1 ${m.sender === "You" ? "items-end" : "items-start"}`}
                                >
                                    <span className="font-semibold">{m.sender}</span>
                                    <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap msgClass">
                                        <ReactMarkdown>{m.text}</ReactMarkdown>
                                    </div>
                                </div>
                            ))}

                            {/* Typing Indicator */}
                            {isTyping && typingHTML && (
                                <div className="flex flex-col gap-1 items-start mt-4">
                                    <span className="font-semibold">Server</span>
                                    <div
                                        className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col msgClass"
                                        dangerouslySetInnerHTML={{ __html: typingHTML }}
                                    />
                                </div>
                            )}

                          {/* Scroll Anchor */}
                          <div ref={messagesEndRef} />
                          
                        </div>
                      {/* Input per i messaggi */}
                      <div className="flex items-center p-2">
                        <input
                          type="text"
                          className="flex-grow p-2 bg-gray-700 rounded-l-2xl focus:outline-none"
                          value={messageInput}
                          onChange={(e) => setMessageInput(e.target.value)}
                          onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                          placeholder="Write a messagge..."
                        />
                        <button
                          className="p-2 bg-blue-600 rounded-r-2xl"
                          onClick={sendMessage}
                          disabled={loadingChat}
                          style={{ backgroundColor: '#C5FF55', color: '#000' }}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <FlexibleDataFetcher
        endpoint="/advisory/surveys"
        params={{ company_id: companyId }}
        onSuccess={handleSurveysListSuccess}
        onError={handleDataFetchError}
      />
      <FlexibleDataFetcher
        endpoint="/advisory/results/answers-count-by-innovation"
        params={{ company_id: Number(companyId) }}
        onSuccess={handleStartupListSuccess}
        onError={handleStartupListError}
      />
      <FlexibleDataFetcher
        endpoint="/advisory/results/by-absolute"
        params={{ 
          company_id: Number(companyId),
          innovations_ids: startups.length > 0 ? [startups[0].innovation_id] : [],
          widget_id: 1 
        }}
        onSuccess={handleFirstWidgetFetchSuccess}
        onError={handleFirstWidgetDataFetchError}
      />
      <FlexibleDataFetcher
        endpoint="/advisory/interviewees"
        params={{ 
          company_id: companyId
        }}
        onSuccess={handleSurveyIntervieweesSuccess}
        onError={handleSurveyIntervieweesError}
      />
    </div>
  )
}
