import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Grid, Tooltip, Chip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit'; 
import CompanyInformationDialog from "../components/adminStartupDetails/dialogs/companyInformation";
import DescriptionCompany from "../components/adminStartupDetails/dialogs/descriptionCompany";
import DialogTags1 from "../components/adminStartupDetails/dialogs/dialogTags1Front";
import DialogTags2 from "../components/adminStartupDetails/dialogs/dialogTags2Front";

const AddStartupAdmin = () => {
    const [formData, setFormData] = useState({
      country_id: "",
      city: "",
      address: "",
      accountType: "",
      name: "",
      founders: "",
      phone: "",
      email: "",
      linkedin_url: "",
      website: "",
      founding_year: "",
      total_funding: "",
      companyStatus: "",
      company_type_id: "",
      company_stage_id: "",
      business_model_id: "",
      otherCountries: [],
      integrationTiming: "",
      numberOfEmployees: "",
      revenue: "",
      platforms: "",
      clients: "",
    });
  
    const [errors, setErrors] = useState({});
    const [dropdownValues, setDropdownValues] = useState({
      countries: [],
      companyTypes: [],
      companyStages: [],
      businessModels: [],
      targetMarkets: [],
    });

    const [startupData, setStartupData] = useState(null);

    // Stato per settori e tag selezionati
    const [selectedSectors, setSelectedSectors] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    // Funzione per aggiornare i settori selezionati
    const handleSaveSectors = (sectors) => {
      setSelectedSectors(sectors);
    };

    // Funzione per aggiornare i tag selezionati
    const handleSaveTags = (tags) => {
      setSelectedTags(tags);
    };

  
    // Fetch dropdown values including target markets
    useEffect(() => {
      const fetchDropdownValues = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/values/innovation-filters?admin=false`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          
          setDropdownValues((prev) => ({
            ...prev,
            countries: data.data.countries || [],
            companyTypes: data.data.company_types || [],
            companyStages: data.data.stages || [],
            businessModels: data.data.business_models || [],
            targetMarkets: data.data.target_markets || [],          
          }));        
        } catch (error) {
          console.error("Error fetching dropdown values:", error);
        }
      };
  
      fetchDropdownValues();
    }, []);
      
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
    
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    
      if (errors[name]) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    };
  
    const requiredFields = [
      "name",
      "website",
      "linkedin_url",
      "country_id",
      "founding_year",
      "company_type_id",
      "business_model_id",
      "company_stage_id",
      "otherCountries",
      "founders",
    ];
  
    const handleUpdate = async () => {
      const token = localStorage.getItem("token");
  
      let newErrors = {};
      requiredFields.forEach((field) => {
        if (!formData[field] || (typeof formData[field] === "string" && formData[field].trim() === "")) {
          newErrors[field] = "This field is required";
        }
      });
  
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
  
      // Creazione del payload senza campi vuoti
      let payload = {};
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== null && formData[key] !== undefined && formData[key] !== "") {
          payload[key] = formData[key];
        }
      });
  
      // Aggiunta dei `;` nei campi richiesti
      if (formData.founders) payload.founders = `;${formData.founders};`;
      if (formData.phone) payload.phone = `;${formData.phone};`;
      if (formData.email) payload.emails = `;${formData.email};`;
      if (formData.platforms) payload.platforms = `;${formData.platforms};`;
      if (formData.clients) payload.clients = `;${formData.clients};`;
  
      // Gestione corretta di `target_markets`, `tags` e `categories`
      if (formData.otherCountries.length > 0) {
        payload.target_markets = formData.otherCountries.map(Number);
      }
    
      delete payload.otherCountries;
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/innovations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          alert("Data updated successfully!");
        } else {
          alert("Failed to update data.");
        }
      } catch (error) {
        console.error("Error updating data:", error);
        alert("An error occurred.");
      }
    };

    const [openDialog, setOpenDialog] = useState(null);

    const handleOpenDialog = (dialogName) => setOpenDialog(dialogName);
    const handleCloseDialog = () => {
      setOpenDialog(null);      
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem("token");
      
        if (!token) {
          alert("Authentication required!");
          return;
        }
      
        let payload = {};
      
        // Mappiamo solo i campi con valori non vuoti
        Object.keys(formData).forEach((key) => {
          if (formData[key] !== null && formData[key] !== undefined && formData[key] !== "") {
            payload[key] = formData[key];
          }
        });
      
        // Formattiamo alcuni campi specifici con `;` quando richiesto
        if (formData.founders) payload.founders = `;${formData.founders};`;
        if (formData.phone) payload.phone = `;${formData.phone};`;
        if (formData.email) payload.emails = `;${formData.email};`;
        if (formData.platforms) payload.platforms = `;${formData.platforms};`;
        if (formData.clients) payload.clients = `;${formData.clients};`;
      
        // Convertiamo gli array in ID
        if (formData.otherCountries.length > 0) {
          payload.target_markets = formData.otherCountries.map(Number);
        }
        
        if (selectedTags.length > 0) {
          payload.tags = selectedTags.map(tag => tag.id);
        }
      
        if (selectedSectors.length > 0) {
          payload.categories = selectedSectors.map(sector => sector.id);
        }
      
        delete payload.otherCountries;
      
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/innovations`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          });
      
          if (response.ok) {
            alert("Startup successfully created!");
          } else {
            const errorData = await response.json();
            console.error("Error:", errorData);
            alert("Failed to create startup.");
          }
        } catch (error) {
          console.error("Request failed:", error);
          alert("An error occurred while submitting.");
        }
      };
      
      
    
    return (
        <div className="m-5 p-5 flex flex-col h-full bg-[#191919] rounded-2xl">
            <h1 className="text-6xl font-medium mb-10">Add Startup</h1>

          <Grid container spacing={3}>
    
            {/* Company Name (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label="Company Name"
                fullWidth
                variant="outlined"
                value={formData.name}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.name)}
                helperText={errors.name || ""}
              />
            </Grid>
    
            {/* Website (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="website"
                label="Website"
                fullWidth
                variant="outlined"
                value={formData.website}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.website)}
                helperText={errors.website || ""}
              />
            </Grid>
    
            {/* linkedin_url URL (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="linkedin_url"
                label="linkedin_url URL"
                fullWidth
                variant="outlined"
                value={formData.linkedin_url}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.linkedin_url)}
                helperText={errors.linkedin_url || ""}
              />
            </Grid>
    
            {/* country_id (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="country_id"
                label="country_id"
                select
                fullWidth
                variant="outlined"
                value={formData.country_id}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.country_id)}
                helperText={errors.country_id || ""}
              >
                {dropdownValues.countries.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
    
            {/* Founding Year (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="founding_year"
                label="Founding Year"
                fullWidth
                variant="outlined"
                value={formData.founding_year}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.founding_year)}
                helperText={errors.founding_year || ""}
              />
            </Grid>
    
            {/* Company Type (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="company_type_id"
                label="Company Type"
                select
                fullWidth
                variant="outlined"
                value={formData.company_type_id}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.company_type_id)}
                helperText={errors.company_type_id || ""}
              >
                {dropdownValues.companyTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
    
            {/* Business Model (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="business_model_id"
                label="Business Model"
                select
                fullWidth
                variant="outlined"
                value={formData.business_model_id}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.business_model_id)}
                helperText={errors.business_model_id || ""}
              >
                {dropdownValues.businessModels.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
    
            {/* Company Stage (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="company_stage_id"
                label="Company Stage"
                select
                fullWidth
                variant="outlined"
                value={formData.company_stage_id}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.company_stage_id)}
                helperText={errors.company_stage_id || ""}
              >
                {dropdownValues.companyStages.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
    
            {/* Target Markets (Required) */}
            <Grid item xs={12} md={6}>
            <TextField
                name="otherCountries"
                label="Target Markets"
                select
                fullWidth
                variant="outlined"
                value={formData.otherCountries}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.otherCountries)}
                helperText={errors.otherCountries || ""}
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => {
                    // --- Ecco dove aggiungi il console.log
                    console.log("Selected IDs:", selected);
              
                    const selectedItems = dropdownValues.targetMarkets
                      .filter((item) => selected.includes(item.id));
              
                    console.log("Selected Items after filter:", selectedItems);
                    
                    return selectedItems.map((item) => item.name).join(", ");
                  },
                }}
              >
                {dropdownValues.targetMarkets.map((item) => (                
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
  
            </Grid>
    
            {/* Founders (Required) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="founders"
                label="Founders"
                fullWidth
                variant="outlined"
                value={formData.founders}
                onChange={handleInputChange}
                sx={textFieldStyle}
                required
                error={Boolean(errors.founders)}
                helperText={errors.founders || ""}
              />
            </Grid>
    
            {/* City (Opzionale) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="city"
                label="City"
                fullWidth
                variant="outlined"
                value={formData.city}
                onChange={handleInputChange}
                sx={textFieldStyle}
              />
            </Grid>
    
            {/* Phone (Opzionale) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="phone"
                label="Phone"
                fullWidth
                variant="outlined"
                value={formData.phone}
                onChange={handleInputChange}
                sx={textFieldStyle}
              />
            </Grid>
    
            {/* Email (Opzionale) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Email"
                fullWidth
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange}
                sx={textFieldStyle}
              />
            </Grid>          
    
            {/* Revenue (Opzionale) */}
            <Grid item xs={12} md={6}>
              <TextField
                name="revenue"
                label="Revenue"
                fullWidth
                variant="outlined"
                value={formData.revenue}
                onChange={handleInputChange}
                sx={textFieldStyle}
              />            
            </Grid>
  
            {/* Revenue (Opzionale) */}
            <Grid item xs={12} md={6}>
            <TextField
                name="clients"
                label="Clients"
                fullWidth
                variant="outlined"
                value={formData.clients}
                onChange={handleInputChange}
                sx={textFieldStyle}
              />           
            </Grid>
    
          </Grid>

          {/* Sectors */}

          <div className="flex flex-col gap-4 my-5">
              <div className="flex flex-row items-center gap-2 ">
                  <h2 className="text-4xl font-semibold m-0">Sectors of Competence</h2>
                  <EditIcon
                      sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                      onClick={() => handleOpenDialog("dialog3")}
                  />
              </div>
              <div className="flex flex-row items-center gap-2 ">
              {selectedSectors.length > 0 ? (
                  selectedSectors.map((category) => (
                    <Chip
                      key={category.id}
                      label={category.name}
                      sx={{
                        backgroundColor: "rgb(var(--global-color-primary))",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    />
                  ))
                ) : (
                  <span>Select some sectors</span> // Mostra un testo alternativo se non ci sono settori
                )}
            </div>
          </div>

          {/* Tags */}

          <div className="flex flex-col gap-4 my-5">
            <div className="flex flex-row items-center gap-2">
              <h2 className="text-4xl font-semibold m-0">Tags</h2>
              <EditIcon
                sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                onClick={() => handleOpenDialog("dialog4")}
              />
            </div>

            {/* Chip per i tag selezionati */}
            <div className="flex flex-wrap gap-2">
            {selectedTags.length > 0 ? (
              selectedTags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  sx={{
                    backgroundColor: "rgb(var(--global-color-primary))",
                    color: "#000",
                    fontWeight: "bold",
                  }}
                />
              ))
            ) : (
              <span>Select some tags</span>
            )}
            </div>
          </div>

          <DialogTags1 onSave={handleSaveSectors} open={openDialog === "dialog3"} handleClose={handleCloseDialog} />
          <DialogTags2 onSave={handleSaveTags} open={openDialog === "dialog4"} handleClose={handleCloseDialog} />

          <Button
  variant="contained"
  onClick={handleSubmit}
  sx={{
    backgroundColor: "rgb(var(--global-color-primary))",
    color: "#000",
    fontWeight: "bold",
    "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
    marginTop: "20px",
    alignSelf: "center",
  }}
>
  Submit Startup
</Button>

        </div>
    );
    
    
  };
  
  const textFieldStyle = {
    marginBottom: "16px",
    "& .MuiInputBase-root": { color: "white" },
  };
  
  export default AddStartupAdmin;
