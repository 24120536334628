import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from './context/UserContext';

const ProtectedRoute = ({ children, allowedRoles = [], requiredPermissions = [], publicPage = false, innovationId = null }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();

  const user = useContext(UserContext);
  const roleName = user?.user?.roles?.length > 0 ? user.user.roles[0].name : ""; // Recupera il ruolo
  const userPermissions = user?.user?.permissions || [];  // Recupera i permessi dell'utente

  //console.log("roleName", roleName)

  // Verifica se l'utente ha almeno un permesso
  const hasAnyPermission = userPermissions.length > 0;

  // Funzione per verificare se l'utente ha tutti i permessi richiesti
  const hasRequiredPermissions = requiredPermissions.every(permission =>
    userPermissions.some(userPermission => userPermission.name === permission)
  );

  if (publicPage && token) {
    return <Navigate to="/" />;
  }

  if (!token && !publicPage) {
    return <Navigate to="/auth" />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(roleName)) {
    return <Navigate to="/" />;
  }

  // Controlla se l'utente ha i permessi necessari per accedere alla pagina
  if (requiredPermissions.length > 0 && !hasRequiredPermissions) {
    return <Navigate to="/" />;
  }

  // Se l'utente con ruolo 'user_innovation' cerca di accedere a una pagina non consentita
  if (
    roleName === "user_innovation" &&
    location.pathname.startsWith("/startupedit") &&
    innovationId &&
    location.pathname !== `/startupedit/${innovationId}`
  ) {
    return <Navigate to={`/startupedit/${innovationId}`} replace />;
  }

  return children;
};

export default ProtectedRoute;
