import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemButton, IconButton, Box, Typography, Button, Collapse, Snackbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import axios from 'axios';

import dashboard from '../assets/icons/dashboard.png';
import scout_startups from '../assets/icons/scout_startups.png';
import saved from '../assets/icons/saved.png';
import chats from '../assets/icons/chats.png';
import ai_navigator from '../assets/icons/ai_navigator.png';
import advisory_program from '../assets/icons/advisory_program.png';
import survey_img from '../assets/icons/survey_img.png';
import account from '../assets/icons/account.png';

import StandardButton from './standardButton';

// IMPORTA il context dove hai user
import { UserContext } from './context/UserContext';

function Sidebar({isStartupIncomplete}) {

  // stato che dice se stiamo mostrando l'area Admin (solo se l'utente è admin)
  const [isAdminUser, setAdminUser] = useState(false);

  // 2) Mantieni il resto del tuo stato invariato
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sectors, setSectors] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);

  // 3) Leggi il tuo user dal context
  const { user, setUser, setLogo } = useContext(UserContext);

  // 4) Definisci isStartup e isAdmin
  const isStartup = user?.roles?.some((role) => role.name === 'user_innovation') || false;
  const isAdmin = user?.roles?.some((role) => role.name === "admin") || false;

  // Ottieni l'elenco dei permessi dell'utente (array di nomi dei permessi)
  const userPermissions = user?.permissions?.map(p => p.name) || [];

  const isRestrictedUser = userPermissions.length !== 0;

  //console.log("isRestrictedUser", isRestrictedUser)

  // Funzione per controllare se l'utente ha il permesso richiesto
  const hasPermission = (requiredPermission) => userPermissions.includes(requiredPermission);

  const menuItems = [
    { label: 'Home', icon: dashboard, path: '/', permission: 'access.innovations' },
    { label: 'Startups', icon: scout_startups, path: '/startups', permission: 'access.innovations' },
    { label: 'Saved', icon: saved, path: '/bookmarks-startups', permission: 'access.innovations' },
    { label: 'Chat', icon: chats, path: '/chats', permission: 'access.chat' },
    { label: 'AI Navigator', icon: ai_navigator, path: '/survey/chat', permission: 'access.ai_navigator' },
    { label: 'Advisory Program', icon: advisory_program, path: '/advisory', permission: 'access.advisory_program' },
    { label: 'Survey', icon: survey_img, path: '/survey', permission: 'access.advisory_program' },
  ];

  const handleDisabledClick = (event) => {
    event.preventDefault();
    setShowUpgradeMessage(true);
  };


  const [openSnackbar, setOpenSnackbar] = useState(false);

  // 6) Recupera l'eventuale id di startup
  const startupId = user?.innovations?.[0]?.id;

  // Al mount, controlliamo se abbiamo salvato in localStorage la preferenza di visualizzazione
  useEffect(() => {
    if (isAdmin) {
      const storedAdmin = localStorage.getItem('isAdminUser');
      if (storedAdmin !== null) {
        // se c'è un valore, lo trasformiamo in boolean e lo impostiamo
        setAdminUser(JSON.parse(storedAdmin));
      } else {
        // se non c'è un valore in localStorage, puoi decidere se di default 
        // l'admin parte dal menù admin (true) o user (false)
        setAdminUser(true);
      }
    } else {
      // se l'utente non è admin, non può togglare: forziamo a false
      setAdminUser(false);
    }
  }, [isAdmin]);

  // 7) Funzione di logout invariata
  const logout = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Pulizia localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('companyId');
      localStorage.removeItem('admin');
      localStorage.removeItem('isAdminUser');
      localStorage.removeItem('roleName');
      localStorage.removeItem('innovation');
      localStorage.removeItem('adminPermissions');
      localStorage.removeItem('account_completed');
      // Resetta user
      setUser(null);
      setLogo(null);
      navigate('/auth');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // 8) toggleUser: switcha l'area e salva in localStorage
  const toggleUser = () => {
    if (!isAdmin) return; // se non è admin, non fa niente
    setAdminUser(prev => {
      const newValue = !prev;
      localStorage.setItem('isAdminUser', JSON.stringify(newValue));
      return newValue;
    });
  };

  // 9) Recupero settori invariato
  const fetchSectors = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/taxonomies?type=category`);
      setSectors(response.data.data);
    } catch (error) {
      console.error('Errore nel recupero delle categorie:', error);
    }
  };

  useEffect(() => {
    fetchSectors();
  }, []);

  // 10) Logica di "nascondi sidebar" invariata
  if (location.pathname === '/lander-survey' || location.pathname === '/register') {
    return null;
  }

  const toggleDrawer = (isOpen) => () => {
    setMobileOpen(isOpen);
  };

  // 11) Resta tutto immutato nelle sezioni che usano isAdminUser / isStartup
  const drawer = (
    <Box sx={{ height: '100%', backgroundColor: '#191919' }}>

      {/* Se l'admin è in modalità "Standard" e NON è una startup */}
      {!isAdminUser && !isStartup && isRestrictedUser && (
        <Box>
          <Typography variant="h6" sx={{ paddingTop: '25px', paddingBottom: '5px', paddingLeft: '25px', color: 'white', fontFamily: 'Urbanist, sans-serif' }}>
            Home
          </Typography>
          
          <List className={'SidebarMenuList'} sx={{ paddingLeft: '25px' }}>          

            {menuItems.map((item) => {
              const isAllowed = hasPermission(item.permission);

              return (
                <ListItemButton
                  key={item.path}
                  component={isAllowed ? Link : 'div'}
                  to={isAllowed ? item.path : '#'}
                  onClick={isAllowed ? undefined : handleDisabledClick}
                  sx={{
                    opacity: isAllowed ? 1 : 0.5,
                    cursor: isAllowed ? 'pointer' : 'not-allowed',
                  }}
                >
                  <Box
                    component="img"
                    src={item.icon}
                    alt={item.label}
                    sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }}
                  />
                  <ListItemText primary={item.label} />
                </ListItemButton>
              );
            })}

          </List>
          
          {isRestrictedUser && (
            <>
          <Typography variant="h6" sx={{ paddingTop: '25px', paddingBottom: '5px', paddingLeft: '25px', color: 'white', fontFamily: 'Urbanist, sans-serif' }}>
            Sectors
          </Typography>

          <Box
            sx={{
              maxHeight: '200px',
              overflowY: 'auto',
              paddingLeft: '25px',
              pr: 2,
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#C5FF55',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#2B2C2D',
              },
            }}
          >
            <List className={'SidebarMenuList'}>
              {sectors.map((sector) => (
                <ListItemButton
                  component={Link}
                  to={`/startups?categories%5B%5D=${sector.id}&name%5B%5D=${sector.name}`}
                  key={sector.id}
                >
                  <Box
                    component="img"
                    src={sector.logo || dashboard}
                    alt={sector.name}
                    sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }}
                  />
                  <ListItemText primary={sector.name} />
                </ListItemButton>
              ))}
            </List>
          </Box>
          </>
          )}
          

          
          <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', gap: '20px' }}>

            {isRestrictedUser && (
              <Button
                component={Link}
                to={'/startups'}
                variant="contained"
                fullWidth
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Urbanist, sans-serif',
                  backgroundColor: '#C5FF55',
                  color: '#191919',
                  fontWeight: 700,
                  textTransform: 'capitalize',
                }}
              >
                Advanced Search &rarr;
              </Button>
            )}

            <StandardButton text="Logout" onClick={logout} />
            {isAdmin && (
              <StandardButton 
                text={isAdminUser ? 'Standard Area' : 'Admin Area'} 
                onClick={toggleUser} 
              />
            )}
          </Box>
        </Box>
      )}

      {/* Se siamo in modalità Admin */}
      {isAdminUser && (
        <Box sx={{ height: '100%' }}>
          <List className={'SidebarMenuList'} sx={{ paddingLeft: '25px', height: '100%' }}>
            <Typography
              sx={{
                paddingTop: '12px',
                paddingBottom: '5px',
                paddingLeft: '12px',
                color: 'white',
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              Dashboard
            </Typography>
            <ListItemButton component={Link} to="/dashboard">
              <Box component="img" src={dashboard} alt="Dashboard" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Dashboard" />
            </ListItemButton>

            <Typography
              sx={{
                paddingTop: '12px',
                paddingBottom: '5px',
                paddingLeft: '12px',
                color: 'white',
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              Users
            </Typography>
            <ListItemButton component={Link} to="/userList">
              <Box component="img" src={account} alt="User List" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="User List" />
            </ListItemButton>

            <Typography
              sx={{
                paddingTop: '12px',
                paddingBottom: '5px',
                paddingLeft: '12px',
                color: 'white',
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              Startups
            </Typography>
            <ListItemButton component={Link} to="/startupList">
              <Box component="img" src={scout_startups} alt="Startup list" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Startup list" />
            </ListItemButton>

            <Typography
              sx={{
                paddingTop: '12px',
                paddingBottom: '5px',
                paddingLeft: '12px',
                color: 'white',
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              Analysis
            </Typography>
            <ListItemButton component={Link} to="/analysis">
              <Box component="img" src={survey_img} alt="Analysis" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Analysis" />
            </ListItemButton>

            <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <StandardButton text="Logout" onClick={logout} />
              {isAdmin && (
                <StandardButton 
                  text={isAdminUser ? 'Standard Area' : 'Admin Area'} 
                  onClick={toggleUser} 
                />
              )}
            </Box>
          </List>
        </Box>
      )}

      {!isRestrictedUser && !isStartup && (
      <>
      <Box>
          <Typography variant="h6" sx={{ paddingTop: '25px', paddingBottom: '5px', paddingLeft: '25px', color: 'white', fontFamily: 'Urbanist, sans-serif' }}>
            Home
          </Typography>
          
          <List className={'SidebarMenuList'} sx={{ paddingLeft: '25px' }}>

            <ListItemButton component={Link} to="/account">
              <Box component="img" src={account} alt="Account" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Account"/>
            </ListItemButton>

            <ListItemButton >
              <Box component="img" src={dashboard} alt="Home" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Home" sx={{opacity:0.5, cursor: 'not-allowed',}}/>
            </ListItemButton>

            <ListItemButton >
              <Box component="img" src={scout_startups} alt="Startups" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Startups" sx={{opacity:0.5, cursor: 'not-allowed',}}/>
            </ListItemButton>

            <ListItemButton >
              <Box component="img" src={saved} alt="Saved" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Saved" sx={{opacity:0.5, cursor: 'not-allowed',}}/>
            </ListItemButton>

            <ListItemButton >
              <Box component="img" src={chats} alt="Chats" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Chats" sx={{opacity:0.5, cursor: 'not-allowed',}}/>
            </ListItemButton>

            <ListItemButton >
              <Box component="img" src={ai_navigator} alt="ai navogator" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Ai Navigator" sx={{opacity:0.5, cursor: 'not-allowed',}}/>
            </ListItemButton>
          
          </List>

          <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <StandardButton text="Logout" onClick={logout} />
          </Box>

      </Box>
      </>
      )}


      {/* Se l'utente è una startup */}
      {isStartup && !isRestrictedUser &&(
        <Box>
          <Typography
            variant="h6"
            sx={{
              paddingTop: '25px',
              paddingBottom: '5px',
              paddingLeft: '25px',
              color: 'white',
              fontFamily: 'Urbanist, sans-serif',
            }}
          >
            Menu
          </Typography>

          <List className={'SidebarMenuList'} sx={{ paddingLeft: '25px' }}>
            <ListItemButton
              component={Link}
              to={`/startups/startup/${startupId}`}
              sx={
                isStartupIncomplete
                  ? {
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }
                  : {}
              }
            >
              <Box component="img" src={dashboard} alt="Startup Page" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Startup Page" />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to={`/chatsAdmin`}
              sx={
                isStartupIncomplete
                  ? {
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }
                  : {}
              }
            >
              <Box component="img" src={chats} alt="Chat" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Chat" />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to={`/account`}
              sx={
                isStartupIncomplete
                  ? {
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }
                  : {}
              }
            >
              <Box component="img" src={account} alt="Account" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Account" />
            </ListItemButton>

            <ListItemButton component={Link} to={`/startupedit/${startupId}`}>
              <Box component="img" src={scout_startups} alt="Edit Startup" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7 } }} />
              <ListItemText primary="Edit Startup" />
            </ListItemButton>

            <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <StandardButton text="Logout" onClick={logout} />              
            </Box>
          </List>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            position: 'relative',
            backgroundColor: '#231F20',
            width: 250,
          },
        }}
      >
        {drawer}
      </Drawer>

      {isMobile && (
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{
            position: 'absolute',
            top: 25,
            right: 10,
            color: '#C5FF55',
            '&:hover': {
              backgroundColor: 'rgba(197, 255, 85, 0.1)',
            },
          }}
        >
          <MenuIcon sx={{ color: '#C5FF55' }} />
        </IconButton>
      )}

      {showUpgradeMessage && (
          <div style={{ position: "absolute", top: "0", width: "100%", backgroundColor: '#fffa76', color: '#000', padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>
              <p style={{fontSize: "16px"}}>Want to compare and evaluate a curated selection of startups that match your search? Upgrade to the full subscription, to access this feature—click the button below!</p>
              <a class="btn" href="mailto:a.montanari@retailhub.it">Apply</a>
              <button onClick={() => setShowUpgradeMessage(false)} style={{ marginLeft: "10px", background: "red", color: "white", border: "none", padding: "5px 10px", cursor: "pointer" }}>Close</button>
          </div>
      )}

    </>

    
  );
}

export default Sidebar;
