import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import SendIcon from '@mui/icons-material/Send';
import StandardTextfield from "../standardTextfield";
import showdown from 'showdown';

export default function Step3({ onNext, chatMessages, setChatMessages, innovations, message, isTypingAncestor }) {
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [serverReplied, setServerReplied] = useState(false);
  const [nextMessage, setNextMessage] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [typingMessage, setTypingMessage] = useState("");
  const fullMessage = useRef(""); 
  const [typingHTML, setTypingHTML] = useState("");

  const messagesEndRef = useRef(null);
  const token = localStorage.getItem("token");

  const converter = new showdown.Converter();

  function replace_bold_names_with_links(response) {
    const pattern = /\*\*(.*?)\*\* \(ID: (.*?)\)/g;
  
    // Replace each found bold text with an HTML link with target="_blank" if it's a startup name
    return response.replace(pattern, (match, name, sid) => {
        if (name && sid) {
            const url = `${process.env.REACT_APP_BASE_URL}/startups/startup/${sid}`;
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${name}</a>`;
        }
        return match; 
    });
  }

  // Funzione per inviare il messaggio di processo di valutazione
  const sendEvaluationProcessMessage = async () => {
    try {
        setIsTyping(true);
        setTypingHTML("Typing...");

        const payload = { message: "tell me more about the evaluation process" };
        const response = await fetch(`${process.env.REACT_APP_API_URL}/advisory/message?stream=true`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
        let accumulatedText = "";

        while (true) {
            const { value, done } = await reader.read();
            
            if (done || accumulatedText.includes("[DONE]")) {
                break;
            }

            value.replaceAll("\n\n\n\n", '__DD__\n\n').split("\n\n").forEach((element) => {
                if (element !== "") {
                    const processedElement = element.replaceAll("__DD__", '\n\n');
                    if (processedElement.startsWith("data: ")) {
                        accumulatedText += processedElement.substring(6);
                    } else {
                        accumulatedText += processedElement;
                    }
                }
            });

            // Update the typing indicators with accumulated text
            const formattedText = converter.makeHtml(accumulatedText.replaceAll("\n   -", "\n    -"));
            setTypingHTML(formattedText); // HTML-formatted content for the typing indicator
        }

        fullMessage.current = replace_bold_names_with_links(fullMessage.current);

        fullMessage.current = accumulatedText
            .replace(/data:  -/g, '') 
            .replace(/\[DONE\]/g, '')
            .trim();

        // Update chatMessages with the final message once fully processed
        setChatMessages(prevMessages => [
            ...prevMessages,
            { id: prevMessages.length, text: fullMessage.current, sender: "Server" },
            { id: prevMessages.length + 1, type: "options", sender: "Server" }
        ]);

        // Clear typing indicators after message is complete
        setTypingMessage("");
        setIsTyping(false);
        setServerReplied(true);

    } catch (error) {
        console.error('Error during fetch:', error);
        setError('Error sending message');
        setIsTyping(false); 
    }
  };


   
  useEffect(() => {
    if (message) {
      // Se `message` è true, invia il messaggio automaticamente
      sendEvaluationProcessMessage();
    } else if (!serverReplied) { 
      // Se `message` è false, aggiungi direttamente le opzioni e imposta `serverReplied` su true
      setServerReplied(true); // Visualizza subito il box
      setChatMessages(prevMessages => [
        ...prevMessages,
        { id: prevMessages.length, type: "options", sender: "Server" }
      ]);
    }
  }, [message]);

  // Scroll to bottom when chatMessages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages, typingMessage, typingHTML]);

  // Funzione per inviare il messaggio delle startup
  const handleSendStartupsMessage = async () => {
    try {
      setButtonClicked(true); // Rimuove i pulsanti dopo il clic
      // Rimuovi l'intero messaggio con il box di opzioni dalla chat
      setChatMessages(prevMessages => prevMessages.filter(msg => msg.type !== "options"));
      onNext(5, innovations, nextMessage); // Procedi allo step successivo
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Error sending message");
    }
  };

  // Funzione per inviare nuovi messaggi
  const handleAddMessage = async () => {
    if (newMessage.trim() !== "") {
        setChatMessages(prevMessages => [
            ...prevMessages,
            { id: prevMessages.length, text: newMessage, sender: "You" }
        ]);

        setIsTyping(true);
        setTypingMessage("Typing...");

        try {
            const payload = {
                message: newMessage,
                stream: true
            };

            fullMessage.current = "";

            const response = await fetch(`${process.env.REACT_APP_API_URL}/advisory/message?stream=true`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (!response.body) {
                throw new Error('ReadableStream not supported or response body is empty.');
            }

            const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
            let accumulatedText = "";

            while (true) {
                const { value, done } = await reader.read();

                if (done || accumulatedText.includes("[DONE]")) {
                    break;
                }

                value.replaceAll("\n\n\n\n", '__DD__\n\n').split("\n\n").forEach((element) => {
                    if (element !== "") {
                        const processedElement = element.replaceAll("__DD__", '\n\n');
                        if (processedElement.startsWith("data: ")) {
                            accumulatedText += processedElement.substring(6);
                        } else {
                            accumulatedText += processedElement;
                        }
                    }
                });

                const formattedText = converter.makeHtml(accumulatedText.replaceAll("\n   -", "\n    -"));
                setTypingMessage(formattedText);
            }

            // Parsa e pulisci in caso
            fullMessage.current = accumulatedText
            .replace(/data:  -/g, '')    
            .replace(/data:    -/g, '')
            .replace(/data: -/g, '')
            .replace(/\[DONE\]/g, '')
            .trim();

            fullMessage.current = replace_bold_names_with_links(fullMessage.current);

            // Update chat messages
            setChatMessages(prevMessages => [
            ...prevMessages,
            { id: prevMessages.length, text: fullMessage.current, sender: "Server" },
            { id: prevMessages.length + 1, type: "button", sender: "System" }
            ]);

            setTypingMessage("");
            setIsTyping(false);

        } catch (error) {
            console.error('Error during fetch:', error);
            setError('Error sending message');
            setIsTyping(false);
        }

        setNewMessage("");
    }
  };

  const handleNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  // Funzione per "No" click, procede allo Step 6
  const handleSecondNoClick = () => {
    setButtonClicked(true);
    setChatMessages(prevMessages => prevMessages.filter(msg => msg.type !== "options"));
    onNext(6); // Procedi al questionario
  };

  // Funzione per tornare allo Step 1
  const handleBackToStep1 = async () => {
    try {
      setIsTyping(true); // Disable input while processing
      onNext(1);
    } catch (error) {
      window.location.reload();
      console.error('Error changing to step 1:', error);
    } finally {
      setIsTyping(false); // Re-enable input
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a new line
      handleAddMessage();     // Calls your message sending function
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* Chat Container */}
      <div className="flex flex-col flex-grow h-0">
        {/* Chat Messages Area */}
        <div className="flex flex-col flex-grow overflow-y-auto px-5">
          
        {chatMessages.map((m, index) => {
          if (m.type === 'options' && !buttonClicked && serverReplied) {
            return (
              <div key={`${m.sender}-${index}`} className="p-5 flex flex-col items-center bg-black-l rounded-2xl max-w-[90%] mt-4" style={{ marginLeft: '5%' }}>
                <span className="font-semibold text-white">
                  Perfect! Do you know what the following startups are about? Do you want to talk about them?
                </span>
                <div className="flex gap-2 mt-4">
                  <Button variant="contained" sx={{ backgroundColor: "rgb(var(--global-color-primary))", color: "#000" }} onClick={handleSendStartupsMessage}>
                    Yes
                  </Button>
                  <Button variant="outlined" sx={{ borderColor: "rgb(var(--global-color-primary))", color: "#fff" }} onClick={handleSecondNoClick}>
                    No, go to questionnaire
                  </Button>
                </div>
              </div>
            );
          } else {
            // Render normal messages
            return (
              <div key={`${m.sender}-${index}`} className={`flex flex-col gap-1 ${m.sender === "You" ? "items-end" : "items-start"}`}>
                <span className="font-semibold">{m.sender}</span>
                <div className="p-2 bg-gray-800 w-full rounded-2xl max-w-[80%] whitespace-pre-wrap msgClass">
                  {m.sender === "Server" ? (
                    <ReactMarkdown>{m.text}</ReactMarkdown>
                  ) : (
                    <span>{m.text}</span>
                  )}
                </div>
              </div>
            );
          }
        })}


          {/* Typing Indicator */}
          {isTyping && typingHTML && (
              <div className="flex flex-col gap-1 items-start mt-4">
                  <span className="font-semibold">AI Navigator</span>
                  <div
                      className="p-2 bg-gray-800 rounded-2xl max-w-[80%] flex flex-col msgClass"
                      dangerouslySetInnerHTML={{ __html: typingHTML }}
                  />
              </div>
          )}

          {/* Scroll Anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-5 w-full bg-black flex items-center gap-2">
          {/* Back to Step 1 Button */}
          <Button
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "#000 !important",
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleBackToStep1}
          >
            Restart
          </Button>

          <StandardTextfield
            variant="outlined"
            multiline={true}
            value={newMessage}
            placeholder="Write here..."
            onChange={handleNewMessage}
            disabled={isTyping}
            style={{ flexGrow: 1 }}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(var(--global-color-primary))",
              color: "black",
              minWidth: '48px',
              minHeight: '48px',
              '&:hover': {
                backgroundColor: "rgb(var(--global-color-primary))",
              }
            }}
            onClick={handleAddMessage}
            disabled={isTyping || (!buttonClicked)}
            
          >
            <SendIcon />
          </Button>
        </div>
      </div>

      {/* Display error */}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
}
