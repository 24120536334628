import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Grid } from "@mui/material";

const CompanyInformationDialog = ({ open, handleClose, innovationId, startupData }) => {
  const [formData, setFormData] = useState({
    country_id: "",
    city: "",
    address: "",
    accountType: "",
    name: "",
    founders: "",
    phone: "",
    email: "",
    linkedin_url: "",
    website: "",
    founding_year: "",
    total_funding: "",
    companyStatus: "",
    company_type_id: "",
    company_stage_id: "",
    business_model_id: "",
    otherCountries: [], // Multi-select field for target markets
    //integrationTiming: "",
    //numberOfEmployees: "",
    revenue: "",
    platforms: "",
    clients: "",
  });

  const [errors, setErrors] = useState({});
  const [dropdownValues, setDropdownValues] = useState({
    countries: [],
    companyTypes: [],
    companyStages: [],
    businessModels: [],
    targetMarkets: [],
  });

  // Fetch dropdown values including target markets
  useEffect(() => {
    const fetchDropdownValues = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/values/innovation-filters?admin=false`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        
        setDropdownValues((prev) => ({
          ...prev,
          countries: data.data.countries || [],
          companyTypes: data.data.company_types || [],
          companyStages: data.data.stages || [],
          businessModels: data.data.business_models || [],
          targetMarkets: data.data.target_markets || [],          
        }));        
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
      }
    };

    fetchDropdownValues();
  }, []);

  // Populate formData only after dropdownValues are available
  useEffect(() => {
    if (startupData && dropdownValues.targetMarkets.length > 0) {
      setFormData((prev) => ({
        ...prev,
        country_id: startupData.country_id || "",
        city: startupData.city || "",
        address: startupData.address || "",
        name: startupData.name || "",
        founders: startupData.founders ? startupData.founders.replaceAll(";", "") : "",
        phone: startupData.phone ? startupData.phone.replaceAll(";", "") : "",
        email: startupData.emails ? startupData.emails.replaceAll(";", "") : "",
        linkedin_url: startupData.linkedin_url || "",
        website: startupData.website || "",
        founding_year: startupData.founding_year || "",
        total_funding: startupData.total_funding || "",        
        company_type_id: startupData.company_type?.id || "",
        company_stage_id: startupData.company_stage?.id || "",
        business_model_id: startupData.business_model?.id || "",
        //integrationTiming: startupData.integration_timing || "",
        //numberOfEmployees: startupData.number_employees || "",
        revenue: startupData.revenue || "",
        platforms: startupData.platforms ? startupData.platforms.replaceAll(";", "") : "",
        clients: startupData.clients ? startupData.clients.replaceAll(";", "") : "",
        otherCountries: startupData.target_markets
        ? startupData.target_markets.map(m => String(m.id))
        : [],
      }));
      console.log("startupData.target_markets", startupData.target_markets);
    }
  }, [startupData, dropdownValues.targetMarkets]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const requiredFields = [
    "name",
    "website",
    "linkedin_url",
    "country_id",
    "founding_year",
    "company_type_id",
    "business_model_id",
    "company_stage_id",
    "otherCountries",
    "founders",
  ];

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    let newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field] || (typeof formData[field] === "string" && formData[field].trim() === "")) {
        newErrors[field] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Creazione del payload senza campi vuoti
    let payload = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== undefined && formData[key] !== "") {
        payload[key] = formData[key];
      }
    });

    // Aggiunta dei `;` nei campi richiesti
    if (formData.founders) payload.founders = `;${formData.founders};`;
    if (formData.phone) payload.phone = `;${formData.phone};`;
    if (formData.email) {
      payload.emails = `;${formData.email};`;
      delete payload.email;
    }
    if (formData.platforms) payload.platforms = `;${formData.platforms};`;
    if (formData.clients) payload.clients = `;${formData.clients};`;

    // Gestione corretta di `target_markets`, `tags` e `categories`
    if (Array.isArray(formData.otherCountries) && formData.otherCountries.length > 0) {
      payload.target_markets = formData.otherCountries
        .map(value => (value === "null" ? null : Number(value))) // Converte "null" in null e numeri in Number
        .filter(id => id !== null && id !== undefined && id !== "" && !isNaN(id)); // Filtra NaN e valori non validi
    }

    delete payload.otherCountries;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/innovations/${innovationId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Data updated successfully!");
        handleClose();
      } else {
        alert("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("An error occurred.");
    }
  };
  
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "#191919", color: "white", textAlign: "center" }}>
        Company Information
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white", padding: "24px" }}>
        <Grid container spacing={3}>
  
          {/* Company Name (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              label="Company Name"
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.name)}
              helperText={errors.name || ""}
            />
          </Grid>
  
          {/* Website (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="website"
              label="Website"
              fullWidth
              variant="outlined"
              value={formData.website}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.website)}
              helperText={errors.website || ""}
            />
          </Grid>
  
          {/* linkedin_url URL (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="linkedin_url"
              label="linkedin_url URL"
              fullWidth
              variant="outlined"
              value={formData.linkedin_url}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.linkedin_url)}
              helperText={errors.linkedin_url || ""}
            />
          </Grid>
  
          {/* country_id (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="country_id"
              label="country_id"
              select
              fullWidth
              variant="outlined"
              value={formData.country_id}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.country_id)}
              helperText={errors.country_id || ""}
            >
              {dropdownValues.countries.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
  
          {/* Founding Year (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="founding_year"
              label="Founding Year"
              fullWidth
              variant="outlined"
              value={formData.founding_year}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.founding_year)}
              helperText={errors.founding_year || ""}
            />
          </Grid>
  
          {/* Company Type (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="company_type_id"
              label="Company Type"
              select
              fullWidth
              variant="outlined"
              value={formData.company_type_id}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.company_type_id)}
              helperText={errors.company_type_id || ""}
            >
              {dropdownValues.companyTypes.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
  
          {/* Business Model (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="business_model_id"
              label="Business Model"
              select
              fullWidth
              variant="outlined"
              value={formData.business_model_id}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.business_model_id)}
              helperText={errors.business_model_id || ""}
            >
              {dropdownValues.businessModels.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
  
          {/* Company Stage (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="company_stage_id"
              label="Company Stage"
              select
              fullWidth
              variant="outlined"
              value={formData.company_stage_id}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.company_stage_id)}
              helperText={errors.company_stage_id || ""}
            >
              {dropdownValues.companyStages.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
  
          {/* Target Markets (Required) */}
          <Grid item xs={12} md={6}>
          <TextField
              name="otherCountries"
              label="Target Markets"
              select
              fullWidth
              variant="outlined"
              value={formData.otherCountries}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.otherCountries)}
              helperText={errors.otherCountries || ""}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                  // --- Ecco dove aggiungi il console.log
                  //console.log("Selected IDs:", selected);
            
                  const selectedItems = dropdownValues.targetMarkets
                    .filter((item) => selected.includes(item.id));
            
                  //console.log("Selected Items after filter:", selectedItems);
                  
                  return selectedItems.map((item) => item.name).join(", ");
                },
              }}
            >
              {dropdownValues.targetMarkets.map((item) => (                
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>

          </Grid>
  
          {/* Founders (Required) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="founders"
              label="Founders"
              fullWidth
              variant="outlined"
              value={formData.founders}
              onChange={handleInputChange}
              sx={textFieldStyle}
              required
              error={Boolean(errors.founders)}
              helperText={errors.founders || ""}
            />
          </Grid>
  
          {/* City (Opzionale) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="city"
              label="City"
              fullWidth
              variant="outlined"
              value={formData.city}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
          </Grid>
  
          {/* Phone (Opzionale) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              variant="outlined"
              value={formData.phone}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
          </Grid>
  
          {/* Email (Opzionale) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
          </Grid>          
  
          {/* Revenue (Opzionale) */}
          <Grid item xs={12} md={6}>
            <TextField
              name="revenue"
              label="Revenue"
              fullWidth
              variant="outlined"
              value={formData.revenue}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />            
          </Grid>

          {/* Revenue (Opzionale) */}
          <Grid item xs={12} md={6}>
          <TextField
              name="clients"
              label="Clients"
              fullWidth
              variant="outlined"
              value={formData.clients}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />           
          </Grid>
  
        </Grid>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px" }}>
        <Button onClick={handleClose} sx={{ color: "white" }}>
          Cancel
        </Button>
        <Button onClick={handleUpdate} sx={{ backgroundColor: "rgb(var(--global-color-primary))", color: "black", fontWeight: "bold" }}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
  
  
};

const textFieldStyle = {
  marginBottom: "16px",
  "& .MuiInputBase-root": { color: "white" },
};

export default CompanyInformationDialog;
