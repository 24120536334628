import React, { useState, useEffect } from "react";
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import StandardButton from '../../standardButton';
import axios from 'axios';

export default function EditPlanBilling({subId, open, handleClose, refreshParent}) {

  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]); // Oggi come default
  const [endDate, setEndDate] = useState("");
  const [isTrial, setIsTrial] = useState(false);
  const [planType, setPlanType] = useState("");
  const [plans, setPlans] = useState([]);

  useEffect(() => {
      if (open) {
        fetchPlans();
      }
  }, [open]);
  
  const fetchPlans = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/plans`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPlans(response.data.data || []);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };
  
  const handleUpdateSubscription = async () => {
      try {
        const token = localStorage.getItem("token");
      
        const payload = {
          plan_id: planType,
          starts_at: startDate,
          ends_at: endDate,
          is_trial: isTrial || false,
        };
      
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/subscriptions/${subId}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      
        alert("Subscription updated successfully!");
        handleClose();
        refreshParent();
      } catch (error) {
        console.error("Error updating subscription:", error);
        alert("Failed to update subscription.");
      }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          backgroundColor: '#191919',
          fontFamily: 'Urbanist, sans-serif',
        }}
      >
        Edit Plan
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: '#191919',
          fontFamily: 'Urbanist, sans-serif',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: "rgba(255, 255, 255, 0.7)" }}>Plan Type</InputLabel>
              <Select
                value={planType}
                onChange={(e) => setPlanType(e.target.value)}
                sx={{ color: "white" }}
              >
                {plans.map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
                  
          <Grid item xs={6}>
            <TextField
              name="start_date"
              label="Start Date"
              type="date"
              fullWidth
              variant="outlined"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: new Date().toISOString().split('T')[0] }} // Impedisce date passate
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="end_date"
              label="End Date"
              type="date"
              fullWidth
              variant="outlined"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <Checkbox
              checked={isTrial}
              onChange={(e) => setIsTrial(e.target.checked)}
              sx={{
                color: "#A3E635",
                "&.Mui-checked": { color: "#A3E635" },
              }}
            />
            <span style={{ color: "white", marginLeft: "8px" }}>Is Trial?</span>
          </Grid>
        </Grid>

      </DialogContent>

      <DialogActions
        sx={{
          backgroundColor: '#191919',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <StandardButton
          onClick={handleClose}
          type={'secondary'}
          variant={'outlined'}
          text={"Cancel"}
        />
        <StandardButton
          onClick={handleUpdateSubscription}
          text="Confirm"
          type="primary"
        />

      </DialogActions>
    </Dialog>
  );
}
