import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  InputAdornment, 
  IconButton
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import loginScreen from '../assets/images/rh_login.jpg';
import axios from "axios";
import logoRH from '../assets/images/Logo IE_White.png'

const theme = createTheme({
  typography: {
    fontFamily: "Urbanist, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#c5ff55",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${loginScreen})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
        },
      },
    },
  },
});


const API_URL = process.env.REACT_APP_API_URL;

export default function Register() {
  const [step, setStep] = useState(1);

  // Usiamo un unico state formData aggiungendo "selectedType" per la scelta iniziale
  const [formData, setFormData] = useState({
    // Step 1
    email: "",
    selectedType: "",  // "company" | "innovation"

    // Step 2
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    companyType: "",   // user_type_id (solo se type=company)
    companyName: "",
    innovationName: "",
    website: "",
    phone: "",

    // Privacy
    agreeToTerms: false,
  });

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [token, setToken] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  // Stato per salvare il tipo di registrazione “finale” (company, innovation o invited)
  const [registrationType, setRegistrationType] = useState(null);

  // Per popolare i radio button se registrationType = "company"
  const [companyTypes, setCompanyTypes] = useState({
    companies: [],
    individuals: [],
  });

  // Se c’è un token nella query string, passiamo allo step 2 (il form finale).
  // In step 2 mostriamo i campi in base a registrationType.
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get("token");
    if (urlToken) {
      setToken(urlToken);
      fetchRegistrationForm(urlToken);
    }
  }, []);

  const fetchRegistrationForm = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/register-form?token=${token}`);
      if (response.data) {
        // 1) Salviamo il type finale restituito dall’API
        const rType = response.data.type;
        setRegistrationType(rType);

        // 2) Eventuali campi precompilati (ad es. email)
        setFormData((prev) => ({
          ...prev,
          email: response.data.email || "",
        }));

        // Se rType === "company", carichiamo i possibili user_type_id
        if (rType === "company" && response.data.user_type_id) {
          const companyOptions = response.data.user_type_id.companies
            ? response.data.user_type_id.companies.filter((c) => c.active)
            : [];
          const individualOptions = response.data.user_type_id.individuals
            ? response.data.user_type_id.individuals.filter((i) => i.active)
            : [];

          setCompanyTypes({
            companies: companyOptions,
            individuals: individualOptions,
          });
        }

        // Passiamo allo step 2 (il form di registrazione vero e proprio)
        setStep(2);
      } else {
        setError("Invalid or expired registration link.");
      }
    } catch (error) {
      setError("Failed to verify registration. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  /**
   * Step 1: invio email
   * Includiamo anche il tipo scelto dall’utente (company o innovation).
   */
  const handleEmailSubmit = async () => {
    setError("");
    setMessage("");

    if (!formData.email.trim()) {
      setError("Please enter a valid email.");
      return;
    }

    if (!formData.selectedType) {
      setError("Please select a registration type (Company or Innovation).");
      return;
    }

    try {
      await axios.post(`${API_URL}/register-email`, {
        email: formData.email,
        type: formData.selectedType,
      });
      setMessage(`We have sent a link to ${formData.email}. Please check your inbox and verify.`);
    } catch (error) {
      setError("Failed to send email. Please try again.");
    }
  };

  /**
   * Step 2: submit finale della registrazione
   */
  const handleSubmit = async () => {
    setError("");

    // Validazioni generali: password, confirm, privacy
    if (!formData.password || formData.password !== formData.confirmPassword) {
      setError("Passwords must match.");
      return;
    }
    if (!formData.agreeToTerms) {
      setError("You must agree to the terms to proceed.");
      return;
    }

    let endpoint = "";
    let body = {};

    switch (registrationType) {
      case "company":
        // Validazioni extra
        if (!formData.firstName.trim() || !formData.lastName.trim()) {
          setError("Please fill in both First Name and Last Name.");
          return;
        }
        if (!formData.companyType) {
          setError("Please select a company type (user_type_id).");
          return;
        }
        if (!formData.companyName.trim()) {
          setError("Please enter a company name.");
          return;
        }

        endpoint = `${API_URL}/register/company?token=${token}`;
        body = {
          user_type_id: parseInt(formData.companyType, 10),
          first_name: formData.firstName,
          last_name: formData.lastName,
          password: formData.password,
          password_confirmation: formData.confirmPassword,
          company_name: formData.companyName,
        };
        break;

      case "innovation":
        if (!formData.firstName.trim() || !formData.lastName.trim()) {
          setError("Please fill in both First Name and Last Name.");
          return;
        }
        if (!formData.innovationName.trim()) {
          setError("Please provide the Innovation Name.");
          return;
        }
        if (!formData.website.trim()) {
          setError("Please provide the Website.");
          return;
        }
        if (!formData.phone.trim()) {
          setError("Please provide the Phone number.");
          return;
        }

        endpoint = `${API_URL}/register/innovation?token=${token}`;
        body = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          password: formData.password,
          password_confirmation: formData.confirmPassword,
          innovation_name: formData.innovationName,
          website: formData.website,
          phone: formData.phone,
        };
        break;

      case "invited":
        // Invited => servono solo password e confirm
        endpoint = `${API_URL}/register/invited?token=${token}`;
        body = {
          password: formData.password,
          password_confirmation: formData.confirmPassword,
        };
        break;

      default:
        setError("Unknown registration type. Please try again.");
        return;
    }

    // Invio finale
    try {
      await axios.post(endpoint, body);
      setStep(3);
    } catch (error) {
      setError("Registration failed. Please try again.");
    }
  };

  return (
    <>
    <style>
      {`
        html, body {
          background-image: url(${loginScreen});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          min-height: 100vh;
          margin: 0;
          padding: 0;
        }
      `}
    </style>    

    <div className="flex flex-row justify-center" style={{height: "10vh",}}>
      <img src={logoRH} style={{width: "5%", maxHeight: "100%", objectFit: "contain"}}/>
    </div>


    <ThemeProvider theme={theme}>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "90vh",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            width: "100%",
            padding: 3,
            backgroundColor: "#191919",
            borderRadius: 2,
            boxShadow: 3,
            color: "#fff",
          }}
        >
          {/* STEP 1 - Scegli tipo (Company/Innovation) e inserisci email */}
          {step === 1 && !token && (
            <Box>
              <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
                Enter Your Email
              </Typography>

              <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                type="email"
                InputLabelProps={{ style: { color: "#fff" } }}
                sx={{
                  input: { color: "#fff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#c5ff55" },
                    "&:hover fieldset": { borderColor: "#c5ff55" },
                  },
                }}
              />

              <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                Select Registration Type:
              </Typography>
              <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                <RadioGroup
                  name="selectedType"
                  value={formData.selectedType}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="company"
                    control={<Radio sx={{ color: "#c5ff55" }} />}
                    label="Corporate"
                    sx={{ color: "#fff" }}
                  />
                  <FormControlLabel
                    value="innovation"
                    control={<Radio sx={{ color: "#c5ff55" }} />}
                    label="Startup"
                    sx={{ color: "#fff" }}
                  />
                </RadioGroup>
              </FormControl>

              {error && (
                <Alert severity="error" sx={{ marginTop: 2 }}>
                  {error}
                </Alert>
              )}
              {message && (
                <Alert severity="success" sx={{ marginTop: 2 }}>
                  {message}
                </Alert>
              )}

              <Button
                variant="contained"
                fullWidth
                sx={{ marginTop: 2, backgroundColor: "#c5ff55", color: "#191919" }}
                onClick={handleEmailSubmit}
              >
                Send Verification Email
              </Button>

              <>
                <div className="flex justify-center mt-5">
                  <a href="/auth">&larr; Back to login</a>
                </div>
              </>
            </Box>
          )}

          {/* STEP 2 - Dopo che l'utente ha cliccato il link e c’è un token */}
          {step === 2 && (
            <Box>
              <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
                Complete Your Registration
              </Typography>

              {/* Se NON è invited => mostra firstName/lastName */}
              {registrationType !== "invited" && (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ style: { color: "#fff" } }}
                    sx={{
                      input: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#c5ff55" },
                        "&:hover fieldset": { borderColor: "#c5ff55" },
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ style: { color: "#fff" } }}
                    sx={{
                      input: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#c5ff55" },
                        "&:hover fieldset": { borderColor: "#c5ff55" },
                      },
                    }}
                  />
                </>
              )}

              {/* Campi Password, sempre visibili */}
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"} // Mostra/nasconde la password
                value={formData.password}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                label="Confirm Password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"} // Mostra/nasconde la conferma password
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Se TYPE = company => radio + companyName */}
              {registrationType === "company" && (
                <>
                  <FormControl component="fieldset" sx={{ marginTop: 2, width: "100%" }}>
                    <RadioGroup
                      name="companyType"
                      value={formData.companyType}
                      onChange={handleChange}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        gap: 2,
                        alignItems: "flex-start",
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        {companyTypes.companies.length > 0 && (
                          <>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#c5ff55", marginBottom: 1 }}
                            >
                              Companies
                            </Typography>
                            {companyTypes.companies.map((option) => (
                              <FormControlLabel
                                key={option.id}
                                value={String(option.id)}
                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                label={option.name}
                                sx={{ width: "100%" }}
                              />
                            ))}
                          </>
                        )}
                      </Box>
                      <Box sx={{ width: "50%" }}>
                        {companyTypes.individuals.length > 0 && (
                          <>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#c5ff55", marginBottom: 1 }}
                            >
                              Individuals
                            </Typography>
                            {companyTypes.individuals.map((option) => (
                              <FormControlLabel
                                key={option.id}
                                value={String(option.id)}
                                control={<Radio sx={{ color: "#c5ff55" }} />}
                                label={option.name}
                                sx={{ width: "100%" }}
                              />
                            ))}
                          </>
                        )}
                      </Box>
                    </RadioGroup>
                  </FormControl>

                  <TextField
                    fullWidth
                    margin="normal"
                    label="Company Name"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ style: { color: "#fff" } }}
                    sx={{
                      input: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#c5ff55" },
                        "&:hover fieldset": { borderColor: "#c5ff55" },
                      },
                    }}
                  />
                </>
              )}

              {/* Se TYPE = innovation => innovationName, website, phone */}
              {registrationType === "innovation" && (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Innovation Name"
                    name="innovationName"
                    value={formData.innovationName}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ style: { color: "#fff" } }}
                    sx={{
                      input: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#c5ff55" },
                        "&:hover fieldset": { borderColor: "#c5ff55" },
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Website"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ style: { color: "#fff" } }}
                    sx={{
                      input: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#c5ff55" },
                        "&:hover fieldset": { borderColor: "#c5ff55" },
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    InputLabelProps={{ style: { color: "#fff" } }}
                    sx={{
                      input: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#c5ff55" },
                        "&:hover fieldset": { borderColor: "#c5ff55" },
                      },
                    }}
                  />
                </>
              )}

              {/* Se invited => nessun altro campo extra oltre a password e confirm. */}

              {/* Privacy/Terms: mostrata sempre */}
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreeToTerms"
                    checked={formData.agreeToTerms}
                    onChange={handleChange}
                    sx={{ color: "#c5ff55" }}
                  />
                }
                label="I agree to the Terms and Privacy Policy"
                sx={{ color: "#fff", marginTop: 2 }}
              />

              {error && (
                <Alert severity="error" sx={{ marginTop: 2 }}>
                  {error}
                </Alert>
              )}

              <Button
                variant="contained"
                fullWidth
                sx={{ marginTop: 2, backgroundColor: "#c5ff55", color: "#191919" }}
                onClick={handleSubmit}
              >
                Complete Registration
              </Button>
            </Box>
          )}
          {step === 3 && (
            <Box>
              <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
                🎉 Thank you! Your account is almost ready.
              </Typography>
              <Typography sx={{ textAlign: "center", marginTop: 2 }}>
                Please check your email to verify your account. We have sent you all the details you need to complete your registration.
              </Typography>

              <Button
                component={Link}
                to="/auth"
                variant="contained"
                fullWidth
                sx={{ marginTop: 2, backgroundColor: "#c5ff55", color: "#191919" }}
              >
                Back to Login
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
    </>
  );
}
