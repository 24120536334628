// Layout.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './header';
import Sidebar from './sidebar';
import { Box } from '@mui/material';
import axios from 'axios';

// 1. Importa il contesto
import { UserContext } from './context/UserContext';

const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [bypassTokenCheck, setBypassTokenCheck] = useState(false);

  const [isStartupIncomplete, setIsStartupIncomplete] = useState(false);
  const [noPermission, setNoPermission] = useState(false);

  // 2. Stato user che verrà messo a disposizione nel contesto
  const [user, setUser] = useState(null);

  const [logo, setLogo] = useState(null);

  const hiddenRoutes = [
    '/auth',
    '/lander-survey',
    '/register-invite',
    '/register',
    '/lost-password',
    '/startup/startup-public'
  ];
  
  const hideHeaderAndSidebar = hiddenRoutes.some(route => location.pathname.startsWith(route));

  // useEffect 1: Verifica token
  useEffect(() => {
    const checkToken = async () => {
      const queryParams = new URLSearchParams(location.search);
      const queryToken = queryParams.get('token');

      if (queryToken && !hiddenRoutes.some(route => location.pathname.startsWith(route))) {
        localStorage.setItem('token', queryToken);
        console.log('Token saved from query string');
        setBypassTokenCheck(true);
        setIsLoading(false);
        return;
      }

      if (user) {
        setIsLoading(false);
        return;
      }

      if (!hideHeaderAndSidebar) {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/user`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            if (response.status === 200) {
              localStorage.setItem('account_completed', response.data.is_completed);
              setUser(response.data);

              const companyLogo = response.data.companies?.length > 0 ? response.data.companies[0].logo_url : null;

              // Se il logo non esiste, genera le iniziali dal nome e cognome
              const initials = response.data.first_name && response.data.last_name
                ? `${response.data.first_name.charAt(0)}${response.data.last_name.charAt(0)}`.toUpperCase()
                : null;

              setLogo(companyLogo || initials);
            }
          } catch (error) {
            console.error('Invalid or expired token', error);
            localStorage.removeItem('token');
            navigate('/auth');
          }
        } else {
          navigate('/auth');
        }
      }
      setIsLoading(false);
    };

    checkToken();
  }, [navigate, location, hideHeaderAndSidebar]);

  // useEffect 2: Navigazione condizionale
  useEffect(() => {
    if (isLoading) return;
    if (!user) return;
  
    const token = localStorage.getItem('token');
    if (!token) return;
  
    // 1️⃣ Controllo se l'utente è una startup ma non ha completato il profilo
    if (!user?.is_completed && user?.roles?.[0]?.name === 'user_innovation') {
      setIsStartupIncomplete(true);
      navigate(`/startupedit/${user.innovations[0]?.id}`);
      return;
    }
  
    // 2️⃣ Controllo se l'utente non ha permessi e non è user_innovation
    const userPermissions = user?.permissions || []; 
    const roleName = user?.roles?.[0]?.name || "";
  
    if (userPermissions.length === 0 && roleName !== "user_innovation" && location.pathname !== "/account" && location.pathname !== "/billingDetails") {
      setNoPermission(true);
      navigate('/billingDetails');
    }
  
  }, [isLoading, user, navigate, location.pathname]);

  if (isLoading) {
    return null;
  }

  return (
    // 3. Avvolgi tutto con il Provider, passando user
    <UserContext.Provider value={{ user, setUser, logo, setLogo }}>

      {/* Banner se è uno startup incompleto */}
      {!hideHeaderAndSidebar && !bypassTokenCheck && isStartupIncomplete && !noPermission &&(
        <div
          style={{
            backgroundColor: '#fffa76',
            color: '#000',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Please complete your startup information before accessing the rest of the platform.
        </div>
      )}

      {/* Banner se non ha permessi */}
      {!hideHeaderAndSidebar && !bypassTokenCheck && noPermission && !isStartupIncomplete &&(
        <div
          style={{
            backgroundColor: '#fffa76',
            color: '#000',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          <p style={{fontSize: "16px"}}>Your trial period for RetailHub Innovation Explorer has concluded. To continue accessing our service without interruption — click the button below!</p>
          <a class="btn" href="mailto:a.montanari@retailhub.it">Apply</a>
        </div>
        // Your trial period for RetailHub Innovation Explorer has concluded. To continue accessing our service without interruption, please visit your Account Settings to subscribe
      )}
      

      <div style={{ display: 'flex', flexDirection: 'column', height:"92vh" }}>
        {!hideHeaderAndSidebar && !bypassTokenCheck && <Header logo={logo}/>}

        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {!hideHeaderAndSidebar && !bypassTokenCheck && <Sidebar isStartupIncomplete={isStartupIncomplete}/>}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              overflow: 'auto',
              height: "92vh",
            }}
          >
            {children} {/* routes e sottocomponenti */}
          </Box>
        </Box>
      </div>
    </UserContext.Provider>
  );
};

export default Layout;
