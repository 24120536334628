import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Box,
    TextField,
    Button,
    Typography,
    Alert,
    Container,
    Link,
} from "@mui/material";
import { createTheme, ThemeProvider, IconButton, InputAdornment } from "@mui/material";
import { updateSocketAuth } from "../index";
import { Transform, Visibility, VisibilityOff } from "@mui/icons-material";
import loginScreen from '../assets/images/rh_login.jpg';
import logoRH from '../assets/images/Logo IE_White.png';

const theme = createTheme({
    typography: {
        fontFamily: "Urbanist, Arial, sans-serif",
    },
    palette: {
        primary: {
            main: "#c5ff55",
        },
    },
});

function Auth() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError("");

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                email: email,
                password: password,
            });
        
            const token = response.data.token;
            const user = response.data.user;
        
            // Estraggo il ruolo dinamicamente
            const roleName = user.roles?.length > 0 ? user.roles[0].name : "unknown";
        
            // Estraggo l'ID della prima innovazione se esiste, altrimenti null
            const innovationId = user.innovations?.length > 0 ? user.innovations[0].id : null;
        
            // Estraggo gli altri dati dell'utente
            const userId = user.id;
            const accountCompleted = user.accountCompleted || false;
            const companyId = user.companies?.length > 0 ? user.companies[0].id : null;
        
            // Salvo tutto nel localStorage (valido per tutti gli utenti)
            localStorage.setItem("token", token);
            localStorage.setItem("userId", userId);
            localStorage.setItem("companyId", companyId);
            localStorage.setItem("roleName", roleName);
            localStorage.setItem("innovation", innovationId);
        
            updateSocketAuth(token);
        
            // Se l'utente è user_innovation e ha un'innovazione, naviga alla pagina startup
            if (roleName === "user_innovation" && innovationId) {
                navigate(`/startups/startup/${innovationId}`);
            } else {
                // Per tutti gli altri utenti, naviga normalmente alla homepage
                setSuccess(true);
                navigate("/");
            }
        } catch (err) {
            setError("Login failed. Please check your credentials.");
            console.error("Error:", err);
        }
        
    };

    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
        <style>
          {`
            html, body {
              background-image: url(${loginScreen});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              min-height: 100vh;
              margin: 0;
              padding: 0;
            }
          `}
        </style>
        <div className="flex flex-row justify-center" style={{height: "10vh",}}>
            <img src={logoRH} style={{width: "5%", maxHeight: "100%", objectFit: "contain"}}/>
        </div>
        
        <ThemeProvider theme={theme}>
            <Container
                maxWidth="sm"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "90vh",
                    alignItems: "center",
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleLogin}
                    sx={{
                        width: "100%",
                        padding: 3,
                        backgroundColor: "#191919",
                        borderRadius: 2,
                        boxShadow: 3,
                        color: "#fff",
                    }}
                >
                    <Typography variant="h5" gutterBottom sx={{ color: "#fff", textAlign: "center" }}>
                        Login
                    </Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        InputLabelProps={{ style: { color: "#fff" } }}
                        sx={{
                            input: { color: "#fff" },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": { borderColor: "#c5ff55" },
                                "&:hover fieldset": { borderColor: "#c5ff55" },
                            },
                        }}
                    />

                    <TextField
                      fullWidth
                      margin="normal"
                      label="Password"
                      type={showPassword ? "text" : "password"}  // 🔄 Cambia tra testo e password
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      InputLabelProps={{ style: { color: "#fff" } }}
                      sx={{
                        input: { color: "#fff" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#c5ff55" },
                          "&:hover fieldset": { borderColor: "#c5ff55" },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              sx={{ color: "#c5ff55" }}  // Stile colore icona
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography variant="body1" gutterBottom sx={{ color: "#fff", textAlign: "center" }}>
                        <Link href="/lost-password" sx={{ color: "#fff", textDecoration: "underline" }}>
                            Lost your password?
                        </Link>
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ marginTop: 2 }}>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert severity="success" sx={{ marginTop: 2 }}>
                            Login successful! Redirecting...
                        </Alert>
                    )}                    
                    

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            marginTop: 2,
                            backgroundColor: "#c5ff55",
                            color: "#191919",
                        }}
                    >
                        Login
                    </Button>

                    <Typography variant="body1" gutterBottom sx={{ color: "#fff", textAlign: "center", marginTop: "10px" }}>
                        New to Innovation Explorer? 
                        <Link href="/register" sx={{ color: "#c5ff55", textDecoration: "underline", marginLeft: "5px" }}>
                            Sign Up
                        </Link>
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    </>
    );
    
}

export default Auth;
