import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip
} from "@mui/material";
import axios from "axios";
import DialogTags2 from "../components/adminStartupDetails/dialogs/dialogTags1Front";

const AddCompanyAdmin = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    country_id: "",
    linkedin_url: "",
    website: "",
    email_domain: "",
    phone_number: "",
    description: "",
    address: "",
    tax_id: "",
    vat_number: "",
    zip_code: "",
    notes: "",
    categories: [],
  });

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dialogSectorsOpen, setDialogSectorsOpen] = useState(false);
  const [selectedSectors, setSelectedSectors] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/values/countries`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data?.data) setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    if (open) {
      fetchCountries();
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveSectors = (sectors) => {
    setSelectedSectors(sectors);
    setFormData((prev) => ({
      ...prev,
      categories: sectors.map((sector) => sector.id),
    }));
  };

  const handleAddCompany = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      // Rimuove campi vuoti dal payload
      const payload = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== "" && value !== null)
      );

      await axios.post(`${process.env.REACT_APP_API_URL}/companies`, payload, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      });

      alert("Company added successfully!");
      handleClose();
    } catch (error) {
      console.error("Error adding company:", error.response?.data || error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "#191919", color: "white", textAlign: "center" }}>
        Add New Company
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#191919", color: "white", padding: "24px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField name="name" label="Company Name" fullWidth variant="outlined" value={formData.name} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/>
          </Grid>

          <Grid item xs={6}>
            <TextField name="city" label="City" fullWidth variant="outlined" value={formData.city} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: "rgba(255, 255, 255, 0.7)" }}>Country</InputLabel>
              <Select name="country_id" value={formData.country_id} onChange={handleInputChange} sx={{ color: "white" }}>
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField name="linkedin_url" label="LinkedIn URL" fullWidth variant="outlined" value={formData.linkedin_url} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/>
          </Grid>

          <Grid item xs={12}>
            <TextField name="website" label="Website" fullWidth variant="outlined" value={formData.website} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/>
          </Grid>          

          <Grid item xs={6}>
            <TextField name="phone_number" label="Phone Number" fullWidth variant="outlined" value={formData.phone_number} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/>
          </Grid>

          <Grid item xs={6}>
            <TextField name="zip_code" label="Zip Code" fullWidth variant="outlined" value={formData.zip_code} onChange={handleInputChange} InputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}/>
          </Grid>

          {/* Settori selezionati */}
          <Grid item xs={12}>
            <div className="flex flex-wrap gap-2">
              {selectedSectors.map((sector) => (
                <Chip key={sector.id} label={sector.name} sx={{ backgroundColor: "rgb(var(--global-color-primary))", color: "#000", fontWeight: "bold" }} />
              ))}
            </div>
            <Button variant="contained" onClick={() => setDialogSectorsOpen(true)} sx={{ mt: 2, backgroundColor: "#A3E635", color: "#000", fontWeight: "bold" }}>
              Edit Sectors
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px", justifyContent: "center" }}>
        <Button onClick={handleAddCompany} disabled={loading} sx={{ backgroundColor: "#A3E635", color: "#000", fontWeight: "bold" }}>
          {loading ? "Adding..." : "Add"}
        </Button>
      </DialogActions>

      {/* Dialog per la selezione dei settori */}
      <DialogTags2 open={dialogSectorsOpen} handleClose={() => setDialogSectorsOpen(false)} onSave={handleSaveSectors} disablePreselectedCategories={true} />
    </Dialog>
  );
};

export default AddCompanyAdmin;
