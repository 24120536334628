import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import UserDetails from "../components/adminUser/userDetails";
import AccountInformation from "../components/adminStartupDetails/accountInformation2";
import BillingDetails from "../components/adminUser/billingDetails";
import UserActivity from "../components/adminUser/userActivity";
import CompanyMember from "../components/adminUser/companyMembers";
import UserBookMarks from "../components/adminUser/userBookmarks";

const StartupAdmin = () => {
  const { id } = useParams();
  const [userData, setStartupData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [userId, setUserId] = useState(null);

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  
  const location = useLocation();
  const { companyId } = location.state || {};
  //console.log("companyId: ", companyId);

  const [companyData, setCompanyData] = useState(null);

  const handleDataFetchSuccess = (data) => {
    //console.log("Dati ricevuti:", data);
    if (data && data) {
      setStartupData(data);
      setUserId(data.id);
      //console.log("dati",data.id);
    } else {
      console.error("Formato dei dati non valido:", data);
      setError("Errore nel formato dei dati.");
    }
    setLoading(false);
  };

  const handleDataFetchSuccessCompany = (data) => {
    console.log("Dati ricevuti company:", data);
    if (data && data) {
      setCompanyData(data);
      //console.log("dati compnay settati",data);
    } else {
      console.error("Formato dei dati non valido:", data);
      setError("Errore nel formato dei dati.");
    }
    setLoading(false);
  };
  

  const handleDataFetchError = (err) => {
    setError(err.message || "Failed to fetch startup data.");
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Cambia il tab attivo
  };

  return (
    <div className="p-5 bg-black min-h-[100vh]">
      {/* Fetch dei dati */}
      <FlexibleDataFetcher
        endpoint={`users/${id}?admin=true`}
        onSuccess={handleDataFetchSuccess}
        onError={handleDataFetchError}
      />
      <FlexibleDataFetcher
        endpoint={`companies/${companyId}?admin=true`}
        onSuccess={handleDataFetchSuccessCompany}
        onError={handleDataFetchError}
      />
  
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (

        /* Sezione 1 */

        <div className="bg-black-l p-5 rounded-2xl">
            <div className="flex flex-row mb-3">
                <div className="w-2/4 flex flex-col">
                    <h1 className="text-5xl font-semibold">{userData?.first_name} {userData?.last_name}</h1>
                </div>
                <div className="w-2/4 flex flex-col gap-2 items-end	justify-center">

                    <div className="flex gap-2">
                      <span className="font-semibold">Active since:</span>
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {userData?.created_at 
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(new Date(userData.created_at))
                            : "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Last Seen:</span>
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {userData?.updated_at 
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }).format(new Date(userData.last_seen_at))
                            : "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Account Type:</span>
                      <span className="text-[rgb(var(--global-color-primary))] lowercase">
                        {userData?.account_type_id || "N/A"}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      <span className="font-semibold">Status:</span>
                      <span className="text-[rgb(var(--global-color-primary))] lowercase">
                      {userData?.is_approved}
                      </span>
                    </div>

                </div>
            </div>

          {/* Tab Header */}
          
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            indicatorColor="primary"
            sx={{
                backgroundColor: "#191919", 
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                "& .MuiTab-root": {
                  fontFamily: "Urbanist, sans-serif", 
                  textTransform: "none", 
                  fontSize: "16px", 
                  fontWeight: "500", 
                  color: "white",
                  minWidth: "150px",
                  margin: "0 10px",
                  "&:hover": {
                    color: "rgba(255, 255, 255, 0.7)", 
                  },
                },
                "& .Mui-selected": {
                  color: "rgb(var(--global-color-primary)) !important", 
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "rgb(var(--global-color-primary))", 
                  height: "4px", 
                  borderRadius: "2px", 
                },
              }}
          >
            <Tab label="Company Details" />
            <Tab label="Account Information" />
            <Tab label="Billing Details" />
            <Tab label="User Activity" />
            <Tab label="Company Member" />
            <Tab label="Bookmarks" />
            <Tab label="Payment" />
          </Tabs>
  
          {/* Tab Content */}
          <div className="mt-5">
            {activeTab === 0 && <UserDetails companyId={companyId}/>}
            {activeTab === 1 && <AccountInformation  userId={userId} companyId={companyId}/>}
            {activeTab === 2 && <BillingDetails  userId={userId} companyId={companyId}/>}
            {activeTab === 3 && <UserActivity userId={userId}/>}
            {activeTab === 4 && <CompanyMember companyId={companyId}/>}
            {activeTab === 5 && <UserBookMarks userId={userId} companyId={companyId}/>}
          </div>
        </div>
      )}
    </div>
  );
  
};

export default StartupAdmin;
