import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

function FlexibleDataFetcher({ endpoint, params = {}, onSuccess, onError, queryToken }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const requestInProgress = useRef(false);

  useEffect(() => {
    if (requestInProgress.current) return;

    const fetchData = async () => {
      try {
        requestInProgress.current = true; // Lock per evitare richieste multiple
        setLoading(true);
        setError('');

        // Token dal localStorage
        const token = queryToken || localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trovato. Effettua il login.');
        }

        // Combino i parametri con l'eventuale token in query string
        const combinedParams = {
          ...params,
          ...(queryToken ? { token: queryToken } : {})
        };    

        // Eseguo la chiamata con Axios
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/${endpoint}`,
          {
            params: combinedParams,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        // Notifico il genitore via onSuccess
        if (onSuccess) {
          onSuccess(response.data);
        }
        setLoading(false);
      } catch (err) {
        setError('Errore durante il recupero dei dati o autenticazione fallita (401)');
        console.error('Errore:', err);
        if (onError) {
          onError(err);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [endpoint, params, onSuccess, onError, queryToken]);

  return null;
}

export default FlexibleDataFetcher;
