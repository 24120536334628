import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Startups from './pages/startups';
import Startup from './pages/startup';
import Blog from './pages/blog';
import ArticlePage from './components/post';
import './global.css';
import Chats from './pages/chats';
import ChatsAdmin from './pages/chatsAdmin';
import AccountInformation from './pages/accountInformations';
import CompanyDetails from './pages/companyDetails';
import BillingDetails from './pages/billingDetails';
import Dashboard from './pages/dashboard';
import StartupList from './pages/startupList';
import StartupAnalysis from './pages/startupAnalysis';
import Auth from './components/auth';
import UserList from './pages/userList';
import ExpoRivaCompetition from './pages/expoRivaCompetitionSubmition';
import CompanyMembers from './pages/companyMembers';
import Articles from './pages/articles';
import InboxMessages from './pages/inboxMessages';
import ManageKeywords from './pages/manageKeywords';
import ManageTags from './pages/manageTags';
import RolesAndPermissions from './pages/rolesAndPermissions';
import RetailhubTeam from './pages/retailhubTeam';
import PlanTypes from './pages/planTypes';
import Prices from './pages/prices';
import PaymentPlans from './pages/paymentPlans';
import PaymentReceipts from './pages/paymentReceipts';
import BookmarksStartups from './pages/bookmarksStartups'; 
import Advisory from './pages/advisory';
import ProtectedRoute from './components/protectedRoute';
import ProtectedHome from './components/protectedHome'; 
import Layout from './components/layout'; 
import Survey from './pages/surveys';
import SurveyChat from './pages/surveyChat';
import LanderSurvey from './pages/landerSurvey';
import StartupAdmin from './pages/startupAdmin';
import UserAdmin from './pages/userAdmin'
import Register from './pages/register'
import RegisterPublic from './pages/registerPublic'
import AnalysisConsultant from './pages/analysisConsultant'
import StartupPublic from './pages/startupPublic';
import LostPassword from './pages/lostPassword';
import StartupEdit from './pages/startupEdit';
import Home from './pages/home';
import AddStartupAdmin from './pages/addStartupAdmin';

function App({ startup }) {

  // Funzione per decidere il componente per /chats
  const ChatComponent = startup === "startup" ? ChatsAdmin : Chats;
  const innovationId = localStorage.getItem("innovation");
  
  return (
    <Router>
      <Layout>
        <Routes>
        <Route path="/" element={
            <ProtectedRoute requiredPermissions={['access.innovations']}>
              <Home />
            </ProtectedRoute>
          } />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/startups" element={
            <ProtectedRoute requiredPermissions={['access.innovations']}>
              <Startups />
            </ProtectedRoute>
          } />
          <Route path="/startupList" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <StartupList />
            </ProtectedRoute>
          } />
          <Route path="/userList" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <UserList />
            </ProtectedRoute>
          } />
          <Route path="/expoRivaCompetition" element={
            <ProtectedRoute>
              <ExpoRivaCompetition />
            </ProtectedRoute>
          } />
          <Route path="/startupAnalysis" element={
            <ProtectedRoute>
              <StartupAnalysis />
            </ProtectedRoute>
          } />
          <Route path="/startups/startup/:id" element={
            <ProtectedRoute>
              <Startup />
            </ProtectedRoute>
          } />
          <Route path="/chats/:id?/:name?" element={
            <ProtectedRoute requiredPermissions={['access.chat']}>
              <ChatComponent />
            </ProtectedRoute>
          } />
          <Route path="/chatsAdmin" element={
            <ProtectedRoute allowedRoles={['admin', 'user_innovation']}>
              <ChatsAdmin />
            </ProtectedRoute>
          } />
          <Route path="/account" element={
            <ProtectedRoute>
              <AccountInformation />
            </ProtectedRoute>
          } />
          <Route path="/companyDetails" element={
            <ProtectedRoute>
              <CompanyDetails />
            </ProtectedRoute>
          } />
          <Route path="/companyMembers" element={
            <ProtectedRoute requiredPermissions={['access.company_member']}>
              <CompanyMembers />
            </ProtectedRoute>
          } />
          <Route path="/billingDetails" element={
            <ProtectedRoute>
              <BillingDetails />
            </ProtectedRoute>
          } />
          <Route path="/blog" element={
          <ProtectedRoute>
              <Blog />
            </ProtectedRoute>
          } />
          <Route path="/articles" element={
            <ProtectedRoute>
              <Articles />
            </ProtectedRoute>
          } />
          <Route path="/inboxMessages" element={
            <ProtectedRoute>
              <InboxMessages />
            </ProtectedRoute>
          } />
          <Route path="/manageKeywords" element={
            <ProtectedRoute>
              <ManageKeywords />
            </ProtectedRoute>
          } />
          <Route path="/manageTags" element={
            <ProtectedRoute>
              <ManageTags />
            </ProtectedRoute>
          } />
          <Route path="/rolesAndPermissions" element={
            <ProtectedRoute>
              <RolesAndPermissions />
            </ProtectedRoute>
          } />
          <Route path="/retailhubTeam" element={
            <ProtectedRoute>
              <RetailhubTeam />
            </ProtectedRoute>
          } />
          <Route path="/planTypes" element={
            <ProtectedRoute>
              <PlanTypes />
            </ProtectedRoute>
          } />
          <Route path="/prices" element={
            <ProtectedRoute>
              <Prices />
            </ProtectedRoute>
          } />
          <Route path="/paymentPlans" element={
            <ProtectedRoute>
              <PaymentPlans />
            </ProtectedRoute>
          } />
          <Route path="/paymentReceipts" element={
            <ProtectedRoute>
              <PaymentReceipts />
            </ProtectedRoute>
          } />
          <Route path="/bookmarks-startups" element={
            <ProtectedRoute requiredPermissions={['access.innovations']}>
              <BookmarksStartups />
            </ProtectedRoute>
          } />
          <Route path="/advisory" element={
            <ProtectedRoute requiredPermissions={['access.advisory_program']}>
              <Advisory />
            </ProtectedRoute>
          } />
          <Route path="/survey" element={
            <ProtectedRoute requiredPermissions={['access.advisory_program']}>
              <Survey />
            </ProtectedRoute>
          } />
          <Route path="/survey/chat" element={
            <ProtectedRoute>
              <SurveyChat />
            </ProtectedRoute>
          } />
          <Route path="/startupList/:id" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <StartupAdmin />
            </ProtectedRoute>
          } />
          <Route path="/user/:id" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <UserAdmin />
            </ProtectedRoute>
          } />
          <Route path="/analysis" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AnalysisConsultant />
            </ProtectedRoute>
          } />
          <Route path="/add-startup" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AddStartupAdmin />
            </ProtectedRoute>
          } />
          <Route path="/startupedit/:id" element={
            <ProtectedRoute allowedRoles={['admin', 'user_innovation']} innovationId={innovationId}>
              <StartupEdit />
            </ProtectedRoute>
          } />

          {/* Rotta per la pagina dell'articolo */}
          <Route path="/article/:id" element={
            <ProtectedRoute publicPage={true}>
              <ArticlePage />
            </ProtectedRoute>
          } />
          
          {/* Rotta per ottenere il token */}
          <Route path="/auth" element={
            <ProtectedRoute publicPage={true}>
              <Auth />
            </ProtectedRoute>
          } />

          {/* Rotte non protette */}
          <Route path="/register-invite" element={
            <ProtectedRoute publicPage={true}>
              <Register />
            </ProtectedRoute>
          } />
          <Route path="/register" element={
            <ProtectedRoute publicPage={true}>
              <RegisterPublic />
            </ProtectedRoute>
          } />
          <Route path="/lost-password" element={
            <ProtectedRoute publicPage={true}>
              <LostPassword />
            </ProtectedRoute>
          } />
          <Route path="/lander-survey" element={
            <ProtectedRoute publicPage={true}>
              <LanderSurvey />
            </ProtectedRoute>
          } />
          <Route path="/startup/startup-public/:id" element={
            <ProtectedRoute publicPage={true}>
              <StartupPublic />
            </ProtectedRoute>
          } />


        </Routes>
      </Layout>
    </Router>
  );
}

export default App;