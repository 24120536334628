import React, { useState, useRef, useEffect } from 'react';
import { List, ListItem, ListItemText, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function AccountMenu({ accountImage, initials, canAccessCompanyMembers }) {
  const [accountOpen, setAccountOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleAccountMenu = () => setAccountOpen((prev) => !prev);

  // Chiudi il menu cliccando fuori
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setAccountOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box ref={menuRef} sx={{ position: 'relative', display: 'inline-block' }}>
      {/* Immagine utente */}
      {/* Se c'è un'immagine, la mostriamo, altrimenti mostriamo le iniziali */}
      {accountImage ? (
        <Box
          component="img"
          src={accountImage}
          alt="Account"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            cursor: 'pointer',
            '&:hover': { opacity: 0.8 },
          }}
          onClick={toggleAccountMenu}
        />
      ) : (
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: '#C5FF55', // Sfondo giallo chiaro
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#191919', // Testo scuro per contrasto
            cursor: 'pointer',
            '&:hover': { opacity: 0.8 },
          }}
          onClick={toggleAccountMenu}
        >
          {initials}
        </Box>
      )}
      {accountOpen && (
        <>
          {/* Freccia triangolare */}
          <Box
            sx={{
              position: 'absolute',
              top: '100%',
              right: 10,
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '10px solid #191919',
              zIndex: 10,
            }}
          />
          {/* Menu a tendina */}
          <Box
            sx={{
              position: 'absolute',
              top: 'calc(100% + 10px)',
              right: 0,
              zIndex: 10,
              background: '#191919',
              //boxShadow: '0px 0px 2px rgba(255, 255, 255, 0.8)',
              borderRadius: '10px',
              minWidth: 200,
              overflow: 'hidden',
            }}
          >
            <List component="nav" disablePadding>
              {[
                { label: 'Account Information', link: '/account' },
                { label: 'Company Details', link: '/companyDetails' },
                { label: 'Billing Details', link: '/billingDetails' },
                canAccessCompanyMembers && { label: 'Company Members', link: '/companyMembers' },
              ].filter(Boolean).map((item, index) => (
                <ListItem
                  button
                  component={Link}
                  to={item.link}
                  key={index}
                  sx={{
                    fontFamily: 'Urbanist, sans-serif',
                    '&:hover': {
                      //backgroundColor: '#c5ff55',
                      color: "#c5ff55",
                      textDecoration: 'none',
                    },
                  }}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AccountMenu;
