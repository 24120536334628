import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Input, Typography } from "@mui/material";
import axios from "axios";

const UploadLogoDialog = ({ open, handleClose, innovationId, onLogoUpdated }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/innovations/${innovationId}/upload-logo`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Logo updated successfully!");
      onLogoUpdated(); // Aggiorna i dati nel componente padre
      handleClose();
    } catch (error) {
      console.error("Error uploading logo:", error);
      alert("Failed to upload logo.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "#191919", color: "white" }}>
        Upload New Logo
      </DialogTitle>
      <Typography sx={{ backgroundColor: "#191919", padding: "5px 24px", color: "white" }}>Logo must be 1:1</Typography>
      <DialogContent sx={{ backgroundColor: "#191919", padding: "24px", color: "white" }}>
        <Input type="file" onChange={handleFileChange} fullWidth sx={{ color: "white" }} />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px" }}>
        <Button onClick={handleClose} sx={{ color: "white", fontWeight: "bold" }}>
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadLogoDialog;
