import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Alert,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";

const theme = createTheme({
    typography: {
        fontFamily: "Urbanist, Arial, sans-serif",
    },
    palette: {
        primary: {
            main: "#c5ff55",
        },
    },
});

const API_URL = process.env.REACT_APP_API_URL;

export default function ForgotPassword() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [token, setToken] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const urlToken = params.get("token");
        console.log("token");
        if (urlToken) {
            setToken(urlToken);
            setStep(2);
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEmailSubmit = async () => {
        if (!formData.email.trim()) {
            setError("Please enter a valid email.");
            return;
        }
        setError("");
        try {
            await axios.post(`${API_URL}/request-forgot-password`, { email: formData.email });
            setMessage("We have sent a reset link to your email. Please check your inbox.");
        } catch (error) {
            setError("Failed to send reset email. Please try again.");
        }
    };

    const handleResetPassword = async () => {
        if (!formData.password || formData.password !== formData.confirmPassword) {
            setError("Passwords must match.");
            return;
        }

        setError("");
        try {
            await axios.post(`${API_URL}/change-password-by-email?token=${token}`, {
                password: formData.password,
                password_confirmation: formData.confirmPassword,
            });

            setMessage("Your password has been reset successfully. You can now log in.");
            setStep(3);
        } catch (error) {
            setError("Failed to reset password. Please try again.");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                maxWidth="sm"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100vh",
                    alignItems: "center",
                }}
            >
                <Box
                    component="form"
                    sx={{
                        width: "100%",
                        padding: 3,
                        backgroundColor: "#191919",
                        borderRadius: 2,
                        boxShadow: 3,
                        color: "#fff",
                    }}
                >
                    {step === 1 && (
                        <Box>
                            <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
                                Reset Your Password
                            </Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                type="email"
                                InputLabelProps={{ style: { color: "#fff" } }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": { borderColor: "#c5ff55" },
                                        "&:hover fieldset": { borderColor: "#c5ff55" },
                                    },
                                }}
                            />
                            {error && <Alert severity="error" sx={{ marginTop: 2 }}>{error}</Alert>}
                            {message && <Alert severity="success" sx={{ marginTop: 2 }}>{message}</Alert>}
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ marginTop: 2, backgroundColor: "#c5ff55", color: "#191919" }}
                                onClick={handleEmailSubmit}
                            >
                                Send Reset Link
                            </Button>
                        </Box>
                    )}

                    {step === 2 && (
                        <Box>
                            <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
                                Set a New Password
                            </Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="New Password"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                InputLabelProps={{ style: { color: "#fff" } }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": { borderColor: "#c5ff55" },
                                        "&:hover fieldset": { borderColor: "#c5ff55" },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                                InputLabelProps={{ style: { color: "#fff" } }}
                                sx={{
                                    input: { color: "#fff" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": { borderColor: "#c5ff55" },
                                        "&:hover fieldset": { borderColor: "#c5ff55" },
                                    },
                                }}
                            />
                            {error && <Alert severity="error" sx={{ marginTop: 2 }}>{error}</Alert>}
                            {message && <Alert severity="success" sx={{ marginTop: 2 }}>{message}</Alert>}
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ marginTop: 2, backgroundColor: "#c5ff55", color: "#191919" }}
                                onClick={handleResetPassword}
                            >
                                Reset Password
                            </Button>
                        </Box>
                    )}

                    {step === 3 && (
                        <Box>
                            <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
                                Password Reset Successfully
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: "center", marginBottom: 2 }}>
                                You can now log in with your new password.
                            </Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ backgroundColor: "#c5ff55", color: "#191919" }}
                                onClick={() => (window.location.href = "/login")}
                            >
                                Go to Login
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
}
