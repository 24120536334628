import React, { useState, useEffect } from "react";
import { Checkbox, Chip } from "@mui/material";
import StandardButton from "../standardButton";
import StandardTextfield from "../standardTextfield";
import StandardSelect from "../standardSelect";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; 
import DialogTags1 from "./dialogs/dialogTags2Front";

export default function AccountInformation({ userId, companyId }) {
  const [user, setUser] = useState({
    id: null,
    firstname: "",
    lastname: "",
    role: "",
    roleOptions: [],
    department: "",
    departmentOptions: [],
    position: "",
    image: "",
    phone: "",
    email: "",
    faEnabled: false,
    isAprroved: false,
  });
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [selectedPositionId, setSelectedPositionId] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");

  const [dialogTagsOpen, setDialogTagsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  // Esempio: se vuoi verificare ruoli in locale
  const isUserAdmin = localStorage.getItem("roleName") === "admin";

  // ---------------------------------------------------------
  // handleChange gestisce le modifiche dei campi di input
  // ---------------------------------------------------------
  const handleChange = (event, field) => {
    switch (field) {
      case "firstname":
        setFirstname(event.target.value);
        break;
      case "lastname":
        setLastname(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "department":
        setSelectedDepartmentId(event.target.value);
        break;
      case "position":
        setSelectedPositionId(event.target.value);
        break;
      case "role":
        setSelectedRoleId(event.target.value);
        break;
      default:
        break;
    }
  };

  // ---------------------------------------------------------
  // handleResetUser reimposta i campi allo stato originale
  // ---------------------------------------------------------
  const handleResetUser = () => {
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setPhone(user.phone);
    setSelectedDepartmentId(user.department);
    setSelectedPositionId(user.position);
    setSelectedRoleId(user.role);
  };

  // ---------------------------------------------------------
  // handleEditUser aggiorna i dati dell'utente (PATCH)
  // ---------------------------------------------------------
  const handleEditUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          first_name: firstname,
          last_name: lastname,
          phone_number: phone,
          department_id: selectedDepartmentId,
          position_id: selectedPositionId,
          tags: selectedTags.map((tag) => tag.id),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // se tutto va bene, potresti voler rifare il fetch per sincronizzare
      // fetchDatas();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  // fetchDatas ottiene tutti i dati necessari da vari endpoint
  // ---------------------------------------------------------
  const fetchDatas = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      // Esempio di Promise.all() per chiamare in parallelo:
      const [
        userResponse,
        membersResponse,
        departmentResponse,
        positionsResponse,
        rolesResponse
      ] = await Promise.all([
        // 1) Chiamata a /users/:userId
        axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),

        // 2) Chiamata a /members?company_id=xx&user_id=yy
        axios.get(
          `${process.env.REACT_APP_API_URL}/members?company_id=${companyId}&user_id=${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        ),

        // 3) Lista dipartimenti
        axios.get(`${process.env.REACT_APP_API_URL}/values/departments`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),

        // 4) Lista posizioni
        axios.get(`${process.env.REACT_APP_API_URL}/values/positions`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),

        // 5) Lista ruoli (se necessario)
        axios.get(`${process.env.REACT_APP_API_URL}/values/roles`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      // Salviamo liste di dipartimenti, posizioni e ruoli negli state
      setDepartments(departmentResponse.data.data);
      setPositions(positionsResponse.data.data);
      setRoles(rolesResponse.data.data);

      // Estraggo i dati di user
      const userData = userResponse.data; 
      // Estraggo i dati di member
      const memberData = membersResponse.data.data[0];

      // setUser() per popolarti lo stato base
      setUser((prevUser) => ({
        ...prevUser,
        id: userData.id,
        firstname: userData.first_name,
        lastname: userData.last_name,
        phone: userData.phone_number,
        email: userData.email,
        // Il JSON dice "is_enabled_2fa": false => mappalo su faEnabled
        faEnabled: userData.is_enabled_2fa,
        // Aggiungiamo i campi dipartment e position
        department: memberData?.member_details?.department_id || "",
        position: memberData?.member_details?.position_id || "",
        isApproved: userData.is_approved,
      }));

      // Popoliamo i vari state dei campi input
      setFirstname(userData.first_name);
      setLastname(userData.last_name);
      setPhone(userData.phone_number);

      // Preselezioniamo i valori di dipartimento e posizione dalle info di member
      setSelectedDepartmentId(memberData?.member_details?.department_id || "");
      setSelectedPositionId(memberData?.member_details?.position_id || "");
      // Se usi role, prendi member_role_id
      setSelectedRoleId(memberData?.member_details?.member_role_id || "");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  // useEffect che richiama fetchDatas al montaggio
  // ---------------------------------------------------------
  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line
  }, []);

  // ---------------------------------------------------------
  // handlePhoneChange per gestire PhoneInput
  // ---------------------------------------------------------
  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  // Gestione del salvataggio dei tag
  const handleSaveTags = (tags) => {
    setSelectedTags(tags); // Aggiorna lo stato con i nuovi tag selezionati
    console.log("Tags saved:", tags); // IDs e nomi
  };

  const handleApproveUser = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/users/${userId}/approve`,
        {}, // Nessun body richiesto
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Aggiornare lo stato dopo l'approvazione
      setUser((prevUser) => ({
        ...prevUser,
        isApproved: true,
      }));
    } catch (error) {
      console.error("Error approving user:", error);
    }
  };
  

  return (
    <React.Fragment>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className="flex flex-col gap-8 w-full">
            <div className="flex flex-row items-center justify-between mb-5">
            <h1 className="text-4xl font-medium mb-0">Account Information</h1>
            {/* Mostra il pulsante solo se l'utente NON è ancora approvato */}
              {!user.isApproved && (
                <StandardButton
                  variant="filled"
                  text="Approve User"
                  onClick={handleApproveUser}
                  width="180px"
                  className="bg-green-500 text-white"
                />
              )}
            </div>
            <div className="grid grid-cols-2 gap-x-12 gap-y-6">
              <StandardTextfield
                label="First Name"
                value={firstname}
                onChange={(e) => {
                  handleChange(e, "firstname");
                }}
              />
              <StandardTextfield
                label="Last Name"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />

              <StandardSelect
                label="Position"
                value={selectedPositionId}
                items={positions}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "position");
                }}
              />
              <StandardSelect
                label="Department"
                value={selectedDepartmentId}
                items={departments}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "department");
                }}
              />

              {/* Telefono */}
              <div className="relative w-full">
                <PhoneInput
                  country={"us"} // o "it", se vuoi cambiare default
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Phone"
                  inputStyle={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    borderRadius: "5px",
                    color: "white",
                    width: "100%",
                    fontFamily: "Urbanist, sans-serif",
                    fontWeight: "500",
                    fontSize: "16px",
                    paddingLeft: "50px", // Per lasciare spazio alla bandierina
                    height: "56px",
                  }}
                  buttonStyle={{
                    backgroundColor: "transparent",
                    border: "none",
                    width: "50px", // Uniforme con il padding del testo
                    borderRadius: "5px 0 0 5px",
                  }}
                  dropdownStyle={{
                    backgroundColor: "#333",
                    color: "white",
                  }}
                />
              </div>

              <StandardTextfield 
                label="Email"
                value={user.email}
                // Se vuoi renderlo modificabile:
                // onChange={(e) => setUser({ ...user, email: e.target.value })}
                // Altrimenti lo lasci come read-only
              />
            </div>

            <div className="flex flex-col gap-4">
                {/* Chip per i tag selezionati */}
                <div className="flex flex-wrap gap-2">
                  {selectedTags.map((tag) => (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      sx={{
                        backgroundColor: "rgb(var(--global-color-primary))",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
                <StandardButton
                  text="Edit Tags"
                  onClick={() => setDialogTagsOpen(true)}
                  width="150px"
                />
            </div>

            <div className="flex items-center gap-4 mt-4">
              <Checkbox
                checked={user.faEnabled}
                onChange={(e) => {
                  // Se vuoi gestire l'abilitazione 2FA in real-time
                  setUser({ ...user, faEnabled: e.target.checked });
                }}
                sx={{ color: "rgb(var(--global-color-primary))" }}
              />
              <span className="text-white">Enable 2FA</span>
            </div>

            <div className="flex justify-end mt-8 gap-4">
              <StandardButton
                variant="outlined"
                text="Reset"
                width="200px"
                onClick={handleResetUser}
              />
              <StandardButton
                variant="filled"
                text="Save"
                width="200px"
                onClick={handleEditUser}
              />
            </div>
          </div>
          
        </div>
      )}

      {/* Dialog per i tag */}
      <DialogTags1
        open={dialogTagsOpen}
        handleClose={() => setDialogTagsOpen(false)}
        onSave={handleSaveTags}
        selectedTags={selectedTags}
      />
    </React.Fragment>
  );
}
