import React, { useState, useEffect } from "react";
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PaginatedTable from "../paginatedTable";
import StandardButton from "../standardButton";
import StandardTextfield from "../standardTextfield";
import StandardSelect from "../standardSelect";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; 
import EditIcon from '@mui/icons-material/Edit'; 
import FlexibleDataFetcher from "../flexibileDataFetcher";
import EditPlanBilling from "../adminStartupDetails/dialogs/editPlanBilling";
import NewPlanBilling from "../adminStartupDetails/dialogs/newPlanBilling";

export default function BillingDetails({companyId, userId}) {
  const [user, setUser] = useState({
    id: null,
    firstname: "",
    lastname: "",
    role: "",
    roleOptions: [],
    department: "",
    departmentOptions: [],
    image: "",
    phone: "",
    email: "",
    faEnabled: false,
  });
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [selectedPositionId, setSelectedPositionId] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const isUserAdmin = localStorage.getItem("roleName") === "admin";
  const [planInfo, setPlanInfo] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [error, setError] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isEditPlanOpen, setIsEditPlanOpen] = useState(false);

  const handleOpenEditPlanDialog = () => setIsEditPlanOpen(true);
  const handleCloseEditPlanDialog = () => setIsEditPlanOpen(false);

  const [subscriptions, setSubscriptions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 15;

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriptions`, {
          params: { page, per_page: perPage, user_id: userId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
  
        setSubscriptions(response.data.data);
        setTotalPages(response.data.last_page);
      } catch (error) {
        console.error("Errore nel recupero delle sottoscrizioni:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchSubscriptions();
  }, [userId, page]);
  

  const formatDate = (isoString) => {
    if (!isoString) return "N/A";
    return new Date(isoString).toLocaleDateString("it-IT", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const header = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "plan_name", headerName: "Piano", width: 200 },
    { field: "created_at", headerName: "Creato il", width: 180 },
    { field: "starts_at", headerName: "Inizio", width: 180 },
    { field: "ends_at", headerName: "Scadenza", width: 180 },
  ];

  const rows = subscriptions.map((sub) => ({
    id: sub.id,
    plan_name: sub.plan?.name || "N/A",
    created_at: formatDate(sub.created_at),
    starts_at: formatDate(sub.starts_at),
    ends_at: formatDate(sub.ends_at),
  }));
  
  
  const handleChange = (event, field) => {
    switch (field) {
      case "firstname":
        setFirstname(event.target.value);
        break;
      case "lastname":
        setLastname(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "department":
        setSelectedDepartmentId(event.target.value);
        break;
      case "position":
        setSelectedPositionId(event.target.value);
        break;
      case "role":
        setSelectedRoleId(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleResetUser = () => {
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setPhone(user.phone);
    setSelectedDepartmentId(user.department);
    setSelectedPositionId(user.position);
    setSelectedRoleId(user.role);
  };

  const handleEditUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          first_name: firstname,
          last_name: lastname,
          phone_number: phone,
          department_id: selectedDepartmentId,
          position_id: selectedPositionId,
          tags: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleSubscriptionFetch = (data) => {
    console.log("Dati ricevuti:", data.data[0]);
    setPlanInfo(data.data[0])
    setPlanId(data.data[0].plan.id)
    setLoading(false);
  };

  const handleSubscriptionError = (err) => {
    setError(err)
    console.log(err)
  }

  const handlePlanFetch = (data) => {
    //console.log("Dati ricevuti:", data);
    setPlanDetails(data)
  };

  const handlePlanError = (err) => {
    setError(err)
    //console.log(err)
  }

  const refreshParent = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleOpenConfirmDialog = () => {
    setIsDialogOpen(true);
  };
  
  const handleCloseConfirmDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOpenNewPlanDialog = () => {
    setIsDialogOpen(true);
  };
  

  return (
    <React.Fragment>      
        <div className="flex flex-col gap-8 w-full">
            <h1 className="text-4xl font-medium mb-0">Billing Address</h1>
            <div className="grid grid-cols-2 gap-x-12 gap-y-6">
              <StandardTextfield
                label="Legal Name"
                value={firstname}
                onChange={(e) => {
                  handleChange(e, "firstname");
                }}
              />
              <StandardSelect
                label="Country"
                value={selectedPositionId}
                items={positions}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  handleChange(e, "position");
                }}
              />
              <StandardTextfield
                label="City"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />              
              <StandardTextfield
                label="Address"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />  
              <StandardTextfield
                label="VAT Number"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />
              <StandardTextfield
                label="Post ZIP Code"
                value={lastname}
                onChange={(e) => {
                  handleChange(e, "lastname");
                }}
              />
            </div>

            <div className="grid ">
                <div className="flex flex-row items-center gap-2 mb-5">
                    <h2 className="text-4xl font-semibold m-0">Active Subscription</h2>
                    {/*
                    <EditIcon
                        sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgb(var(--global-color-primary))' }}
                        onClick={handleOpenEditPlanDialog}
                    />
                    */}
                </div>

                <div className="flex flex-col gap-2 ">
                    <p><strong>Current Plan:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize">{planDetails?.name}</span></p>
                    <p><strong>Next Billing Date: </strong> 
                      <span className="text-[rgb(var(--global-color-primary))] capitalize"> 
                        {planInfo?.ends_at 
                          ? new Date(planInfo.ends_at).toLocaleDateString("en-EN", {
                              year: "numeric",
                              month: "long",
                              day: "numeric"
                            }) 
                          : "N/A"
                        }
                      </span>
                    </p>
                    <p><strong>Plan Interval:</strong> <span className="text-[rgb(var(--global-color-primary))] capitalize">{planDetails?.duration}</span></p>
                    <p><strong>Amount: </strong> <span className="text-[rgb(var(--global-color-primary))] capitalize">{planDetails?.price} €</span></p>
                    <p><strong>Plan Expiry Date: </strong> 
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                          {planInfo?.ends_at 
                            ? new Date(planInfo.ends_at).toLocaleDateString("en-EN", {
                              year: "numeric",
                              month: "long",
                              day: "numeric"
                            }) 
                            : "N/A"
                          }
                      </span>
                    </p>
                    <p><strong>Is Trial Period? </strong> 
                      <span className="text-[rgb(var(--global-color-primary))] capitalize">
                        {planInfo?.is_trial ? "Yes" : "No"}
                      </span>
                    </p>

                    <div className="flex flex-row gap-2 ">
                        <StandardButton
                          variant="outlined"
                          onClick={handleOpenConfirmDialog}
                          text="Update Subscription"
                          width="25%"
                        />

                        <StandardButton
                          onClick={handleOpenNewPlanDialog} 
                          text="Add Subscription"
                          width="25%"
                        />
                    </div>
                </div>

                

            </div>

            <div className="flex flex-col gap-2 ">
                <h2 className="text-4xl font-semibold mb-5">Subscription List</h2>
                <div className="flex flex-col w-full">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <PaginatedTable
                        items={rows}
                        header={header} 
                        hideFooterPagination={true}
                      />
                    )}
                  </div>
                  
                  <div className="flex justify-center mt-4">
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={(event, value) => setPage(value)}
                      color="primary"
                    />
                  </div>
            </div>
        </div>

        <FlexibleDataFetcher
          key={refreshKey}
          endpoint="subscriptions"
          params={{ company_id: companyId, user_id: userId }}
          onSuccess={handleSubscriptionFetch}
          onError={handleSubscriptionError}
        />

        {planId && (
          <FlexibleDataFetcher
            key={refreshKey}
            endpoint={`plans/${planId}`}
            onSuccess={handlePlanFetch}
            onError={handlePlanError}
          />
        )}

        {planId && (
          <EditPlanBilling
            subId = {planInfo.id}
            open={isDialogOpen}
            handleClose={handleCloseConfirmDialog}                 
            refreshParent={refreshParent}
          />
        )}


          <NewPlanBilling
            userId = {userId}
            company_id={companyId}
            open={isDialogOpen}
            handleClose={handleCloseConfirmDialog}                 
            refreshParent={refreshParent}
          />
        

    </React.Fragment>
  );
}
