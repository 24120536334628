import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';
import axios from 'axios';

export default function EditAdvisoryDialog({ advisory, onAdvisoryUpdated, open, onClose }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startupsSelected, setStartupsSelected] = useState([]);
  const [startupsOptions, setStartupsOptions] = useState([]);
  const [loadingStartups, setLoadingStartups] = useState(true);
  const [error, setError] = useState(null);

  const companyId = localStorage.getItem('companyId');
  const token = localStorage.getItem('token');

  // Aggiorna lo stato ogni volta che cambia l'advisory
  useEffect(() => {
    if (advisory) {
      setName(advisory.title || "");
      setDescription(advisory.description || "");
      setStartupsSelected(advisory.innovations.map(i => i.id) || []);
    }
  }, [advisory]);

  useEffect(() => {
    // Funzione per recuperare le startup dall'API
    const fetchStartups = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/advisory/results/answers-count-by-innovation`, {
          params: {
            company_id: companyId
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const startupsData = response.data;
        const options = startupsData.map(startup => ({
          id: parseInt(startup.innovation_id),
          name: startup.name
        }));
        setStartupsOptions(options);
        setLoadingStartups(false);
      } catch (err) {
        console.error('Errore nel recupero delle startup:', err);
        setError('Errore nel recupero delle startup');
        setLoadingStartups(false);
      }
    };

    fetchStartups();
  }, [companyId, token]);

  // Gestione dei campi modificati
  const handleChange = (event, field) => {
    switch (field) {
      case 'name':
        setName(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'startups':
        const selectedIds = event.target.value;
        setStartupsSelected(selectedIds);
        break;
      default:
        break;
    }
  };

  // Gestione del submit della modifica
  const handleSubmit = async () => {
    const data = {
      title: name,
      description: description,
      innovations_ids: startupsSelected.map(id => parseInt(id))
    };
  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/advisory/analyses/${advisory.id}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      console.log('Advisory modificata con successo:', response.data);
  
      // Passa i dati aggiornati alla funzione di callback
      if (onAdvisoryUpdated) {
        onAdvisoryUpdated(response.data); // Invia i dati aggiornati al parent
      }
  
      // Chiudi il dialog
      onClose();
    } catch (err) {
      console.error('Errore durante l\'aggiornamento dell\'advisory:', err);
    }
  };
  

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: "16px !important",
          overflow: 'hidden',
          backgroundColor: "#191919"
        }
      }}
    >
      <DialogContent sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919" }}>
        <div className='flex flex-col gap-10 justify-center items-center'>
          <h1 className="text-6xl">Edit Advisory</h1>
          {loadingStartups ? (
            <p>Loading startups...</p>
          ) : error ? (
            <p style={{ color: 'red' }}>{error}</p>
          ) : (
            <div className='flex flex-col gap-4 w-full'>
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Name' value={name} onChange={(e) => { handleChange(e, 'name') }} />
                <StandardSelect
                  items={startupsOptions}
                  value={startupsSelected}
                  label={"Startups"}
                  multiple={true}
                  onChange={(e) => { handleChange(e, 'startups') }}
                  optionValue="id"
                  optionLabel="name"
                  id="Test"
                />
              </div>
              <StandardTextfield label='Description' value={description} onChange={(e) => { handleChange(e, 'description') }} />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px' }}>
        <StandardButton variant='outlined' onClick={onClose} text="Cancel" width={"300px"} type={"secondary"} />
        <StandardButton onClick={handleSubmit} autoFocus={true} text="Submit" width={"300px"} />
      </DialogActions>
    </Dialog>
  );
}
