import React, { useState, useEffect } from "react";
import klarnaLogo from '../assets/startups/klarna-logo.png';
import { Checkbox } from '@mui/material';
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";
import axios from "axios";
import DialogTags1 from "../components/adminStartupDetails/dialogs/dialogTags2Front";
import { Button, Chip } from "@mui/material";
import ChangePasswordDialog from "../components/changePassword";

export default function AccountInformation() {
  const [user, setUser] = useState({
    id: null,
    firstname: '',
    lastname: '',
    role: '', // ID del ruolo selezionato
    roleOptions: [], // Lista dei ruoli disponibili
    department: '', // ID del dipartimento selezionato
    departmentOptions: [], // Lista dei dipartimenti disponibili
    image: klarnaLogo,
    phone: '',
    email: '',
    faEnabled: false
  });
  const [loading, setLoading] = useState(true); // Stato di caricamento
  const [positions, setPositions] = useState([]); 
  const [departments, setDepartments] = useState([]); 
  const [roles, setRoles] = useState([]); 
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0); 
  const [selectedPositionId, setSelectedPositionId] = useState(0); 
  const [selectedRoleId, setSelectedRoleId] = useState(0); 
  const [firstname, setFirstname] = useState(""); 
  const [lastname, setLastname] = useState(""); 
  const [phone, setPhone] = useState(""); 
  let departmentList = []
  let positionList = []
  let roleList = []
  const isUserAdmin = localStorage.getItem('roleName') === 'admin' ? true : false
  const [dialogTagsOpen, setDialogTagsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  
  const storedInnovation = localStorage.getItem("roleName");
  const isStartup = storedInnovation === "user_innovation";


  // Gestisce il toggle per 2FA
  // const handleFAToggle = () => {
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     faEnabled: !prevUser.faEnabled
  //   }));
  // };

  const handleChange = (event, field) => {
    switch (field) {
      case 'firstname':
        setFirstname(event.target.value);
        break;
      case 'lastname':
        setLastname(event.target.value);
        break;
      case 'phone':
        setPhone(event.target.value);
        break;
      case 'department':
        setSelectedDepartmentId(event.target.value)
        break;
      case 'position':
        setSelectedPositionId(event.target.value)
        break;
      case 'role':
        setSelectedRoleId(event.target.value)
        break;
      default:
        break;
    }
  };

  const handleResetUser = () => {
    setFirstname(user.firstname)
    setLastname(user.lastname)
    setPhone(user.phone)
    setSelectedDepartmentId(user.department)
    setSelectedPositionId(user.position)
    setSelectedRoleId(user.role)
  }

  const handleEditUser = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem('token')
      const response = axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          first_name: firstname,
          last_name: lastname,
          phone_number: phone,
          ...(!isStartup && { department_id: selectedDepartmentId }),
          ...(!isStartup && { position_id: selectedPositionId }),
          ...(!isStartup && { tags: selectedTags.map((tag) => tag.id) }),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Se necessario
          }
        }
      )
    } catch(err) {
      console.log(err)
    } finally {
      alert("User saved succesfully!");
      setLoading(false)
    }
  }

  const fetchDatas = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const urlUser = `${process.env.REACT_APP_API_URL}/user`;
      const urlDepartments = `${process.env.REACT_APP_API_URL}/values/departments`;
      const urlPositions = `${process.env.REACT_APP_API_URL}/values/positions`;
      const urlRoles = `${process.env.REACT_APP_API_URL}/values/roles`;
  
      const response = await axios.get(urlUser, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Se necessario
        },
      });
  
      const departmentResponse = await axios.get(urlDepartments, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Se necessario
        },
      });
  
      const positionsResponse = await axios.get(urlPositions, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Se necessario
        },
      });
  
      const rolesResponse = await axios.get(urlRoles, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Se necessario
        },
      });
  
      setDepartments(departmentResponse.data.data);
      departmentList = departmentResponse.data.data;
      setPositions(positionsResponse.data.data);
      positionList = positionsResponse.data.data;
      setRoles(rolesResponse.data.data);
      roleList = rolesResponse.data.data;
  
      console.log(response.data);
  
      // Inizializza lo stato utente
      setUser((prevUser) => ({
        ...prevUser,
        id: response.data.id || null,
        firstname: response.data.first_name || "",
        lastname: response.data.last_name || "",
        phone: response.data.phone_number || "",
        email: response.data.email || "",
        role: response.data.companies && response.data.companies[0] 
          ? response.data.companies[0].member_details?.role?.id 
          : undefined,
        roleOptions: roles || roleList,
        position: response.data.companies && response.data.companies[0] 
          ? response.data.companies[0].member_details?.position?.id 
          : undefined,
        positionOptions: positions || positionList,
        department: response.data.companies && response.data.companies[0] 
          ? response.data.companies[0].member_details?.department?.id 
          : "",
        departmentOptions: departments || departmentList,
        image: klarnaLogo,
        faEnabled: prevUser.faEnabled,
      }));      
  
      // Inizializza i valori dello stato per i tag
      setFirstname(response.data.first_name);
      setLastname(response.data.last_name);
      setPhone(response.data.phone_number);
      setSelectedDepartmentId(
        response.data.companies[0]?.member_details?.department?.id || undefined
      );
      setSelectedPositionId(
        response.data.companies[0]?.member_details?.position?.id || undefined
      );
      setSelectedRoleId(
        response.data.companies[0]?.member_details?.role?.id || undefined
      );
  
      // Inizializza i tag preselezionati
      setSelectedTags(response.data.tags_preference || []);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  

   // Gestione del salvataggio dei tag
   const handleSaveTags = (tags) => {
    setSelectedTags(tags); // Aggiorna lo stato con i nuovi tag selezionati
    console.log("Tags saved:", tags); // IDs e nomi
  };

  // Effetto per il debug dello stato utente
  useEffect(() => {
    fetchDatas()
    // console.log("User state updated:", user);
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="bg-black h-full min-h-[100vh] flex p-5">
          <div className="flex flex-col gap-4 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
            <h1 className="text-6xl font-medium">Account Information</h1>
            <StandardTextfield 
              label='Firstname' 
              value={firstname} 
              onChange={e => {handleChange(e, 'firstname')}}
            />
            <StandardTextfield 
              label='Lastname' 
              value={lastname} 
              onChange={e => {handleChange(e, 'lastname')}}
            />
            {/*
            <StandardSelect 
              label="Role" 
              value={selectedRoleId} 
              items={roles} 
              optionValue="id"
              optionLabel="name"
              onChange={e => {handleChange(e, 'role')}}
              disabled={!isUserAdmin}
            />
            */}
            {!isStartup && (
            <StandardSelect 
              label="Position" 
              value={selectedPositionId} 
              items={positions} 
              optionValue="id"
              optionLabel="name"
              onChange={e => {handleChange(e, 'position')}}
            />
            )}
            {!isStartup && (
            <StandardSelect 
              label="Department" 
              value={selectedDepartmentId} 
              items={departments} 
              optionValue="id"
              optionLabel="name"
              onChange={e => {handleChange(e, 'department')}}
            />
            )}
            <StandardTextfield 
              label='Phone' 
              value={phone} 
              onChange={e => {handleChange(e, 'phone')}}
            />
            <StandardTextfield 
              label='Email' 
              value={user.email} 
            />
            {!isStartup && (
              <div className="flex flex-col gap-4">
                {/* Chip per i tag selezionati */}
                <div className="flex flex-wrap gap-2">
                  {selectedTags.map((tag) => (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      sx={{
                        backgroundColor: "rgb(var(--global-color-primary))",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
                <StandardButton
                  text="Edit Tags"
                  onClick={() => setDialogTagsOpen(true)}
                  width="150px"
                />
              </div>
            )}
            <div className="flex flex-col gap-4 justify-center items-center">
              <span onClick={() => setPasswordDialogOpen(true)} style={{cursor: "pointer"}}><span className="text-[rgb(var(--global-color-primary))]">Change</span> the password</span>
              <div className="flex flex-wrap gap-5">
                <StandardButton variant="outlined" text="Reset" width="300px" onClick={handleResetUser}  />
                <StandardButton variant="filled" text="Save" width="300px" onClick={handleEditUser} />
              </div>
            </div>
          </div>
        </div>
      )}
      

        {/* Dialog per i tag */}
        <DialogTags1
          open={dialogTagsOpen}
          handleClose={() => setDialogTagsOpen(false)}
          onSave={handleSaveTags}
          selectedTags={selectedTags}
        />
    

      <ChangePasswordDialog 
        open={passwordDialogOpen} 
        handleClose={() => setPasswordDialogOpen(false)} 
      />

    </React.Fragment>
  );
}
