import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Stili predefiniti di React Quill

const DescriptionCompany = ({ open, handleClose, innovationId, startupData }) => {
  const [formData, setFormData] = useState({
    description: "",
    solutions: "",
  });

  // Precompila i campi con i dati esistenti quando si apre la dialog
  useEffect(() => {
    if (startupData && (startupData.description !== formData.description || startupData.solutions !== formData.solutions)) {
      setFormData({
        description: startupData.description || "",
        solutions: startupData.solutions || "",
      });
    }
  }, [startupData]);

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/innovations/${innovationId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData), // Invia l'HTML nel payload
      });

      if (response.ok) {
        alert("Data updated successfully!");
        handleClose();  // Chiude la dialog e aggiorna il padre
      } else {
        alert("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("An error occurred.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", backgroundColor: "#191919", color: "white" }}>
        Description
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#191919", color: "white", padding: "24px" }}>
        <Grid container spacing={3}>
          {/* Description */}
          <Grid item xs={12}>
            <span className="font-semibold text-white">Company Description</span>
            <ReactQuill
              value={formData.description}
              onChange={(value) => handleInputChange("description", value)}
              theme="snow"
              className="mt-2 bg-white text-black"
            />
          </Grid>

          {/* Solutions */}
          <Grid item xs={12}>
            <span className="font-semibold text-white">Solutions, Products and Services</span>
            <ReactQuill
              value={formData.solutions}
              onChange={(value) => handleInputChange("solutions", value)}
              theme="snow"
              className="mt-2 bg-white text-black"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#191919", padding: "16px" }}>
        <Button onClick={handleClose} sx={{ color: "white", fontWeight: "bold" }}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          sx={{
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "rgba(var(--global-color-primary), 0.8)" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionCompany;
